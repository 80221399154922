import { useContext } from 'react'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import _ from 'lodash'
import { t } from 'client/i18n'

export function TourWarningBanner() {
  const { values } = useContext(TranslationFormContext)
  if (!values) {
    return undefined
  }

  const { items } = values
  const missingCount = _.filter(items, (item) => _.isNil(item.itemMapLocation?.mapLocation)).length

  if (missingCount === 0) {
    return undefined
  }

  return (
    <Banner type="warning" headingText={t('All items need a map location')}>
      <BannerItem>
        {t('tourItemsMissingMapLocationsBannerMessage', { count: missingCount })}
      </BannerItem>
      <BannerItem>
        {t('Not seeing the right locations? Save this tour and edit locations on the Map page.')}
      </BannerItem>
    </Banner>
  )
}
