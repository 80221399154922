import _ from 'lodash'
import { useState, useCallback } from 'react'
import { noop } from 'redux-saga/utils'
import { useNavigate, Route, Routes } from 'react-router-dom'
import { Formik, FormikProps } from 'formik'
import StandardForm from 'client/components/StandardForm/StandardForm'
import { MuseumImageType } from 'shared/constants/images'
import SplashBackgroundForm from 'client/screens/AppEditor/BrandAssets/Splash/SplashBackgroundForm'
import { ICropEvent } from 'shared/CropTypes'
import { usePost } from 'client/hooks/api'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { showChangesSavedToast } from 'client/redux/actions/toast'
import { useDispatch } from 'react-redux'
import InvalidLogoFileErrorDialog from 'client/screens/AppEditor/InvalidLogoFileErrorDialog'
import isValidLogo from 'client/util/validator/isValidLogo'
import { t } from 'client/i18n'
import { refetchActiveQueries } from 'client/apollo'
import ImageFormField from 'client/screens/AppEditor/BrandAssets/ImageFormField'
import SplashPreview from '../SplashPreview'
import {
  SplashFormContainer,
  LeftFormInput,
  RightFormPreview,
  PreviewTextContainer,
  Divider,
  PreviewText,
  PreviewContainer
} from './styledComponents'

interface IFormValues {
  backgroundImage: {
    file: File | undefined | null
    // Original uploaded image, without cropping
    sourceUrl: string | null | undefined
    // Potentially cropped image
    url: string | undefined | null
    cropState: ICropEvent | null
  }
  logo: {
    file: File | undefined | null
    url: string | undefined | null
  }
}

interface IHandleUpdateSplashFormProps {
  backgroundFile: IFormValues['backgroundImage']['file']
  backgroundCropState: IFormValues['backgroundImage']['cropState']
  logoFile: IFormValues['logo']['file']
}

const getPostSplashRequestData = (props: IHandleUpdateSplashFormProps) => {
  const { backgroundFile, backgroundCropState, logoFile } = props

  const data = new FormData()

  const imagesForDeletion: MuseumImageType[] = []

  // Note: this is a temporary workaround:
  // logoFile is always initialized as undefined in Formik, when it gets deleted, it will be set to null,
  // as a result we only check null here to determine if we need to delete splash logo in backend.
  if (logoFile === null) {
    imagesForDeletion.push(MuseumImageType.SPLASH_LOGO)
  }
  data.append('imagesForDeletion', JSON.stringify(imagesForDeletion))

  if (logoFile) {
    data.append(MuseumImageType.SPLASH_LOGO, logoFile)
  }

  if (backgroundFile) {
    data.append(MuseumImageType.SPLASH_BACKGROUND, backgroundFile)
  }

  if (!_.isNil(backgroundCropState)) {
    data.append('backgroundCropState', JSON.stringify(backgroundCropState))
  }

  return data
}

const FormView = (props: FormikProps<IFormValues> & { displayName: string }) => {
  const { values, errors, setFieldValue, setFieldError, displayName } = props
  const { backgroundImage, logo } = values

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [hasValidationError, setHasValidationError] = useState(false)
  const [errorDialog, setError] = useErrorDialog()

  const logoUri = logo?.url
  const logoFile = logo?.file
  const backgroundFile = backgroundImage?.file
  const backgroundCropState = backgroundImage?.cropState

  const validateFormValues = () => {
    if (_.isNil(backgroundImage.sourceUrl)) {
      setFieldError('backgroundImage.file', 'A background image is required')
      return false
    }
    return true
  }

  const [postSplash, isPostSplashLoading] = usePost('/splash', {
    onSuccess: () => {
      refetchActiveQueries()
      navigate('/app-editor/brand-assets')
      dispatch(showChangesSavedToast())
    },
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.log('error in handle update splash', error)
      setError({
        error,
        title: t('Unable to Save Changes')
      })
    }
  })

  const onSubmit = async () => {
    if (!validateFormValues()) {
      return
    }

    const payload = {
      backgroundFile,
      backgroundCropState,
      logoFile
    }

    const requestBody = getPostSplashRequestData(payload)
    await postSplash(requestBody)
  }

  const onAddOrReplaceBackgroundImage = (file: File) => {
    const imgUrl = URL.createObjectURL(file)
    setFieldValue('backgroundImage', {
      file,
      sourceUrl: imgUrl,
      url: imgUrl
    })
  }

  const onReplaceLogo = async (file: File) => {
    const isValid = await isValidLogo(file)
    if (!isValid) {
      setHasValidationError(true)
      return
    }

    const logoUrl = URL.createObjectURL(file)
    const formData = new FormData()
    formData.append('image', file)
    setFieldValue('logo', {
      file,
      url: logoUrl
    })
  }

  const onDeleteLogo = () => {
    setFieldValue('logo', {
      url: null,
      file: null
    })
  }

  const onClose = useCallback(() => setHasValidationError(false), [])

  return (
    <>
      <StandardForm
        title={t('Splash Screen')}
        isLoading={isPostSplashLoading}
        onSave={onSubmit}
        onCancel={() => navigate('/app-editor/brand-assets')}
        fullWidth={true}
        isLarge={true}
      >
        <SplashFormContainer>
          {hasValidationError && <InvalidLogoFileErrorDialog onClose={onClose} />}
          <LeftFormInput>
            <ImageFormField
              type={MuseumImageType.SPLASH_BACKGROUND}
              src={backgroundImage.url}
              fileInputOption={{
                label: t('Replace Image'),
                onFileChange: onAddOrReplaceBackgroundImage,
                isSvgAllowed: false
              }}
              additionalOptions={[
                {
                  label: t('Edit Image'),
                  onClick: () => navigate('/app-editor/brand-assets/splash/background')
                },
                {
                  label: t('Download Image'),
                  onClick: () => window.open(`${backgroundImage.sourceUrl}?download=true`, '_self')
                }
              ]}
              // This "as string" assertion here is a hacky workaround as FormikErrors doesn't accept File type value.
              // We use type assertion here to bypass this TS error even tho knowing backgroundFile is not string.
              error={errors?.backgroundImage?.file as string}
            />
            <Divider />
            <ImageFormField
              type={MuseumImageType.SPLASH_LOGO}
              src={logoUri}
              fileInputOption={{
                label: t('Replace Logo'),
                onFileChange: onReplaceLogo,
                isSvgAllowed: true
              }}
              additionalOptions={[
                {
                  label: t('Delete Logo'),
                  onClick: onDeleteLogo
                }
              ]}
              // This "as string" assertion here is a hacky workaround as FormikErrors doesn't accept File type value.
              // We use type assertion here to bypass this TS error even tho knowing logoFile is not string.
              error={errors?.logo?.file as string}
            />
          </LeftFormInput>
          <RightFormPreview>
            <PreviewContainer>
              <SplashPreview
                logoUrl={logoUri}
                backgroundImageUrl={backgroundImage.url}
                displayName={displayName}
              />
            </PreviewContainer>
            <PreviewTextContainer>
              <PreviewText>
                {t('Screen sizes vary. The background image might get cropped in the app.')}
              </PreviewText>
            </PreviewTextContainer>
          </RightFormPreview>
        </SplashFormContainer>
        {errorDialog}
      </StandardForm>
      <Routes>
        <Route path="background" element={<SplashBackgroundForm />} />
      </Routes>
    </>
  )
}

interface ISplashFormProps {
  backgroundImageSourceUrl: string
  backgroundImageUrl: string
  backgroundImageCropState: ICropEvent
  logoUrl: string
  displayName: string
}

const SplashForm = ({
  backgroundImageSourceUrl,
  backgroundImageUrl,
  backgroundImageCropState,
  logoUrl,
  displayName
}: ISplashFormProps) => {
  const initialValues: IFormValues = {
    backgroundImage: {
      // Original uploaded image, without cropping
      sourceUrl: backgroundImageSourceUrl,
      // Potentially cropped image
      url: backgroundImageUrl,
      file: null,
      cropState: backgroundImageCropState
    },
    logo: {
      url: logoUrl,
      file: undefined
    }
  }
  return (
    <Formik onSubmit={noop} initialValues={initialValues}>
      {(formikProps) => <FormView {...formikProps} displayName={displayName} />}
    </Formik>
  )
}

export default SplashForm
