import StandardForm from 'client/components/StandardForm/StandardForm'
import { Formik } from 'formik'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { IMapJson } from 'shared/json/IMapJson'
import TextInputField from 'client/components/Formik/TextInput/TextInput'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import { updateExteriorMap } from 'client/redux/actions/maps'
import { t } from 'client/i18n'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import Section from 'client/components/Form/Section/Section'
import Divider from 'client/components/Divider/Divider'
import styled from 'styled-components'
import FormField from 'client/components/Form/FormField/FormField'
import {
  LatitudeTextField,
  LongitudeTextField,
  MapOrientationContextualHelp
} from './GoogleMapFields'

const StyledDivider = styled(Divider)`
  margin: var(--spacing-large) 0px;
`

const GoogleMapsForm = ({ loading, map }: { loading: boolean; map?: IMapJson }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { EDIT_GOOGLE_MAPS_INFO } = useFeatureFlags()

  return (
    <Formik
      onSubmit={(values) => {
        dispatch(updateExteriorMap(values))
        navigate('..')
      }}
      initialValues={{
        isGoogleMap: map?.isGoogleMap,
        cameraCenterLatitude: map?.cameraCenterLatitude,
        cameraCenterLongitude: map?.cameraCenterLongitude,
        cameraHeading: map?.cameraHeading,
        cameraZoom: map?.cameraZoom
      }}
      enableReinitialize={true}
    >
      {({ handleSubmit, values: { isGoogleMap } }) => (
        <StandardForm
          title={t('Edit Map')}
          onCancel={() => navigate('..')}
          onSave={handleSubmit}
          saveButtonLabel={t('Save')}
          isLoading={loading}
        >
          {EDIT_GOOGLE_MAPS_INFO && (
            <FormField>
              <ToggleField name="isGoogleMap" label={t('Google Maps Enabled')} />
            </FormField>
          )}

          {EDIT_GOOGLE_MAPS_INFO && isGoogleMap && <StyledDivider />}

          {isGoogleMap && (
            <>
              <Section title={t('Center Point Location')} />
              <LatitudeTextField name="cameraCenterLatitude" />
              <LongitudeTextField name="cameraCenterLongitude" />

              <StyledDivider />

              <TextInputField
                name="cameraHeading"
                label={t('Map Orientation')}
                additionalLabelNode={MapOrientationContextualHelp}
              />
              <TextInputField
                name="cameraZoom"
                label={t('Map Tab Zoom')}
                description={t(
                  'The zoom level for the guide’s Map tab is dynamic and scales automatically to ensure that all pins are visible on all devices. Changing the zoom here might mean some pins do not appear in the default view.'
                )}
              />
            </>
          )}
        </StandardForm>
      )}
    </Formik>
  )
}

export default GoogleMapsForm
