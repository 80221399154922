import _ from 'lodash'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import { usePost } from 'client/hooks/api'
import { flash } from 'client/redux/actions/flash'
import PageContent from 'client/components/PageContent/PageContent'
import Button from 'client/components/Button/Button'
import { t } from 'client/i18n'
import FormField from 'client/components/Form/FormField/FormField'
import FormFieldSection from 'client/components/Form/FormField/FormFieldSection'
import { gql, useQuery } from '@apollo/client'
import { GQLGuide, GQLMuseum } from 'shared/graphql/types/graphql'
import { useMemo, useState } from 'react'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import SelectBox, { ISelectBoxOptions } from 'client/components/Form/SelectBox/SelectBox'
import MuseumVersionType from 'shared/MuseumVersionType'

const PageContentContainer = styled.div`
  padding: var(--spacing-xlarge);
  > * + * {
    margin-top: var(--spacing-large);
  }
`
const useDeveloperPost = (url, description, onSuccess = _.noop) => {
  const dispatch = useDispatch()
  return usePost(url, {
    onSuccess: (...args) => {
      dispatch(flash(`${description}: SUCCESS!`))
      onSuccess(...args)
    },
    onError: () => {
      dispatch(flash(`${description}: ERROR!`, true))
    }
  })
}

type SourceGuide = Pick<GQLGuide, 'id' | 'name' | 'visibilityType'>

const ALL_GUIDES = gql`
  query allGuides {
    guides {
      id
      name
      visibilityType
    }
  }
`
type SourceGuideWithVersions = {
  id: number
  versions: Pick<GQLMuseum, 'id' | 'title' | 'versionType' | 'archiveDate'>[]
}

const SOURCE_GUIDE_WITH_VERSIONS = gql`
  query sourceGuideWithVersions($id: Int!) {
    guide(id: $id) {
      versions {
        id
        title
        versionType
        archiveDate
      }
    }
  }
`

const StyledSelectBox = styled(SelectBox)`
  color: black;
  width: 313px;
`

const FormSection = styled.section`
  display: flex;
  column-gap: var(--spacing-medium);
`

const CopyMuseumToGuide = () => {
  const [selectedSourceGuideOption, setSelectedSourceGuideOption] = useState<ISelectBoxOptions>()

  const [selectedSourceMuseumOption, setSelectedSourceMuseumOption] = useState<{
    value: number
    label: string
  }>()

  const [selectedDestinationGuideOption, setSelectedDestinationGuideOption] = useState<{
    value: number
    label: string
  }>()

  const { data: allGuidesData } = useQuery<{ guides: SourceGuide[] }>(ALL_GUIDES)
  const { data: sourceGuideWithVersionsData } = useQuery<{ guide: SourceGuideWithVersions }>(
    SOURCE_GUIDE_WITH_VERSIONS,
    {
      skip: !selectedSourceGuideOption,
      variables: { id: selectedSourceGuideOption?.value }
    }
  )

  const sourceGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .orderBy('name')
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} (${guide.id})`
        }))
        .filter((guide) => guide.value !== selectedDestinationGuideOption?.value)
        .value(),
    [allGuidesData?.guides, selectedDestinationGuideOption?.value]
  )

  const sourceMuseumOptions = useMemo(
    () =>
      _(sourceGuideWithVersionsData?.guide.versions)
        .orderBy([
          (a) => {
            const priority = {
              [MuseumVersionType.LIVE]: -1,
              [MuseumVersionType.PREVIEW]: 0,
              [MuseumVersionType.ARCHIVE]: 1
            }
            return priority[a.versionType]
          },
          'archiveDate'
        ])
        .map((version) => {
          const dateLabel =
            version.versionType === MuseumVersionType.ARCHIVE
              ? ` - ${new Date(version.archiveDate!).toLocaleString()}`
              : ''
          return {
            value: version.id,
            label: `${version.title} (${
              version.id
            }) - ${version.versionType.toUpperCase()}${dateLabel}`
          }
        })
        .value(),
    [sourceGuideWithVersionsData?.guide.versions]
  )

  const privateGuideOptions = useMemo(
    () =>
      _(allGuidesData?.guides)
        .filter((guide) => {
          return (
            guide.visibilityType === GuideVisibilityType.PRIVATE &&
            guide.id !== selectedSourceGuideOption?.value
          )
        })
        .map((guide) => ({
          value: guide.id,
          label: `${guide.name} (${guide.id})`,
          guideId: guide.id
        }))
        .sortBy('label')
        .value(),
    [allGuidesData?.guides, selectedSourceGuideOption]
  )

  const [copyMuseumToPrivateGuide] = useDeveloperPost(
    '/api/developer/copy-museum-to-private-guide',
    'Copy Museum to Private Guide',
    () => window.location.reload()
  )

  return (
    <FormFieldSection
      label={
        <h4 style={{ paddingBottom: 24 }}>
          <strong>{t('Copy Museum to Private Guide')}</strong>
        </h4>
      }
    >
      <FormSection>
        <FormField label={t('Source Guide')}>
          <StyledSelectBox
            placeholder={t('Select a guide...')}
            options={sourceGuideOptions}
            value={selectedSourceGuideOption}
            onChange={(e) => {
              const entry = _.find(sourceGuideOptions, { value: e.target.value })
              setSelectedSourceGuideOption(entry)
              setSelectedSourceMuseumOption(undefined)
            }}
          />
        </FormField>
        <FormField label={t('Source Museum')} key={selectedSourceMuseumOption?.value}>
          <StyledSelectBox
            isDisabled={_.isNil(selectedSourceGuideOption)}
            placeholder={t('Select a museum...')}
            options={sourceMuseumOptions}
            value={selectedSourceMuseumOption}
            onChange={(e) => {
              const entry = _.find(sourceMuseumOptions, { value: e.target.value })
              setSelectedSourceMuseumOption(entry)
            }}
          />
        </FormField>
      </FormSection>

      <FormField label={t('Destination Private Guide')}>
        <StyledSelectBox
          placeholder={t('Select a guide...')}
          options={privateGuideOptions}
          value={selectedDestinationGuideOption}
          onChange={(e) => {
            const entry = _.find(privateGuideOptions, { value: e.target.value })
            setSelectedDestinationGuideOption(entry)
          }}
        />
      </FormField>
      <FormField>
        <Button
          disabled={!selectedSourceMuseumOption?.value || !selectedDestinationGuideOption?.value}
          onClick={() => {
            copyMuseumToPrivateGuide({
              sourceMuseumId: selectedSourceMuseumOption?.value,
              destinationGuideId: selectedDestinationGuideOption?.value
            })
          }}
          label={t('Copy Source Museum to Destination Guide')}
        />
      </FormField>
    </FormFieldSection>
  )
}

export default function Developer() {
  const [clearCloudfrontCache, isClearCloudfrontCachePending] = useDeveloperPost(
    '/api/developer/clear-cloudfront-cache',
    'Clear Cloudfront Cache'
  )

  const [backfillGoogleTranslationContent, isBackfillGoogleTranslationContentPending] =
    useDeveloperPost('/api/developer/backfill-google-translations', 'Google Content Backfill')

  const [backfillGoogleTranslationCache, isBackfillGoogleTranslationCachePending] =
    useDeveloperPost('/api/developer/backfill-google-translations-cache', 'Google Cache Backfill')

  const [updateGooglePlacesInfo, isUpdatingGooglePlacesInfo] = useDeveloperPost(
    '/api/developer/update-google-places-info',
    'Update Google Places Info'
  )

  const [fixMuseumLocationsPositions, isFixingMuseumLocationsPositions] = useDeveloperPost(
    '/api/developer/fix-museum-locations',
    'Fix Museum Locations Position'
  )

  const isLoading =
    isClearCloudfrontCachePending ||
    isBackfillGoogleTranslationContentPending ||
    isBackfillGoogleTranslationCachePending ||
    isUpdatingGooglePlacesInfo ||
    isFixingMuseumLocationsPositions

  return (
    <PageContent title={t('Developer')}>
      {isLoading && <LoadingOverlay />}
      <PageContentContainer style={{ height: '150%' }}>
        <Button onClick={() => clearCloudfrontCache()} label={t('Clear Cloudfront Cache')} />

        <Button
          onClick={() => clearCloudfrontCache({ path: '/graphql/*' })}
          label={t('Clear Cloudfront Cache for GraphQL')}
        />

        <Button
          onClick={() => clearCloudfrontCache({ path: '/graphql/explore?*' })}
          label={t('Clear Cloudfront Cache for Explore')}
        />

        <Button
          onClick={() => {
            backfillGoogleTranslationContent()
          }}
          label={t('Start Google Translation Content Backfill')}
        />

        <Button
          onClick={() => {
            backfillGoogleTranslationCache()
          }}
          label={t('Start Google Translation Cache Backfill')}
        />

        <Button
          onClick={() => {
            updateGooglePlacesInfo()
          }}
          label={t('Update Google Places Info')}
        />

        <Button
          onClick={() => {
            fixMuseumLocationsPositions()
          }}
          label={t('Fix Museum Locations Positions')}
        />
        <CopyMuseumToGuide />
      </PageContentContainer>
    </PageContent>
  )
}
