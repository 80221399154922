import { put, select, takeEvery } from 'redux-saga/effects'
import { flash } from 'client/redux/actions/flash'
import { getSelectedVersionType } from 'client/redux/selectors/version'
import MuseumVersionType from 'shared/MuseumVersionType'
import { SHOW_CHANGES_SAVED_TOAST, FLASH_MESSAGE } from 'client/redux/actions/types'
import { toast } from 'react-toastify'
import CheckIcon from 'assets/svg/icon/status_confirmation_20.svg'
import ErrorIcon from 'assets/svg/icon/status_error_20.svg'
import { IFlashMessage, IReduxState } from 'client/types'
import { t } from 'client/i18n'

const createToastMessage = (museumVersionType: MuseumVersionType) => {
  switch (museumVersionType) {
    case MuseumVersionType.LIVE:
      return t('Live guide updated')
    case MuseumVersionType.PREVIEW:
      return t('Draft updated')
    case MuseumVersionType.ARCHIVE:
      return t('Archived guide updated')
    default:
      return t('Changes saved')
  }
}

const showSuccessToast = (msg: string) =>
  toast.success(msg, {
    icon: CheckIcon,
    delay: 0
  })

const showErrorToast = (msg: string) =>
  toast.error(msg, {
    icon: ErrorIcon,
    delay: 0
  })

export function* toastSuccess() {
  const museumVersionType = yield select(getSelectedVersionType)
  const toastMessage = createToastMessage(museumVersionType)

  yield put(flash(toastMessage))
}

function* showToast() {
  const flashOption: IFlashMessage = yield select((state: IReduxState) => state.flash.message)
  if (flashOption.error) {
    showErrorToast(flashOption.text)
  } else {
    showSuccessToast(flashOption.text)
  }
}

export default function* root(): IterableIterator<any> {
  yield takeEvery(SHOW_CHANGES_SAVED_TOAST, toastSuccess)
  yield takeEvery(FLASH_MESSAGE, showToast)
}
