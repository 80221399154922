import * as React from 'react'
import styled from 'styled-components'
import XIconButtonTooltip from 'client/components/Tooltip/XIconButtonTooltip'
import {
  GQLAudio,
  GQLVideo,
  GQLItem,
  GQLExhibit,
  GQLCreator,
  GQLImage
} from 'shared/graphql/types/graphql'
import { getItemDescription, isItemContent } from 'shared/util/items'
import { IGetMapLocationContent } from 'client/redux/selectors/maps'
import { getHowLongAgoText } from 'client/util/featuredGuides'
import IGuidePickerJson from 'shared/json/IGuidePickerJson'
import AudioDescriptionLabelTooltip from 'client/components/Languages/AudioDescriptionLabelTooltip'
import { getCreatorDisplayName } from 'client/util/creators'
import DownloadAssetIconButton from 'client/screens/Catalog/grids/shared/DownloadAssetIconButton'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import AudioPlaceholderSVG from 'assets/svg/emptyStates/audio_no_image_rectangle.svg'
import CreatorPlaceholderSVG from 'assets/svg/emptyStates/creator_no_image_rectangle.svg'
import SubtitlesPlaceholderSVG from 'assets/svg/emptyStates/subtitles_captions_no_image_rectangle.svg'
import VideoPlaceholderSVG from 'assets/svg/emptyStates/video_no_image_rectangle.svg'
import ImagePlaceholderSVG from 'assets/svg/emptyStates/no_image_rectangle.svg'
import { getItemOrExhibitImageHero, getItemOrExhibitImageThumbnail } from 'client/util/images'
import _ from 'lodash'
import { IBaseContentRow, IBaseContentRowWithTags } from 'client/components/ContentRow/types'
import { getExhibitDescription } from 'client/util/exhibits'
import { t } from 'client/i18n'
import { getItemOrExhibitTags } from 'client/util/contentTags'
import { formatDurationInHoursMinutesSeconds } from 'client/util/dates'
import { AssetType } from 'shared/constants/asset'
import {
  ReorderableRow,
  Subheader,
  Grabber,
  IconGroup,
  Tag,
  Header,
  HTMLText,
  LowerContent,
  RightContentContainer,
  GrabberContainer,
  Container,
  HeaderContainer,
  HeaderSubheaderWrapper,
  ContentStateCaption,
  SubHeaderContainer,
  ThumbnailContainer,
  ThumbnailImage,
  UpperContent,
  TextContainer,
  NoteIconsContainer
} from './styledComponents'
import GlobeIconTooltip from '../Languages/GlobeIconTooltip'
import ClosedCaptionLabelTooltip from '../Languages/ClosedCaptionLabelTooltip'
import { Note, ContentRow } from './ContentRenderer'

const RemoveButton = styled(XIconButtonTooltip)`
  margin-left: 24px;
`

const StyledRemoveButton = styled.div`
  padding-top: 10px;
`

interface IReorderableRowWrapperProps<T> {
  className?: string
  isDragging?: boolean
  showGrabber?: boolean
  // This is used, but not directly in this file.
  // Passed via `...props`
  /* eslint-disable react/no-unused-prop-types */
  onEdit?: (newValue: any) => void
  flexComponent?: (value: T) => React.ReactNode
  onRemove?: () => void
  children?: React.ReactNode
  isCompact?: boolean
  isSelected?: boolean
  /* eslint-enable react/no-unused-prop-types */
}

export interface IReorderableRowProps<T> extends IReorderableRowWrapperProps<T> {
  value: T
  index?: number
}

export const ReorderableRowWrapper = <T extends object>(props: IReorderableRowWrapperProps<T>) => {
  const { className, isDragging, children, showGrabber = true } = props
  return (
    <ReorderableRow className={className} isDragging={isDragging}>
      <Container>
        {showGrabber && (
          <GrabberContainer>
            <Grabber />
          </GrabberContainer>
        )}
        <RightContentContainer>{children}</RightContentContainer>
      </Container>
    </ReorderableRow>
  )
}

interface IImageProps {
  image?: string | React.FunctionComponent
  isError?: boolean
  isPickerRow?: boolean
  isGridRow?: boolean
}
export const ImageContainer = ({
  image: Image,
  isError = false,
  isPickerRow = false,
  isGridRow = false
}: IImageProps) => {
  const isSVG = _.isFunction(Image)

  return (
    <ThumbnailContainer
      isError={isError}
      isSVG={isSVG}
      isPickerRow={isPickerRow}
      isGridRow={isGridRow}
    >
      {isSVG ? (
        <Image />
      ) : (
        <ThumbnailImage
          isError={isError}
          src={Image}
          isPickerRow={isPickerRow}
          isGridRow={isGridRow}
        />
      )}
    </ThumbnailContainer>
  )
}

interface IIconGroupProps {
  onRemove?: () => void
  tags?: string[] | null
  note?: React.ReactNode
  isSelected?: boolean
  iconNodes?: React.ReactNode
  showRemoveIcon?: boolean
}
const IconGroupContainer = ({
  onRemove,
  tags,
  note,
  isSelected,
  iconNodes,
  showRemoveIcon
}: IIconGroupProps) => {
  return (
    <IconGroup>
      {iconNodes}
      {showRemoveIcon && <RemoveButton onRemove={onRemove} />}
      <Note>{note}</Note>
      <div>
        {tags?.map((tag) => (
          <Tag key={tag} isSelected={isSelected}>
            {tag}
          </Tag>
        ))}
      </div>
    </IconGroup>
  )
}

interface IHeadersProps extends IBaseContentRow {
  isPickerRow?: boolean
}

export const HeaderSubheaderContainer = ({
  header,
  subheaders = [],
  isError,
  isPickerRow = false,
  contentStateCaption
}: IHeadersProps) => {
  const filteredSubheaders = _.compact(subheaders)
  return (
    <>
      <HeaderContainer>
        {header && (
          <Header isPickerRow={isPickerRow}>
            <HTMLText html={header} />
          </Header>
        )}
      </HeaderContainer>
      <SubHeaderContainer>
        {filteredSubheaders.map((subheader) => (
          <Subheader key={subheader} isPickerRow={isPickerRow}>
            <HTMLText html={subheader} />
          </Subheader>
        ))}
      </SubHeaderContainer>
      {contentStateCaption && (
        <ContentStateCaption isError={isError} key={contentStateCaption}>
          <HTMLText html={contentStateCaption} />
        </ContentStateCaption>
      )}
    </>
  )
}

export interface IFullContentRowProps<T>
  extends IReorderableRowWrapperProps<T>,
    IBaseContentRowWithTags {
  flexComponent?: (value) => React.ReactNode
  image?: string | React.FunctionComponent
  onRemove?: () => void
  note?: React.ReactNode
  isSelected?: boolean
  value: T
  iconNodes?: React.ReactNode
  showRemoveIcon?: boolean
  isCompact?: boolean
}

export const FullContentRow = <T extends Object>(props: IFullContentRowProps<T>) => {
  const {
    header,
    subheaders = [],
    image,
    flexComponent,
    value,
    onRemove,
    tags,
    isError,
    contentStateCaption,
    note,
    isSelected,
    iconNodes,
    showRemoveIcon = true
  } = props
  return (
    <ReorderableRowWrapper {...props} flexComponent={flexComponent}>
      <ImageContainer image={image} isError={isError} />
      <TextContainer>
        <UpperContent>
          <HeaderSubheaderWrapper>
            <HeaderSubheaderContainer
              header={header}
              subheaders={subheaders}
              isError={isError}
              contentStateCaption={contentStateCaption}
            />
          </HeaderSubheaderWrapper>
          <IconGroupContainer
            onRemove={onRemove}
            showRemoveIcon={showRemoveIcon}
            tags={tags}
            note={note}
            iconNodes={iconNodes}
            isSelected={isSelected}
          />
        </UpperContent>
        {flexComponent && <LowerContent>{flexComponent(value)}</LowerContent>}
      </TextContainer>
    </ReorderableRowWrapper>
  )
}

export const ReorderableItemRow = (props: IReorderableRowProps<GQLItem>) => {
  const { value, isCompact, isSelected } = props
  const { header, subheaders } = getItemDescription(value)
  const image = isCompact ? getItemOrExhibitImageThumbnail(value) : getItemOrExhibitImageHero(value)
  const tags = getItemOrExhibitTags(value)

  return (
    <ContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={tags}
      note={<GlobeIconTooltip locales={value.locales} />}
      isCompact={isCompact}
      isSelected={isSelected}
    />
  )
}

export const ReorderableExhibitRow = (props: IReorderableRowProps<GQLExhibit>) => {
  const { value, isCompact, isSelected } = props
  const { header, subheaders } = getExhibitDescription(value)
  const image = isCompact ? getItemOrExhibitImageThumbnail(value) : getItemOrExhibitImageHero(value)
  const tags = getItemOrExhibitTags(value)

  return (
    <ContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={tags}
      note={<GlobeIconTooltip locales={value.locales} />}
      isCompact={isCompact}
      isSelected={isSelected}
    />
  )
}

export const ReorderableCreatorRow = (props: IReorderableRowProps<GQLCreator>) => {
  const { value, isCompact } = props
  const subheaders = [getCreatorDisplayName(value)]

  return (
    <ContentRow
      {...props}
      subheaders={subheaders}
      image={CreatorPlaceholderSVG}
      note={<GlobeIconTooltip locales={value.locales} />}
      isCompact={isCompact}
    />
  )
}

const getMediaContentCaption = (isProcessing: boolean, isChildrenProcessingError: boolean) => {
  if (isProcessing) {
    return t('Processing...')
  }
  if (isChildrenProcessingError) {
    return t('Unable to process')
  }
  return undefined
}

export const ReorderableAudioRow = (props: IReorderableRowProps<GQLAudio>) => {
  const { value, isCompact } = props
  const { transcript, title, duration, isMarketingUseAllowed, sourceUrl } = value
  const header = title
  const image = AudioPlaceholderSVG
  const audioDuration = duration && formatDurationInHoursMinutesSeconds(duration)
  const subheaders = [audioDuration]
  const contentStateCaption = getMediaContentCaption(
    value.isProcessing,
    value.isChildrenProcessingError
  )
  const tags = transcript ? [t('Transcript')] : null
  const { MARKETING } = useFeatureFlags()
  const hasSourceUrl = !_.isNil(sourceUrl)

  const note =
    MARKETING && !isCompact ? (
      <NoteIconsContainer>
        <GlobeIconTooltip locales={value.locales} />
        <DownloadAssetIconButton
          assetId={value.id}
          assetType={AssetType.AUDIO}
          hasSourceUrl={hasSourceUrl}
          isMarketingUseAllowed={isMarketingUseAllowed}
        />
      </NoteIconsContainer>
    ) : (
      <GlobeIconTooltip locales={value.locales} />
    )

  return (
    <ContentRow
      {...props}
      header={header}
      subheaders={subheaders}
      image={image}
      tags={contentStateCaption ? null : tags}
      isError={!!value.isChildrenProcessingError}
      contentStateCaption={contentStateCaption}
      note={note}
      isCompact={isCompact}
    />
  )
}

export const ReorderableVideoRow = (props: IReorderableRowProps<GQLVideo>) => {
  const { value, isCompact } = props
  const { title, posterImageUrl, duration, transcript, isMarketingUseAllowed, sourceUrl } = value
  const contentStateCaption = getMediaContentCaption(value.isProcessing, !!value.processingError)
  const videoDuration = duration && formatDurationInHoursMinutesSeconds(duration)
  const subheaders = [videoDuration]
  const tags = transcript ? [t('Transcript')] : null
  const { MARKETING } = useFeatureFlags()
  const hasSourceUrl = !_.isNil(sourceUrl)

  const note =
    MARKETING && !isCompact ? (
      <NoteIconsContainer>
        <ClosedCaptionLabelTooltip translations={value.translations} />
        <AudioDescriptionLabelTooltip translations={value.translations} />
        <GlobeIconTooltip locales={value.locales} />
        <DownloadAssetIconButton
          assetId={value.id}
          assetType={AssetType.VIDEO}
          hasSourceUrl={hasSourceUrl}
          isMarketingUseAllowed={isMarketingUseAllowed}
        />
      </NoteIconsContainer>
    ) : (
      <NoteIconsContainer>
        <ClosedCaptionLabelTooltip translations={value.translations} />
        <AudioDescriptionLabelTooltip translations={value.translations} />
        <GlobeIconTooltip locales={value.locales} />
      </NoteIconsContainer>
    )

  return (
    <ContentRow
      {...props}
      header={title}
      subheaders={subheaders}
      image={posterImageUrl ?? VideoPlaceholderSVG}
      tags={contentStateCaption ? null : tags}
      isError={!!value.processingError}
      contentStateCaption={contentStateCaption}
      note={note}
      isCompact={isCompact}
    />
  )
}

export const ReorderableImageRow = (props: IReorderableRowProps<GQLImage>) => {
  const { value, isCompact } = props
  const { hero, thumbnail, fileName, isMarketingUseAllowed, sourceUrl } = value
  const subheaders = [fileName]
  const image = isCompact ? thumbnail : hero
  const { MARKETING } = useFeatureFlags()
  const hasSourceUrl = !_.isNil(sourceUrl)

  const note =
    MARKETING && !isCompact ? (
      <NoteIconsContainer>
        <GlobeIconTooltip locales={value.locales} />
        <DownloadAssetIconButton
          assetId={value.id}
          assetType={AssetType.IMAGE}
          hasSourceUrl={hasSourceUrl}
          isMarketingUseAllowed={isMarketingUseAllowed}
        />
      </NoteIconsContainer>
    ) : (
      <GlobeIconTooltip locales={value.locales} />
    )

  return (
    <ContentRow
      {...props}
      subheaders={subheaders}
      image={image || ImagePlaceholderSVG}
      note={note}
      isCompact={isCompact}
    />
  )
}

export const ReorderableGuideRow = (props: IReorderableRowProps<IGuidePickerJson>) => {
  const { value, isCompact } = props
  const { name, imageUrl } = value
  const image = imageUrl || ImagePlaceholderSVG
  const timeAdded = getHowLongAgoText(value.dateAdded)
  const header = isCompact ? undefined : name
  const subheaders = isCompact ? [name] : [timeAdded]
  return <ContentRow {...props} header={header} subheaders={subheaders} image={image} />
}

export const ReorderableMapLocationContentRow = (
  props: IReorderableRowProps<IGetMapLocationContent>
) => {
  const { value } = props

  return isItemContent(value) ? (
    <ReorderableItemRow {...(props as IReorderableRowProps<GQLItem>)} />
  ) : (
    <ReorderableExhibitRow {...(props as IReorderableRowProps<GQLExhibit>)} />
  )
}

export const ReorderableSubtitlesRow = (
  props: IReorderableRowProps<{ subtitlesValue: string }>
) => {
  const { value } = props
  const subheaders = [value.subtitlesValue]
  const image = SubtitlesPlaceholderSVG

  return <FullContentRow {...props} subheaders={subheaders} image={image} />
}

export const ShowOnMapRow = <T extends object>(props: IReorderableRowProps<T>) => {
  const { value, onRemove, children, flexComponent = () => null } = props

  return (
    <ReorderableRowWrapper {...props}>
      {children}
      <HeaderSubheaderWrapper>
        <HeaderSubheaderContainer />
        {flexComponent && <LowerContent>{flexComponent(value)}</LowerContent>}
      </HeaderSubheaderWrapper>
      <StyledRemoveButton>
        <RemoveButton onRemove={onRemove} />
      </StyledRemoveButton>
    </ReorderableRowWrapper>
  )
}
