import gql from 'graphql-tag'
import BaseGridView from 'client/components/BaseGridView/BaseGridView'
import { ITableCellProps } from 'client/dsm/Table/Table'
import { GQLAccessibilitySuggestion, GQLVideo } from 'shared/graphql/types/graphql'
import { useCallback } from 'react'
import ImageForm from 'client/screens/Catalog/forms/ImageForm'
import AudioForm from 'client/screens/Catalog/forms/AudioForm'
import VideoForm from 'client/screens/Catalog/forms/VideoForm'
import {
  MEDIA_COLUMN_SPACING,
  UPDATED_AT_COLUMN
} from 'client/screens/Catalog/grids/shared/columns'
import AccessibilityIcon20Dark from 'assets/svg/icon/accessibility_20_dark.svg'
import AccessibilityIcon48Light from 'assets/svg/icon/accessibility_48_light.svg'
import styled from 'styled-components'
import AccessibilityContextualHelp from 'client/screens/Tools/AccessibilitySuggestions/ContextualHelp'
import ImagePreview from 'client/screens/Catalog/grids/renderers/ImagePreview'
import AudioPreview from 'client/screens/Catalog/grids/renderers/AudioPreview'
import VideoPreview from 'client/screens/Catalog/grids/renderers/VideoPreview'
import { t } from 'client/i18n'
import PlaceholderText from './PlaceholderText'

const Suggestion = styled.div`
  display: flex;
  align-items: center;
  gap: var(--spacing-xxsmall);
  line-height: var(--spacing-medium);
`

// TODO: ask jake about suggestions
const ACCESSIBILITY_SUGGESTIONS_GQL_QUERY = gql`
  query accessibilitySuggestionsForMuseum($museumId: Int!) {
    accessibilitySuggestions(museumId: $museumId) {
      id
      type
      title
      url
      suggestions
      updatedAt
      isProcessing
      processingError
    }
  }
`

function suggestionRenderer({ rowData }: ITableCellProps<GQLAccessibilitySuggestion>) {
  return (
    <>
      {rowData.suggestions.map(
        (suggestion: 'Add Alt-Text' | 'Add Transcript' | 'Upload Subtitles/Captions') => (
          <Suggestion key={suggestion}>
            <AccessibilityIcon20Dark />
            {t(`accessibilitySuggestion.${suggestion}`)}
          </Suggestion>
        )
      )}
    </>
  )
}

const AccessibilitySuggestionVideoPreview = (props: ITableCellProps<GQLVideo>) => {
  const { cellData, rowData } = props
  return <VideoPreview {...props} rowData={{ ...rowData, url: cellData }} />
}

const TYPE_TO_COMPONENT = {
  Image: ImagePreview,
  Audio: AudioPreview,
  Video: AccessibilitySuggestionVideoPreview
}

function previewRenderer(props: ITableCellProps<GQLAccessibilitySuggestion>) {
  const {
    rowData: { type }
  } = props

  const Preview = TYPE_TO_COMPONENT[type]
  return <Preview {...props} />
}

export default function AccessibilitySuggestions() {
  const navigatePath = useCallback(
    (rowData: GQLAccessibilitySuggestion) => `${rowData.type.toLowerCase()}/${rowData.id}`,
    []
  )

  return (
    <BaseGridView
      contentName="Accessibility Suggestion"
      contextualHelp={<AccessibilityContextualHelp />}
      gqlQuery={ACCESSIBILITY_SUGGESTIONS_GQL_QUERY}
      placeholderIcon={<AccessibilityIcon48Light />}
      placeholder={<PlaceholderText />}
      columns={[
        {
          dataKey: 'url',
          label: t('Preview'),
          cellRenderer: previewRenderer,
          sortable: false,
          minWidth: 320,
          maxWidth: 320,
          ...MEDIA_COLUMN_SPACING
        },
        {
          dataKey: 'type',
          label: t('Type'),
          maxWidth: 84
        },
        {
          dataKey: 'title',
          label: t('Title')
        },
        UPDATED_AT_COLUMN,
        {
          dataKey: 'suggestions',
          label: t('Suggestions'),
          sortable: false,
          cellRenderer: suggestionRenderer,
          minWidth: 200
        }
      ]}
      filterCriteria={['type', 'title', 'suggestions']}
      navigatePath={navigatePath}
      formComponent={{
        'image/:id/*': ImageForm,
        'audio/:id/*': AudioForm,
        'video/:id/*': VideoForm
      }}
    />
  )
}
