import { memo, useMemo } from 'react'
import VirtualSelect from 'client/components/LookupNumberSelect/VirtualSelect'
import { t } from 'client/i18n'
import _ from 'lodash'

// A stricter string type. Typecast it as '' so it's not a generic string type.
const EMPTY_STRING = '' as ''

const NONE_VALUE = { value: EMPTY_STRING, label: t('None') }

export type LookupNumber = {
  value: number | typeof EMPTY_STRING | null | undefined
  label: string
}

interface IProps {
  name: string
  value: LookupNumber['value']
  onChange: (lookupNumber: LookupNumber) => void
  lookupNumbers: number[]
}

function LookupNumberSelect(props: IProps) {
  const { onChange, value, name, lookupNumbers = [] } = props

  const options = useMemo(() => {
    const mappedLookupNumbers: LookupNumber[] = lookupNumbers.map((number) => ({
      value: number,
      label: number.toString()
    }))
    // If a lookup number is selected, put the "None" option to the top of the dropdown menu.
    if (value) {
      mappedLookupNumbers.unshift(NONE_VALUE)
    }

    return mappedLookupNumbers
  }, [lookupNumbers, value])

  const handleChange = (entry: LookupNumber) => {
    const resolvedValue = _.isFinite(entry?.value) ? entry?.value : null
    onChange({ ...entry, value: resolvedValue })
  }

  // If a not null value is selected, the text input field will display the number.
  // Otherwise it just displays placeholder text "None".
  const lookupValue = useMemo(() => (value ? { value, label: String(value) } : NONE_VALUE), [value])

  return (
    <VirtualSelect
      name={name}
      onChange={handleChange}
      options={options}
      value={lookupValue}
      placeholder={t('None')}
      height={40}
    />
  )
}

export default memo(LookupNumberSelect)
