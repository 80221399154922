import { useSelector } from 'react-redux'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import {
  isLoading,
  didExteriorMapUploadFail,
  didCreateBuildingFail
} from 'client/redux/selectors/maps'
import { useEffect } from 'react'

interface IProps {
  onCancel: () => void
  onNext: (stepName: string) => void
}

export const UploadingExteriorMap = (props: IProps) => {
  const isUploading = useSelector(isLoading)
  const didUploadFail = useSelector(didExteriorMapUploadFail)

  useEffect(() => {
    if (!isUploading && !didUploadFail) {
      props.onNext('exteriorMapUploaded')
    }
    if (!isUploading && didUploadFail) {
      props.onCancel()
    }
  })

  if (!isUploading || didUploadFail) {
    return null
  }

  return <LoadingOverlay />
}

export const CreatingBuilding = (props: IProps) => {
  const isCreating = useSelector(isLoading)
  const didCreateFail = useSelector(didCreateBuildingFail)

  useEffect(() => {
    if (!isCreating && !didCreateFail) {
      props.onNext('buildingCreated')
    }
    if (!isCreating && didCreateFail) {
      props.onCancel()
    }
  })

  if (!isCreating || didCreateFail) {
    return null
  }

  return <LoadingOverlay />
}
