import _ from 'lodash'
// TODO: We want to revisit how we're importing locales from the date-fns library.
import {
  de as localeDE,
  es as localeES,
  fr as localeFR,
  it as localeIT,
  ja as localeJA,
  nl as localeNL,
  pt as localePT,
  zhTW as localeZH
} from 'date-fns/locale'
import en from './en.json'
import de from './de.json'
import deGT from './de_gt.json'
import es from './es.json'
import esGT from './es_gt.json'
import fr from './fr.json'
import frGT from './fr_gt.json'
import it from './it.json'
import itGT from './it_gt.json'
import ja from './ja.json'
import jaGT from './ja_gt.json'
import nl from './nl.json'
import nlGT from './nl_gt.json'
import pt from './pt.json'
import ptGT from './pt_gt.json'
import zh from './zh.json'
import zhGT from './zh_gt.json'

// ordering here is important, as it drives the order of options in the UserForm language dropdown
// and the internal dropdown in the LanguageSelector
export const TRANSLATIONS = {
  zh: {
    name: 'Chinese (Traditional)',
    human: zh,
    gt: zhGT,
    dateFnsLocale: localeZH
  },
  nl: {
    name: 'Dutch',
    human: nl,
    gt: nlGT,
    dateFnsLocale: localeNL
  },
  fr: {
    name: 'French',
    human: fr,
    gt: frGT,
    dateFnsLocale: localeFR
  },
  de: {
    name: 'German',
    human: de,
    gt: deGT,
    dateFnsLocale: localeDE
  },
  it: {
    name: 'Italian',
    human: it,
    gt: itGT,
    dateFnsLocale: localeIT
  },
  ja: {
    name: 'Japanese',
    human: ja,
    gt: jaGT,
    dateFnsLocale: localeJA
  },
  pt: {
    name: 'Portuguese',
    human: pt,
    gt: ptGT,
    dateFnsLocale: localePT
  },
  es: {
    name: 'Spanish',
    human: es,
    gt: esGT,
    dateFnsLocale: localeES
  }
}

type TranslationLanguageCode = keyof typeof TRANSLATIONS

function combineTranslations(languageCode: TranslationLanguageCode) {
  const { human, gt } = TRANSLATIONS[languageCode]

  return {
    ...gt,
    ...human
  }
}

type SpecialLanguageCode = 'en' | 'hearts'
export type ResourceLanguageCode = SpecialLanguageCode | TranslationLanguageCode

type AllResources = {
  [key in ResourceLanguageCode]: {
    translation: ReturnType<typeof combineTranslations>
  }
}

const ALL_RESOURCES: AllResources = {
  en: {
    // DOCNT-11033 - Temporarily ignored due to issues with `translations fix` command
    // @ts-ignore
    translation: en
  },
  ..._.mapValues(TRANSLATIONS, (value, code: TranslationLanguageCode) => ({
    translation: combineTranslations(code)
  })),
  hearts: {
    // DOCNT-11033 - Temporarily ignored due to issues with `translations fix` command
    // @ts-ignore
    translation: _.mapValues(en, (t) => `♥️ ${t} ♥️`)
  }
}

export const fallbackLng = 'en'
// We have some keys that contain single colons, which is the default nsSeparator
export const nsSeparator = '$$'
// We have some keys that contain single periods, which is the default keySeparator
export const keySeparator = '::'

export default function createBaseConfiguration(
  enabledLanguageCodes: '*' | ResourceLanguageCode[]
) {
  const resources =
    enabledLanguageCodes === '*' ? ALL_RESOURCES : _.pick(ALL_RESOURCES, enabledLanguageCodes)

  return {
    resources,
    nsSeparator,
    fallbackLng,
    keySeparator
  }
}
