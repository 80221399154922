import { MiniPicker } from 'client/components/MiniPicker/MiniPicker/MiniPicker'
import _ from 'lodash'
import {
  Cancel,
  Submit,
  Title,
  Upload
} from 'client/components/MiniPicker/MiniPicker/Header/Header'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import AccentListSVG from 'assets/svg/accentIcons/accent_list_dark.svg'
import SelectedIconSVG from 'assets/svg/icon/status_selected_20.svg'
import { useState, useRef } from 'react'
import List from 'client/components/MiniPicker/FilterableMiniPicker/List'
import styled from 'styled-components'
import EmptyState from 'client/dsm/EmptyState/EmptyState'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { FileUploadChangeEvent } from 'client/components/FileUploader/FileUploader'
import { ThemeType } from 'client/types'
import { getFilteredData } from 'client/util/filters'
import DocentTippy from 'client/dsm/Tooltip/DocentTippy'
import { t } from 'client/i18n'
import {
  ItemContainer,
  SubItemContainer,
  ItemInPicker,
  SelectedItemIcon,
  PickerWrapper
} from './styledComponents'

const EmptyPicker = styled(EmptyState)`
  padding-top: 0;
  color: var(--color-grey-05);
`

const FilteredContentContainer = styled.div`
  height: 100%;
`

export interface IRowComponentProps<T> {
  value: T
  isSelected: boolean
  isCompact?: boolean
}

export interface IFilterableMiniPickerProps {
  // Would be good to add generics here too
  items: any[]
  rowComponent: (props: IRowComponentProps<any>) => JSX.Element
  contentName: ContentName
  filterCriteria: string[]
  onSubmit: (items: (number | string)[]) => void

  idField?: string | number
  isLoading?: boolean
  loadingText?: string
  maxSelectable?: number
  onUpload?: (event: FileList) => Promise<number[]>
  uploadFileFormats?: string[]
  // This is used by `ReorderableListWithPicker` to disable adding when max number of values has been reached
  showAddButton?: boolean
  // This is used by DataAwareReorderableList to force GQL refresh when the picker is opened
  onTooltipShow?: () => void
}

// Even though this is meant to be a generic component, we basically have to explicitly list out
// the union of all possible ContentName strings in order to take advantage of strict TS checking
// around JS string interpolation in the i18next `t` calls
//
// Alternatively, we could use some more advanced TS features like conditional typing with `infer`:
//
//   type constraint1<T> = T extends `No ${infer P}s have been added yet.` ? P : never
//   type constraint2<T> = T extends `Select ${infer P}` ? P : never
//   type constraint3<T> = T extends `Add ${infer P}` ? P : never
//   type ContentName = constraint1<TKey> & constraint2<TKey> & constraint3<TKey>
//
// However, this seems less useful because it makes the TS errors more indirect. When adding a new
// string to this union, the programmer will see TS failures at each specific interpolation that is
// found to be missing in our translation data files. But making a similar change with the inferred
// types above would lead to a TS error just where the new contentName attr value is being passed
// to the FilterableMiniPicker component. That TS error could only be fixed by adding every missing
// interpolation, the universe of which would have to be manually gathered.
type ContentName = 'Guide' | 'Content' | 'Audio' | 'Image' | 'Item' | 'Video' | 'Creator'
export default function FilterableMiniPicker(props: IFilterableMiniPickerProps) {
  const {
    filterCriteria,
    contentName,
    onUpload,
    onSubmit,
    items,
    rowComponent,
    showAddButton = true,
    isLoading = false,
    idField = 'id',
    maxSelectable = Number.POSITIVE_INFINITY,
    onTooltipShow = _.noop,
    uploadFileFormats,
    loadingText
  } = props

  const [selected, setSelected] = useState<(number | string)[]>([])
  const [filterText, setFilterText] = useState<string>('')
  const [isButtonSelected, setIsButtonSelected] = useState(false)
  const addButtonRef = useRef<HTMLButtonElement>(null)

  const hidePickerAndClearState = () => {
    setSelected([])
    setFilterText('')
    setIsButtonSelected(false)
    addButtonRef.current!.click()
  }

  const cancelSelection = () => {
    if (isLoading) {
      return
    }
    hidePickerAndClearState()
  }

  const submitSelection = () => {
    hidePickerAndClearState()
    onSubmit(selected)
  }

  const itemOnClick = (id: any) => {
    setSelected((currentSelected) => {
      const isRemoving = _.includes(currentSelected, id)
      if (isRemoving) {
        return _.without(currentSelected, id)
      }
      return currentSelected.length < maxSelectable ? [...currentSelected, id] : currentSelected
    })
  }

  const onFilterCancel = () => {
    setFilterText('')
  }

  const onFilterChange = (value) => {
    setFilterText(value)
  }

  // TODO: start using `value` rather than `item`, as `Item` is a type in our system
  const renderItem = (item: any) => {
    const isSelected = _.includes(selected, item[idField])
    const Row = rowComponent
    const isClickable = isSelected || selected.length < maxSelectable

    return (
      <ItemContainer
        onClick={isClickable ? () => itemOnClick(item[idField]) : undefined}
        key={`itemMiniPicker${item[idField]}`}
        isDisabled={!isClickable}
      >
        <SubItemContainer isSelected={isSelected}>
          <ItemInPicker>
            <Row value={item} isSelected={isSelected} />
            <SelectedItemIcon>{isSelected && <SelectedIconSVG />}</SelectedItemIcon>
          </ItemInPicker>
        </SubItemContainer>
      </ItemContainer>
    )
  }

  // eslint-disable-next-line docent/require-translation-keys-to-be-literals
  const emptyStateText = t(`No ${contentName}s have been added yet.`)
  const renderFilteredItems = () => {
    if (_.isEmpty(items)) {
      return (
        <EmptyPicker icon={<AccentListSVG />}>
          <p>{emptyStateText}</p>
        </EmptyPicker>
      )
    }

    const filteredItems = getFilteredData(items, filterCriteria, filterText)

    if (filteredItems.length === 0) {
      return (
        <EmptyPicker showIcon={false}>
          <p>{t('No results found for __filterText__', { filterText })}</p>
        </EmptyPicker>
      )
    }

    return (
      <FilteredContentContainer>
        <List items={filteredItems} rowRenderer={renderItem} />
      </FilteredContentContainer>
    )
  }

  const miniPicker = (
    <MiniPicker
      headerOptions={_.compact([
        () => <Cancel key="cancel" onClick={cancelSelection} />,
        () => (
          <Submit
            key="submit"
            allowSubmit={selected.length > 0}
            onSubmit={submitSelection}
            label={t('Apply')}
          />
        ),
        onUpload &&
          (() => (
            <Upload
              key="upload"
              uploadFileFormats={uploadFileFormats}
              onUpload={async (event: FileUploadChangeEvent) => {
                const ids = await onUpload(event.target.files!)
                const addableCount = maxSelectable - selected.length
                setSelected([...selected, ..._.take(ids, addableCount)])
              }}
            />
          )),
        // eslint-disable-next-line docent/require-translation-keys-to-be-literals
        () => <Title key="title" title={t(`Select ${contentName}`)} />
      ])}
      filterText={filterText}
      onFilterChange={onFilterChange}
      onFilterCancel={onFilterCancel}
    >
      {renderFilteredItems()}
      {isLoading && <LoadingOverlay loadingText={loadingText} type={ThemeType.DARK} />}
    </MiniPicker>
  )

  const handleTooltipShow = () => {
    setIsButtonSelected(true)
    onTooltipShow()
  }

  const addButton = showAddButton ? (
    <DocentTippy
      size="small"
      content={miniPicker}
      trigger="click"
      onHide={cancelSelection}
      onShow={handleTooltipShow}
      interactive={true}
      placement="right"
    >
      <PlusIconAddButton
        // eslint-disable-next-line docent/require-translation-keys-to-be-literals
        label={t(`Add ${contentName}`)}
        selected={isButtonSelected}
        ref={addButtonRef}
      />
    </DocentTippy>
  ) : null

  return <PickerWrapper>{addButton}</PickerWrapper>
}
