import { Form, Formik } from 'formik'
import _ from 'lodash'
import StandardForm from 'client/components/StandardForm/StandardForm'
import GuideVisibilityType from 'shared/GuideVisibilityType'
import TextInputField from 'client/components/Formik/TextInput/TextInput'
import FormField from 'client/components/Form/FormField/FormField'
import { GQLBrowseCategory, GQLLocale } from 'shared/graphql/types/graphql'
import { useQuery } from '@apollo/client'
import useCoercedParam from 'client/hooks/useCoercedParam'
import useAdminApi from 'client/screens/Admin/useAdminApi'
import gql from 'graphql-tag'
import { Route, Routes, useNavigate } from 'react-router-dom'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import {
  DeleteGuideConfirmDialog,
  MakeGuidePublicConfirmDialog
} from 'client/screens/Admin/Guides/GuideForm/GuideFormDialogs'
import PublicVisibilityField from 'client/screens/Admin/Guides/GuideForm/PublicVisibilityField'
import InternalTestingGuideField from 'client/screens/Admin/Guides/GuideForm/InternalTestingGuideField'
import PencilIconEditButton from 'client/components/Button/PencilIconEditButton'
import Checkbox, { CheckboxGroup } from 'client/components/Checkbox/Checkbox'
import styled from 'styled-components'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import useFormikErrors from 'client/hooks/useFormikErrors'
import { useEffect, useState } from 'react'
import { useErrorDialog } from 'client/components/ErrorDialog'
import { useSelector } from 'react-redux'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { t } from 'client/i18n'
import SelectBox from 'client/components/Form/SelectBox/SelectBox'
import { TKey } from 'shared/i18n/types/translationResources'
import { filesize } from 'filesize'

const Container = styled.div`
  padding: var(--container-padding);
  overflow: auto;
  height: 100%;
  hr {
    margin-top: var(--spacing-large);
    margin-bottom: var(--spacing-large);
  }
`

const DefaultLanguageSelectBox = styled(SelectBox)`
  margin-top: var(--spacing-xsmall);
`

const EDIT_GUIDE_NAME_FIELD_DESCRIPTION = t(
  'This field inherits the name from the About page. By default, the formal name appears. If a friendly name is provided, the friendly name appears.'
)

const ADD_GUIDE_NAME_FIELD_DESCRIPTION = t('Enter the organization’s formal name.')

const GUIDE_FORM_DATA_QUERY = gql`
  query guideById($id: Int!) {
    guide(id: $id) {
      id
      name
      code
      keywords
      visibilityType
      publicDate
      isGoogleTranslateEnabled
      isShareEnabled
      isInternal
      downloadSize
      defaultLocale {
        id
      }
      browseCategories {
        id
      }
    }
  }
`

interface IGuideBrowseCategoriesFormProps {
  browseCategories: GQLBrowseCategory[]
  selected: GQLBrowseCategory['id'][]
  onCancel: () => void
  onSave: (selected: GQLBrowseCategory['id'][]) => void
}

const GuideBrowseCategoriesForm = ({
  browseCategories,
  selected,
  onCancel,
  onSave
}: IGuideBrowseCategoriesFormProps) => {
  const [currentSelection, setCurrentSelection] = useState(selected)

  const handleCheckboxToggle = (id: GQLBrowseCategory['id'], checked: boolean) => {
    const updatedSelection = checked ? [...currentSelection, id] : _.without(currentSelection, id)
    setCurrentSelection(updatedSelection)
  }

  return (
    <StandardForm
      title={t('Edit Categories')}
      onCancel={onCancel}
      onSave={() => onSave(currentSelection)}
      transparentBackgroundContainer={true}
      saveButtonLabel={t('Apply')}
    >
      <FormField
        description={t(
          'Select at least one category so this guide can appear via the filters on Explore.'
        )}
      >
        <CheckboxGroup>
          {_.map(browseCategories, ({ id, name }) => (
            <Checkbox
              key={id}
              name={name}
              checked={_.includes(currentSelection, id)}
              onChange={({ target }) => handleCheckboxToggle(id, target.checked)}
              label={t(`browseCategory.${name}` as TKey)}
            />
          ))}
        </CheckboxGroup>
      </FormField>
    </StandardForm>
  )
}

interface IFormFields {
  code: string
  name: string
  visibilityType: GuideVisibilityType
  defaultLocaleId: number
  keywords: string
  isGoogleTranslateEnabled: boolean
  isShareEnabled: boolean
  isInternal: boolean
  browseCategories: any[]
}

const mapLocaleToOption = ({ id, code }: GQLLocale) => ({
  value: `${id}`,
  label: `${t(`language.${code}` as TKey)} (${code})`
})

const getNewValues = (values: IFormFields) => {
  return {
    ...values,
    keywords: values.keywords ? _.split(values.keywords, ' ') : null
  }
}

const GuideFormErrorBanner = () => {
  const errors = useFormikErrors()
  return <FormErrorBanner errorMap={errors} />
}

const GuideForm = () => {
  const navigate = useNavigate()
  const guideId = useCoercedParam<number>('id')
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false)
  const [showConfirmMakeGuidePublicDialog, setShowConfirmMakeGuidePublicDialog] = useState(false)
  const [errorDialog, setErrorDialog] = useErrorDialog()

  const { data: formGlobalData = {}, loading: isLocalesLoading } = useQuery(gql`
    query formGlobalData {
      locales {
        id
        code
        englishName
      }

      browseCategories {
        id
        name
      }
    }
  `)

  const { locales, browseCategories } = formGlobalData

  const {
    object: guide,
    createUpdate,
    doDelete,
    isLoading,
    error
  } = useAdminApi({
    gqlQuery: GUIDE_FORM_DATA_QUERY,
    url: '/api/guides',
    id: guideId
  })

  useEffect(() => {
    if (error) {
      setErrorDialog({ title: t('Unable to Save Guide'), error })
    }
  }, [error, setErrorDialog])

  const { guideId: activeGuideId } = useSelector(getSelectedVersionInfo)

  const { DEVELOPER_MODE } = useFeatureFlags()

  const initialValues: IFormFields = guide
    ? {
        code: guide.code,
        name: guide.name,
        keywords: _.join(guide.keywords, ' '),
        visibilityType: guide.visibilityType,
        defaultLocaleId: guide.defaultLocale.id,
        isGoogleTranslateEnabled: guide.isGoogleTranslateEnabled,
        isShareEnabled: guide.isShareEnabled,
        isInternal: guide.isInternal,
        browseCategories: _.map(guide.browseCategories, 'id')
      }
    : {
        // A user can't edit the read-only guide code on the Guide Form UI.
        // Undefined here is to ensure code props won't show up in payload.
        code: undefined,
        name: '',
        keywords: '',
        defaultLocaleId: _.find(locales, { code: 'en-US' })?.id,
        visibilityType: GuideVisibilityType.PRIVATE,
        isGoogleTranslateEnabled: true,
        isShareEnabled: true,
        isInternal: false,
        browseCategories: []
      }

  const originalGuideVisibilityType = initialValues.visibilityType

  const onValidate = (values: IFormFields) => {
    const errors = _.pickBy({
      name: _.isEmpty(values.name) && `${t('Name')}: ${t('This field is required.')}`,
      browseCategories:
        values.visibilityType === GuideVisibilityType.PUBLIC &&
        _.isEmpty(values.browseCategories) &&
        `${t('Categories')}: ${t('At least one category is required.')}`
    })

    return errors
  }

  const onSubmit = async (values: IFormFields) => {
    // The guide visibility type can only be updated if it currently is private.
    // A user can't make changes to the guide visibility type on the Guide Form UI if the guide is secret or already public.
    if (values.visibilityType !== originalGuideVisibilityType) {
      setShowConfirmMakeGuidePublicDialog(true)
    } else {
      // A user will just see a displayed read-only guide code on the Guide Form UI. We don't allow them to create/edit code.
      const newValues = getNewValues(values)
      await createUpdate(newValues)
    }
  }

  const onCancel = () => navigate('..')

  const onDelete = () => {
    setShowConfirmDeleteDialog(true)
  }

  const formTitle = guideId ? t('Edit Guide') : t('Add Guide')

  return (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        enableReinitialize={true}
        validate={onValidate}
      >
        {({ values, handleSubmit, setFieldValue }) => {
          const onLocaleSelect = (e) => {
            setFieldValue('defaultLocaleId', parseInt(e.target.value, 10))
          }

          const localesOptions = _.map(locales, mapLocaleToOption)
          const selectedLocale: any = _.find(localesOptions, {
            value: values.defaultLocaleId?.toString()
          })

          const handleEditGuideCategoriesSave = (selected: GQLBrowseCategory['id'][]) => {
            setFieldValue('browseCategories', selected)
            navigate('.')
          }

          return (
            <StandardForm
              title={formTitle}
              onCancel={onCancel}
              onDelete={guideId ? onDelete : undefined}
              onSave={handleSubmit}
              isLoading={isLoading || isLocalesLoading}
              fullWidth={true}
            >
              {showConfirmDeleteDialog && (
                <DeleteGuideConfirmDialog
                  onCancel={() => setShowConfirmDeleteDialog(false)}
                  onDelete={() => {
                    setShowConfirmDeleteDialog(false)
                    doDelete!()
                  }}
                />
              )}

              {showConfirmMakeGuidePublicDialog && (
                <MakeGuidePublicConfirmDialog
                  onCancel={() => {
                    setFieldValue('visibilityType', GuideVisibilityType.PRIVATE)
                    setShowConfirmMakeGuidePublicDialog(false)
                  }}
                  onMakeGuidePublic={async () => {
                    setShowConfirmMakeGuidePublicDialog(false)
                    const newValues = getNewValues(values)
                    await createUpdate(newValues)
                    // Making a guide public also makes the current draft live
                    // If this guide is selected in the guide selector, this will cause popups in the UI telling the user that there is now a version mismatch
                    // This is because the version selector has DRAFT selected, but this version is now LIVE
                    // To avoid this, reload the browser when making a guide public IF it's the selected guide
                    // Then the version selector will know this is a LIVE version
                    if (guideId === activeGuideId) {
                      window.location.reload()
                    }
                  }}
                />
              )}

              <GuideFormErrorBanner />
              <Container>
                <Form>
                  <TextInputField
                    name="name"
                    label={t('* Name')}
                    description={
                      guideId ? EDIT_GUIDE_NAME_FIELD_DESCRIPTION : ADD_GUIDE_NAME_FIELD_DESCRIPTION
                    }
                    disabled={!!guideId}
                    disabledTooltip={t('Only editable on the About page.')}
                  />
                  <hr />
                  {guideId && (
                    <TextInputField
                      name="code"
                      label={t('Code')}
                      disabled={values.visibilityType === GuideVisibilityType.PUBLIC}
                      disabledTooltip={
                        <>
                          <p>{t('The guide code cannot be changed for public guides.')}</p>
                          <p>{t('Contact CMS Engineering with questions.')}</p>
                        </>
                      }
                    />
                  )}
                  <FormField
                    disabled={!!guideId}
                    label={t('* Default Language')}
                    disabledTooltip={
                      <>
                        <p>{t('The default language cannot be changed in the CMS.')}</p>
                        <p>{t('Contact CMS Engineering with questions.')}</p>
                      </>
                    }
                  >
                    <DefaultLanguageSelectBox
                      name="defaultLocaleId"
                      value={selectedLocale}
                      isDisabled={!!guideId}
                      onChange={onLocaleSelect}
                      options={localesOptions}
                    />
                  </FormField>

                  {guideId && (
                    <FormField label={t('Download Size')}>
                      {guide?.downloadSize ? filesize(guide?.downloadSize) : null}
                    </FormField>
                  )}

                  <FormField>
                    <ToggleField
                      name="isGoogleTranslateEnabled"
                      label={t('Google Translate Enabled')}
                    />
                  </FormField>
                  <hr />
                  <FormField>
                    <ToggleField name="isShareEnabled" label={t('Share Enabled')} />
                  </FormField>

                  <>
                    <FormField label={t('Categories')}>
                      <ErrorMessage name="browseCategories" />
                      <PencilIconEditButton onClick={() => navigate('categories')} />
                    </FormField>

                    <Routes>
                      <Route
                        path="categories"
                        element={
                          <GuideBrowseCategoriesForm
                            browseCategories={browseCategories}
                            selected={values.browseCategories}
                            onSave={handleEditGuideCategoriesSave}
                            onCancel={() => navigate('.')}
                          />
                        }
                      />
                    </Routes>
                  </>

                  <TextInputField
                    name="keywords"
                    label={t('Keywords')}
                    description={t('Enter keywords separated by spaces.')}
                  />

                  <hr />
                  {DEVELOPER_MODE && <InternalTestingGuideField />}
                  <PublicVisibilityField
                    originalGuideVisibilityType={originalGuideVisibilityType}
                    guideId={guideId}
                  />
                </Form>
              </Container>
            </StandardForm>
          )
        }}
      </Formik>
      {errorDialog}
    </>
  )
}

export default GuideForm
