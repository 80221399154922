import { memo, useCallback, useState } from 'react'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import WorkflowManager from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowManager'
import styled from 'styled-components'
import { t } from 'client/i18n'
import { useNavigate } from 'react-router-dom'
import Steps from './Steps'

const AddBuildingButton = styled(PlusIconAddButton)`
  flex-shrink: 0;
  margin: 16px;
`

const {
  ExteriorMap,
  BuildingName,
  UploadingExteriorMap,
  ExteriorMapUploaded,
  CreatingBuilding,
  BuildingCreated
} = Steps

/* TODO: we will need to revisit the options on scaling the workflow manager before the next feature/task
       that requires a workflow. The main aspects that need to be considered is keeping
       each step unaware of the next step and remove useEffect but still keeping the workflow manager
       workflow agnostic (not hardcoded to a specific workflow)
*/

const creatingBuildingFlow = [
  { name: 'exteriorMap', component: ExteriorMap },
  { name: 'uploadingExteriorMap', component: UploadingExteriorMap },
  { name: 'exteriorMapUploaded', component: ExteriorMapUploaded },
  { name: 'buildingName', component: BuildingName },
  { name: 'creatingBuilding', component: CreatingBuilding },
  { name: 'buildingCreated', component: BuildingCreated }
]

const BuildingFactory = memo(() => {
  const [startCreationFlow, setStartCreationFlow] = useState(false)
  const navigate = useNavigate()

  const onCancel = useCallback(() => setStartCreationFlow(false), [])

  return (
    <>
      <AddBuildingButton
        type="primary"
        label={t('Add Building')}
        onClick={() => navigate('buildings/new')}
      />
      {startCreationFlow && (
        <WorkflowManager workflowSteps={creatingBuildingFlow} onCancel={onCancel} />
      )}
    </>
  )
})

export default BuildingFactory
