import { Route, Routes } from 'react-router-dom'
import Developer from 'client/screens/Internal/Developer'
import GuideStarts from 'client/screens/Internal/GuideStarts'
import FirstOpens from 'client/screens/Internal/FirstOpens'
import AssetCounts from 'client/screens/Internal/AssetCounts'
import QrCodeGeneration from 'client/screens/QrCodeGeneration'
import Explore from 'client/screens/Internal/Explore/Explore'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

export default function Internal() {
  const { ANALYTICS, MARKETING, DEVELOPER_MODE, MANAGE_FEATURED_GUIDES } = useFeatureFlags()
  return (
    <Routes>
      {ANALYTICS && <Route path="guide-starts" element={<GuideStarts />} />}
      {ANALYTICS && <Route path="first-opens" element={<FirstOpens />} />}
      {MARKETING && <Route path="asset-counts" element={<AssetCounts />} />}
      {DEVELOPER_MODE && <Route path="developer" element={<Developer />} />}
      {MARKETING && <Route path="generate-qr-codes" element={<QrCodeGeneration />} />}
      {MANAGE_FEATURED_GUIDES && <Route path="explore/*" element={<Explore />} />}
    </Routes>
  )
}
