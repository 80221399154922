import { useContext } from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import FormField, { IFormFieldProps } from 'client/components/Form/FormField/FormField'

import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'

const Container = styled((props) => (
  <div data-showbackground={props.showBackground} {..._.omit(props, 'showBackground')} />
))<{
  showBackground: boolean
}>`
  padding: 16px;
  background-color: ${({ showBackground }) =>
    showBackground ? 'var(--color-blue-01)' : 'transparent'};
`

export const EnableOrDisableWrapper = styled.div<{ disabled: boolean }>`
  z-index: 1;
  opacity: ${({ disabled }) => (disabled ? 0.65 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  color: ${({ disabled }) => (disabled ? 'var(--color-grey-05)' : 'inherit')};

  button {
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }

  input {
    color: ${({ disabled }) => (disabled ? 'var(--color-grey-05)' : 'inherit')};
  }
`

interface ITranslatableFormFieldProps extends IFormFieldProps {
  translatable?: boolean
}

const StyledFormField = styled(FormField)`
  margin-bottom: 0;
`

const TranslatableFormField = (props: ITranslatableFormFieldProps) => {
  const { translatable = true, className } = props
  const { isDefaultLocaleSelected } = useContext(TranslationFormContext)

  return (
    <Container showBackground={translatable && !isDefaultLocaleSelected} className={className}>
      <EnableOrDisableWrapper disabled={!translatable && !isDefaultLocaleSelected}>
        <StyledFormField {...props} />
      </EnableOrDisableWrapper>
    </Container>
  )
}

export default styled(TranslatableFormField)``
