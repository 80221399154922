import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloLink,
  from,
  ServerError
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import _ from 'lodash'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { MAINTENANCE_MODE_HEADER_NAME } from 'shared/constants/responseHeaders'
import { authFailed } from './redux/actions/auth'
import store from './redux/store'

const maintenanceModeCheckLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((data) => {
    const context = operation.getContext()
    const { headers } = context.response
    if (headers.has(MAINTENANCE_MODE_HEADER_NAME)) {
      window.location.reload()
    }
    return data
  })
})

const setHeaders = setContext((operation, previousContext) => {
  const { variables = {} } = operation
  const { headers: prevHeaders } = previousContext
  const headers = { ...prevHeaders }

  if (variables.selectedLocaleCode) {
    headers['cms-selected-language'] = variables.selectedLocaleCode
    variables.selectedLocaleCode = undefined
  }

  if (variables.enableGoogleTranslate) {
    headers['cms-enable-google-translate'] = variables.enableGoogleTranslate
    variables.enableGoogleTranslate = undefined
  }

  return { ...previousContext, headers }
})

const museumContextLink = new ApolloLink((operation, forward) => {
  const versionInfo = getSelectedVersionInfo(store.getState())
  if (_.isEmpty(operation.variables?.museumId)) {
    _.set(operation, 'variables.museumId', versionInfo.id)
  }

  if (_.isEmpty(operation.variables?.guideId)) {
    _.set(operation, 'variables.guideId', versionInfo.guideId)
  }

  return forward(operation)
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`)
    const { statusCode } = networkError as ServerError
    if (_.includes([401, 403], statusCode)) {
      store.dispatch(authFailed(new Error()))
      return
    }
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      // eslint-disable-next-line no-console
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    )
  }
})

const httpLink = new HttpLink({
  uri: '/graphql'
})

const cache = new InMemoryCache({
  typePolicies: {
    MuseumImages: {
      merge: true
    }
  }
})

const client = new ApolloClient({
  cache,
  link: from([errorLink, museumContextLink, setHeaders, maintenanceModeCheckLink, httpLink]),
  defaultOptions: {
    mutate: {
      refetchQueries: 'active'
    },
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
})

export function refetchActiveQueries() {
  // Intentionally using `.catch` as we don't want callers of `refetchActiveQueries` to `await`
  // This should be fire and forget - `awaiting` it can cause odd UI behavior (forms reloading before disappearing, etc)
  client
    .refetchQueries({
      include: 'active'
    })
    .catch((e) => {
      // eslint-disable-next-line no-console
      console.log('Error refetching data for Apollo queries:', e)
    })
}

export default client
