import BuildingName from './BuildingName'
import ExteriorMap from './ExteriorMap'
import { UploadingExteriorMap, CreatingBuilding } from './Uploading'
import { ExteriorMapUploaded, BuildingCreated } from './Success'

export default {
  ExteriorMap,
  BuildingName,
  UploadingExteriorMap,
  ExteriorMapUploaded,
  CreatingBuilding,
  BuildingCreated
}
