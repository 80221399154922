import { useRef } from 'react'
import * as React from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { getExterior, isLoading } from 'client/redux/selectors/maps'
import { updateExteriorMap, deleteExteriorMap } from 'client/redux/actions/maps'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { confirm } from 'client/redux/actions/confirmation'
import BuildingFactory from 'client/screens/AppEditor/MapEditor/BuildingFactory/BuildingFactory'
import AddExteriorMapWorkflow from 'client/screens/AppEditor/MapEditor/AddExteriorMap/AddExteriorMapWorkflow'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { PermanentActionMessage } from 'client/dsm/Dialog/Dialog'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import { t } from 'client/i18n'
import {
  EditorContainer,
  ExteriorMapSection,
  ExteriorMapLeftSection
} from './FloorEditor/styledComponents'
import Buildings from './Buildings/Buildings'
import MapImageInput from './MapImageInput'
import GoogleMapsForm from './GoogleMapsForm'

interface IProps {
  selectedId: number | string
  onSelect: (buildingId, floorId) => void
  isExteriorMapSelected?: boolean
  onExteriorMapSelected: (id) => void
}

const MapSidebar: React.FC<IProps> = (props: IProps) => {
  const { selectedId, onSelect, isExteriorMapSelected, onExteriorMapSelected } = props
  const replaceExteriorMapRef = useRef<HTMLInputElement>(null)
  const loading = useSelector(isLoading)
  const exteriorMap = useSelector(getExterior)
  const buildings = useBuildingList()
  const dispatch = useDispatch()
  const { EDIT_GOOGLE_MAPS_INFO } = useFeatureFlags()
  const navigate = useNavigate()

  const handleExteriorMapImageUpdate = (image: File) => dispatch(updateExteriorMap({ image }))
  const confirmReplaceExteriorMap = () => {
    dispatch(
      confirm({
        title: t('Replace Exterior Map?'),
        message: (
          <>
            <p>{t('This Exterior Map is currently associated with building pins.')}</p>
            <p>
              {t(
                'Please check your building pin locations once the new map is uploaded and adjust if needed.'
              )}
            </p>
            <p>
              {t(
                'Note: the Exterior Map helps visitors orient themselves in your outdoor space (if any).'
              )}
            </p>
          </>
        ),
        confirmYes: {
          action: () => replaceExteriorMapRef.current!.click(),
          label: t('Replace Map')
        }
      })
    )
  }
  const confirmDeleteExteriorMap = () => {
    const messageWithBuildings = (
      <>
        <p>
          {t(
            'Are you sure you want to delete the Exterior Map? This will also delete its associated building pin.'
          )}
        </p>
        <p>{t('Note: Remaining building will be shown in the Map tab in the app by default.')}</p>
        <PermanentActionMessage />
      </>
    )

    const messageWithoutBuildings = (
      <>
        <p>{t('Are you sure you want to delete the Exterior Map?')}</p>
        <PermanentActionMessage />
      </>
    )

    dispatch(
      confirm({
        title: t('Delete Exterior Map?'),
        message: _.isEmpty(buildings) ? messageWithoutBuildings : messageWithBuildings,
        confirmYes: {
          action: () => dispatch(deleteExteriorMap(isExteriorMapSelected)),
          label: t('Delete Map'),
          isDestructive: true
        }
      })
    )
  }

  const options: IMenuOption[] = _.compact([
    {
      label: t('Replace Map'),
      onClick: confirmReplaceExteriorMap
    },
    buildings.length <= 1
      ? {
          label: t('Delete Map'),
          onClick: confirmDeleteExteriorMap
        }
      : null,
    EDIT_GOOGLE_MAPS_INFO || exteriorMap?.isGoogleMap
      ? {
          label: t('Edit Map'),
          onClick: () => navigate('edit')
        }
      : null
  ])

  const hasExteriorMap = !_.isEmpty(exteriorMap)

  return (
    <EditorContainer>
      <AddExteriorMapWorkflow hidden={hasExteriorMap} />
      {hasExteriorMap && (
        <ExteriorMapSection selected={isExteriorMapSelected}>
          <ExteriorMapLeftSection onClick={() => onExteriorMapSelected(exteriorMap.id)}>
            {t('Exterior Map')}
            <MapImageInput
              onFileChange={handleExteriorMapImageUpdate}
              id="replace-exterior-map"
              ref={replaceExteriorMapRef}
            />
          </ExteriorMapLeftSection>
          <MenuPopout options={options} />
        </ExteriorMapSection>
      )}

      <Buildings buildings={buildings} selectedFloorId={selectedId} onFloorSelected={onSelect} />
      <BuildingFactory />

      <Routes>
        <Route path="edit" element={<GoogleMapsForm map={exteriorMap} loading={loading} />} />
      </Routes>
    </EditorContainer>
  )
}

export default MapSidebar
