import { Suspense, useEffect } from 'react'
import { Routes, Route, useLocation, Navigate, Location } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { IAuthState, AuthenticationStatus } from 'client/types'
import _ from 'lodash'
import { fetchToken } from 'client/redux/actions/auth'
import { getAuthData } from 'client/redux/selectors/auth'
import Authenticated from 'client/screens/App/Authenticated'
import Unauthenticated from 'client/screens/App/Unauthenticated'
import 'client/global'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import { t } from 'client/i18n'
import UserMFAType from 'shared/UserMFAType'

function getRedirectPath(authState: IAuthState, location: Location): string | null {
  const {
    mfaType,
    authStatus,
    mfaEnabled,
    mfaEnrolled,
    mfaPassed,
    isPasswordExpired,
    privacyPolicyAcceptedAt,
    isUserLoggingInForFirstTime
  } = authState
  const { pathname } = location

  const isAuthorized = _.includes(
    [AuthenticationStatus.AUTHORIZED, AuthenticationStatus.VERIFIED],
    authStatus
  )

  if (!isAuthorized) {
    const isRestricted = authStatus === AuthenticationStatus.ACCESS_TIME_RESTRICTED

    if (isRestricted) {
      return '/auth/login/restricted'
    }

    // Users are allowed to start password reset process if they aren't logged in
    if (pathname === '/auth/login/forgot-password') {
      return null
    }

    return '/auth/login'
  }

  // A user logging in for the first time should see the Password screen before the MFA screen
  if (isUserLoggingInForFirstTime && isPasswordExpired) {
    return '/auth/reset-password'
  }

  if (mfaEnabled && !mfaEnrolled && mfaType === UserMFAType.TOTP) {
    return '/auth/mfa-enrollment'
  }

  if (mfaEnabled && !mfaPassed) {
    return '/auth/mfa-verification'
  }

  if (isPasswordExpired) {
    return '/auth/reset-password'
  }

  if (!privacyPolicyAcceptedAt) {
    return '/auth/acknowledge-privacy-notice'
  }

  // User is fully logged in
  if (pathname.startsWith('/auth')) {
    return '/'
  }

  return null
}

function AuthRedirectRoute() {
  const authData = useSelector(getAuthData)
  const location = useLocation()
  const redirectPath = getRedirectPath(authData, location)
  const shouldRedirect = redirectPath && location.pathname !== redirectPath

  return shouldRedirect ? (
    <Routes>
      <Route path="*" element={<Navigate replace={true} to={redirectPath} />} />
    </Routes>
  ) : null
}

function useAppRoutes() {
  const dispatch = useDispatch()
  const { mfaEnabled, authStatus, isPasswordExpired, privacyPolicyAcceptedAt } =
    useSelector(getAuthData)

  useEffect(() => {
    dispatch(fetchToken())
  }, [dispatch])

  const needsMFA = mfaEnabled && authStatus !== AuthenticationStatus.VERIFIED
  const shouldLogin =
    authStatus === AuthenticationStatus.UNAUTHORIZED ||
    authStatus === AuthenticationStatus.ACCESS_TIME_RESTRICTED

  if (authStatus === AuthenticationStatus.UNKNOWN) {
    return null
  }

  if (shouldLogin || needsMFA || isPasswordExpired || !privacyPolicyAcceptedAt) {
    return <Unauthenticated />
  }

  return <Authenticated />
}

function App() {
  const routes = useAppRoutes()

  if (!routes) {
    return null
  }

  return (
    <Suspense fallback={<div>{t('Loading...')}</div>}>
      <AuthRedirectRoute />
      {routes}
    </Suspense>
  )
}

// ts-prune-ignore-next
export default App
