import styled from 'styled-components'
import GrabberSVG from 'assets/svg/icon/grabber_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'

const GrabberContainer = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 4px;
  svg {
    color: var(--color-black);
    &:hover {
      color: var(--color-brand);
    }
  }
`

const Grabber = () => {
  return (
    <Tooltip content={t('Drag to reorder')}>
      <GrabberContainer>
        <GrabberSVG />
      </GrabberContainer>
    </Tooltip>
  )
}

export { Grabber }
