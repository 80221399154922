import ContextualHelp, {
  ContextualHelpTextSection
} from 'client/components/ContextualHelp/ContextualHelp'
import { t } from 'client/i18n'

const TourExpirationContextualHelp = () => (
  <ContextualHelp
    tooltipContent={t(
      'More on why you need to convert the tour to an exhibition to keep the content in the guide'
    )}
    helpSize="large"
    helpPlacement="bottom"
    header={t('Convert Tour to Exhibition to Keep Content Visible')}
    helpContent={
      <>
        <ContextualHelpTextSection>
          {t('Tour stops rely on map locations to guide visitors through your space.')}
        </ContextualHelpTextSection>
        <ContextualHelpTextSection>
          {t(
            'Converting an expired tour to an exhibition lets you keep the information in your guide without any locations.'
          )}
        </ContextualHelpTextSection>
      </>
    }
  />
)

export default TourExpirationContextualHelp
