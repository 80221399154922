import { memo, Fragment } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import PencilIconEditButton from 'client/components/Button/PencilIconEditButton'
import { getHowLongAgoText } from 'client/util/featuredGuides'
import { ReorderableGuideRow } from 'client/components/ContentRow/ReorderableRow'
import FeaturedGuidesForm from 'client/screens/Internal/Explore/FeaturedGuidesForm'
import { useGet } from 'client/hooks/api'
import IGuidePickerJson from 'shared/json/IGuidePickerJson'
import _ from 'lodash'
import PageContent from 'client/components/PageContent/PageContent'
import { t } from 'client/i18n'
import { Subheader } from 'client/components/ContentRow/styledComponents'

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  flex-grow: 1;
  padding: var(--spacing-xsmall) 0;
`

const LeftItem = styled.div`
  flex: 1;
  display: flex;
`

const RightItem = styled.div`
  flex: 2;
  justify-content: flex-end;
  display: flex;
  padding-right: 12px;
`

const ItemBorder = styled.div`
  border-top: 1px solid var(--color-grey-03);
  height: 1px;
`

const ExploreRoundedContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-medium) var(--spacing-large) 0 var(--spacing-large);
`

const StyledPencilIconEditButton = styled(PencilIconEditButton)`
  margin-top: var(--spacing-small);
  margin-left: var(--spacing-large);
`

const SoftText = styled(Subheader)`
  color: var(--color-grey-07);
`

const Explore = () => {
  const [featuredGuides, isLoading, refetchFeaturedGuides] =
    useGet<IGuidePickerJson[]>('/api/featured-guides')

  const navigate = useNavigate()

  return (
    <PageContent title={t('New and Featured')}>
      {isLoading ? (
        <LoadingOverlay />
      ) : (
        <>
          <ExploreRoundedContainer>
            {_.map(featuredGuides, (featuredGuide, index) => (
              <Fragment key={featuredGuide.id}>
                <Item>
                  <LeftItem>
                    <ReorderableGuideRow value={featuredGuide} isCompact={true} />
                  </LeftItem>
                  <RightItem>
                    <SoftText>{getHowLongAgoText(featuredGuide.dateAdded)}</SoftText>
                  </RightItem>
                </Item>
                {index < featuredGuides!.length - 1 && <ItemBorder />}
              </Fragment>
            ))}
          </ExploreRoundedContainer>
          <StyledPencilIconEditButton onClick={() => navigate('featuredGuides/edit')} />

          <Routes>
            <Route
              path="featuredGuides/edit"
              element={<FeaturedGuidesForm refetchFeaturedGuides={refetchFeaturedGuides} />}
            />
          </Routes>
        </>
      )}
    </PageContent>
  )
}

export default memo(Explore)
