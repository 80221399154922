import * as React from 'react'
import {
  HeaderSubheaderContainer,
  ImageContainer,
  FullContentRow,
  IFullContentRowProps
} from 'client/components/ContentRow/ReorderableRow'

import { IBaseContentRowWithTags } from 'client/components/ContentRow/types'
import styled from 'styled-components'
import { DescriptionContainer, NoteSpan, Label, IconsContainer } from './styledComponents'

const RowWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`

interface ICompactContentRowProps extends IBaseContentRowWithTags {
  image?: string | React.FunctionComponent
  note?: React.ReactNode
  isSelected?: boolean
}

interface INoteProps {
  children: React.ReactNode
}
export const Note = (props: INoteProps) => {
  const { children } = props
  if (!children) {
    return null
  }

  return <NoteSpan>{children}</NoteSpan>
}

// CompactContentRow is used by the minipicker and the "New and Featured" page.
// We may want to rethink/simplify this CompactContentRow component in the future from being overloaded/trying to do too much.
const CompactContentRow = ({
  image: Image,
  header,
  subheaders = [],
  note,
  isError,
  contentStateCaption,
  tags,
  isSelected
}: ICompactContentRowProps) => {
  return (
    <RowWrapper>
      <ImageContainer isError={isError} image={Image} isPickerRow={true} />
      <DescriptionContainer>
        <HeaderSubheaderContainer
          header={header}
          subheaders={subheaders}
          isPickerRow={true}
          isError={isError}
          contentStateCaption={contentStateCaption}
        />
        <IconsContainer>
          <div>
            {tags?.map((tag) => (
              <Label key={tag} isSelected={isSelected}>
                {tag}
              </Label>
            ))}
          </div>
          <Note>{note}</Note>
        </IconsContainer>
      </DescriptionContainer>
    </RowWrapper>
  )
}

interface IContentRowProps<T> extends IFullContentRowProps<T> {
  isCompact?: boolean
  isSelected?: boolean
}
export const ContentRow = <T extends Object>(props: IContentRowProps<T>) => {
  return props.isCompact ? <CompactContentRow {...props} /> : <FullContentRow {...props} />
}
