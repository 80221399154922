import { useContext, useState } from 'react'
import _ from 'lodash'
import ErrorMessage from 'client/components/Formik/ErrorMessage/ErrorMessage'
import { useFormikContext } from 'formik'
import Button from 'client/components/Button/Button'
import styled from 'styled-components'
import ErrorDialog from 'client/components/ErrorDialog'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'
import { t } from 'client/i18n'
import { UploadMediaType } from 'client/hooks/useMediaUpload'

const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: baseline;
`

const LinkButton = styled(Button).attrs((props) => ({
  ...props,
  type: 'tertiary'
}))`
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  text-decoration: underline;
`

interface IViewErrorDetailsProps {
  details: string
  contentName: Uncapitalize<`${UploadMediaType}`>
}

const ViewErrorDetails = ({ details, contentName }: IViewErrorDetailsProps) => {
  const [showErrorDialog, setShowErrorDialog] = useState(false)

  return (
    <>
      <LinkButton label={t('View details')} onClick={() => setShowErrorDialog(true)} />
      {showErrorDialog ? (
        <ErrorDialog
          // eslint-disable-next-line docent/require-translation-keys-to-be-literals
          title={t(
            `Unable to Process ${_.capitalize(contentName) as Capitalize<typeof contentName>}`
          )}
          error={details}
          onClose={() => setShowErrorDialog(false)}
        />
      ) : null}
    </>
  )
}

interface IProcessingErrorMessageProps extends Pick<IViewErrorDetailsProps, 'contentName'> {
  name: string
}

const ProcessingErrorMessage = (props: IProcessingErrorMessageProps) => {
  const { name, contentName } = props
  const { values, errors, touched } = useFormikContext()
  const { getFieldName } = useContext(TranslationFormContext)

  const dataFieldName = getFieldName(name)
  const processingErrorName = getFieldName('processingError')

  const hasActiveFieldError = _.has(errors, dataFieldName) && _.has(touched, dataFieldName)
  const hasActiveProcessingError =
    _.has(errors, processingErrorName) && _.has(touched, processingErrorName)

  if (hasActiveProcessingError) {
    return (
      <ErrorMessageContainer>
        <ErrorMessage name={processingErrorName} />
        &nbsp;
        <ViewErrorDetails contentName={contentName} details={_.get(values, processingErrorName)} />
      </ErrorMessageContainer>
    )
  }

  if (hasActiveFieldError) {
    return (
      <ErrorMessageContainer>
        <ErrorMessage name={dataFieldName} />
      </ErrorMessageContainer>
    )
  }

  return null
}

export default ProcessingErrorMessage
