import styled from 'styled-components'
import CheckmarkSVG from 'assets/svg/icon/check_20_filled.svg'
import { t } from 'client/i18n'
import { Body1CSS } from 'client/components/TextStyles'
import { format } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'

const CompletedContainer = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 20px 24px;
  column-gap: var(--spacing-xsmall);
  border-bottom: var(--border-light);
`

const CheckmarkIcon = styled(CheckmarkSVG)`
  color: var(--color-green-07);
`

const SuccessText = styled.div`
  ${Body1CSS};
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xsmall);
  color: var(--color-black);
  span:first-child {
    font-size: 16px;
    color: var(--color-green-07);
    font-weight: var(--font-weight-semi-bold);
  }
`

interface CompletedViewProps {
  name: string
  createdAt: Date
}
export const CompletedView = ({ name, createdAt }: CompletedViewProps) => (
  <CompletedContainer>
    <CheckmarkIcon />
    <SuccessText>
      <span>{t('Last Uploaded')}</span>
      <span>
        {t('bulkUpload.lastUploaded.message', {
          name,
          date: format(createdAt, `${UNIVERSAL_DATE_FORMAT} hh:mm:ss a`)
        })}
      </span>
    </SuccessText>
  </CompletedContainer>
)
