import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { getAuthError } from 'client/redux/selectors/auth'
import StringInput from 'client/components/Form/StringInput/StringInput'
import Button from 'client/components/Button/Button'
import {
  clearAuthError,
  login,
  loginError as loginErrorAction,
  loginSuccess
} from 'client/redux/actions/auth'
import { NavLink, useSearchParams } from 'react-router-dom'
import SupportFooter from 'client/components/SupportFooter'
import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import FormField from 'client/components/Form/FormField/FormField'
import _ from 'lodash'
import api from 'client/util/api'
import { parseTokenToObject } from 'client/util/auth'
import AuthenticationErrorType from 'shared/AuthenticationErrorType'
import { StyledBanner, StyledBannerItem } from 'client/screens/styledComponents'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const StyledNavLink = styled(NavLink)`
  font-weight: var(--font-weight-regular);
`

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: var(--spacing-xlarge);
`

const StyledButton = styled(Button)`
  width: 100%;
`

const ERROR_MESSAGE_AND_INSTRUCTION_MAP = {
  [AuthenticationErrorType.UNRECOGNIZED_LOGIN_CREDENTIALS]: {
    message: t('Unrecognized login credentials'),
    instruction: t('Enter a valid email and password.')
  },
  [AuthenticationErrorType.EXPIRED_PASSWORD_RESET_LINK]: {
    message: t('Expired password reset link'),
    instruction: (
      <Trans i18nKey="Log in with your email and password or click Forgot password? to send a new link to reset your password." />
    )
  },
  [AuthenticationErrorType.EXPIRED_PLATFORM_INVITE_LINK]: {
    message: t('Expired CMS invitation link'),
    instruction: t('Reach out to your partnership manager to request a new CMS invitation link.')
  }
}

const Login = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const authError = useSelector(getAuthError)
  const authErrorMessage = authError?.message
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()
  const resetPasswordToken = searchParams.get('token')

  const loginWithPasswordResetToken = useCallback(async () => {
    try {
      setIsLoading(true)
      const { data } = await api.post<{ token: string }>(`/auth/login/${resetPasswordToken}`)
      const parsedToken = parseTokenToObject(data.token)
      dispatch(loginSuccess({ data, ...parsedToken }))
    } catch (e) {
      const { status: statusCode, data } = e.response
      const { message } = data
      if (message) {
        dispatch(loginErrorAction({ statusCode, message }))
      }
    } finally {
      setIsLoading(false)
    }
  }, [dispatch, resetPasswordToken])

  const loginError = useMemo(
    () =>
      _.includes(
        [
          AuthenticationErrorType.UNRECOGNIZED_LOGIN_CREDENTIALS,
          AuthenticationErrorType.EXPIRED_PASSWORD_RESET_LINK,
          AuthenticationErrorType.EXPIRED_PLATFORM_INVITE_LINK
        ],
        authErrorMessage
      )
        ? authErrorMessage
        : null,
    [authErrorMessage]
  )

  useEffect(() => {
    if (!_.isEmpty(resetPasswordToken) && !isLoading) {
      loginWithPasswordResetToken()
    }

    // If a user attempts to log in with incorrect credentials, they'll see the `Unrecognized login credentials` error banner on the page.
    // If they've forgotton their password, they'll click on the `Forgot Password?` link.
    // Once they're on the `ForgotPassword` screen, if they click `Back` to go back to the Login screen, they shouldn't still see the error banner.
    return () => {
      dispatch(clearAuthError())
    }
  }, [dispatch, isLoading, loginWithPasswordResetToken, resetPasswordToken])

  const onEmailChange = (event) => setEmail(event.target.value)
  const onPasswordChange = (event) => setPassword(event.target.value)

  const onSubmit = (event?: any) => {
    if (event) {
      event.preventDefault()
    }
    dispatch(login(email, password))
  }
  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <ContainerWithConnectsLogo onKeyPress={onKeyPress} isLoading={isLoading}>
      {loginError && (
        <StyledBanner
          type="error"
          headingText={ERROR_MESSAGE_AND_INSTRUCTION_MAP[loginError].message}
        >
          <StyledBannerItem>
            {ERROR_MESSAGE_AND_INSTRUCTION_MAP[loginError].instruction}
          </StyledBannerItem>
        </StyledBanner>
      )}
      <form onSubmit={onSubmit}>
        <FormField label={t('Email')}>
          <StringInput name="email" value={email} onChange={onEmailChange} />
        </FormField>
        <FormField
          label={t('Password')}
          hint={
            <StyledNavLink
              to={`/auth/login/forgot-password${
                _.isEmpty(email) ? '' : `?email=${encodeURIComponent(email)}`
              }`}
            >
              {t('Forgot password?')}
            </StyledNavLink>
          }
        >
          <StringInput
            name="password"
            password={true}
            value={password}
            onChange={onPasswordChange}
          />
        </FormField>
        <ButtonContainer>
          <StyledButton
            type="primary"
            disabled={!email || !password}
            onClick={onSubmit}
            label={t('Next')}
          />
        </ButtonContainer>
      </form>
      <SupportFooter />
    </ContainerWithConnectsLogo>
  )
}

export default Login
