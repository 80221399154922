import { useContext, useMemo } from 'react'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'
import Section from 'client/components/Form/Section/Section'
import ContentLookupNumberSelect from 'client/screens/Catalog/forms/shared/LookupNumber/ContentLookupNumberSelect'
import {
  LookupSectionContent,
  SelectContainer
} from 'client/screens/Catalog/forms/shared/styledComponents'
import { t } from 'client/i18n'

interface IItemLookupNumberSelect {
  name: string
}

const ItemLookupNumberSelect = (props: IItemLookupNumberSelect) => {
  const { name } = props
  const { getFieldValue, initialValues } = useContext(TranslationFormContext)
  const { lookupNumber: initialLookupNumber } = initialValues!

  // Note 'getFieldValue' changes reference every render, so using the actual value
  // for deps.
  const lookupNumberFieldValue = getFieldValue('lookupNumber')
  const usedLookupMapping = useMemo(
    () => ({ [initialLookupNumber]: lookupNumberFieldValue }),
    [initialLookupNumber, lookupNumberFieldValue]
  )

  return (
    <LookupSectionContent>
      <Section title={t('Lookup Number')} />
      <SelectContainer>
        <ContentLookupNumberSelect name={name} usedLookupMapping={usedLookupMapping} />
      </SelectContainer>
    </LookupSectionContent>
  )
}

export default ItemLookupNumberSelect
