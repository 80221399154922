import StandardForm, {
  SectionGroup,
  StyledSection
} from 'client/components/StandardForm/StandardForm'
import { Formik } from 'formik'
import { t } from 'client/i18n'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getExterior } from 'client/redux/selectors/maps'
import { createBuilding, updateBuilding } from 'client/redux/actions/maps'
import TextInputField from 'client/components/Formik/TextInput/TextInput'
import { IBuildingJson } from 'shared/json/IBuildingJson'
import { IBuildingsURLParams } from 'client/types'
import { useGet } from 'client/hooks/api'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import _ from 'lodash'
import useLatLongValidation from 'client/hooks/useLatLongValidation'
import * as yup from 'yup'
import FormErrorBanner from 'client/dsm/Banner/FormErrorBanner'
import useFormikErrors from 'client/hooks/useFormikErrors'
import { LatitudeTextField, LongitudeTextField } from '../GoogleMapFields'

const BuildingFormErrorBanner = () => {
  const errors = useFormikErrors()
  return <FormErrorBanner errorMap={errors} />
}

const BuildingForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isGoogleMap = useSelector(getExterior)?.isGoogleMap ?? false
  const { editBuildingId: buildingId } = useParams<IBuildingsURLParams>()
  const validateLatLong = useLatLongValidation()

  const [fetchedBuildings, isFetchedBuildingsLoading] = useGet<IBuildingJson[]>('/maps/building')
  const building = _.find(fetchedBuildings, { id: _.toNumber(buildingId) })

  const onSubmit = (values) => {
    if (!validateLatLong(values.latitude, values.longitude)) {
      return
    }

    if (buildingId) {
      dispatch(
        updateBuilding({
          id: buildingId,
          name: values.name,
          mapLocation: {
            latitude: values.latitude,
            longitude: values.longitude
          }
        })
      )
    } else {
      dispatch(
        createBuilding({
          name: values.name,
          latitude: values.latitude,
          longitude: values.longitude
        })
      )
    }

    navigate('..')
  }

  const initialValues = buildingId
    ? {
        name: building?.name,
        latitude: building?.mapLocation?.latitude,
        longitude: building?.mapLocation?.longitude
      }
    : {}

  if (isFetchedBuildingsLoading) {
    return <LoadingOverlay />
  }

  const validationSchema = yup.object().shape({
    name: yup.string().nullable().required()
  })

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      handleCancel={() => navigate('..')}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ handleSubmit }) => {
        return (
          <StandardForm
            title={buildingId ? t('Edit Building') : t('Add Building')}
            onCancel={() => navigate('..')}
            onSave={handleSubmit}
            fullWidth={true}
          >
            <BuildingFormErrorBanner />
            <SectionGroup>
              <StyledSection>
                <TextInputField name="name" label={t('* Name')} />
                {isGoogleMap && (
                  <>
                    <LatitudeTextField name="latitude" />
                    <LongitudeTextField name="longitude" />
                  </>
                )}
              </StyledSection>
            </SectionGroup>
          </StandardForm>
        )
      }}
    </Formik>
  )
}

export default BuildingForm
