import { formatDuration, intervalToDuration } from 'date-fns'

export function formatDurationInHoursMinutesSeconds(seconds: number) {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 })

  // We need to set these to 0 if they are undefined, otherwise they will not be included in the formatted string
  duration.minutes = duration.minutes ?? 0
  duration.seconds = duration.seconds ?? 0

  const zeroPad = (num) => String(num).padStart(2, '0')

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (token, count) => zeroPad(count)
    }
  })
}
