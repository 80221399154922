import { put, takeEvery, call, select } from 'redux-saga/effects'
import _ from 'lodash'
import api from 'client/util/api'
import { confirm } from 'client/redux/actions/confirmation'
import {
  fetchBuildingsSuccess,
  fetchBuildingsError,
  updateBuildingSuccess,
  updateBuildingError,
  IUpdateBuilding,
  ICreateExteriorMap,
  createExteriorMapSuccess,
  createExteriorMapError,
  IUpdateExteriorMap,
  updateExteriorMapSuccess,
  updateExteriorMapError,
  IDeleteExteriorMap,
  deleteExteriorMapSuccess,
  deleteExteriorMapError,
  fetchExteriorSuccess,
  fetchExteriorError,
  ICreateBuildingFloor,
  createBuildingFloorSuccess,
  createBuildingFloorError,
  IDeleteBuildingFloor,
  deleteBuildingFloorSuccess,
  deleteBuildingFloorError,
  IUpdateBuildingFloor,
  updateBuildingFloorSuccess,
  updateBuildingFloorError,
  IUpdateBuildingFloorOrder,
  updateBuildingFloorOrderSuccess,
  updateBuildingFloorOrderError,
  ICreateBuildingFloorPin,
  createBuildingFloorPinSuccess,
  createBuildingFloorPinError,
  IUpdateBuildingFloorPinContent,
  updateBuildingFloorPinContentSuccess,
  updateBuildingFloorPinContentError,
  IUpdateFloorPin,
  IUpdateBuildingFloorPin,
  updateBuildingFloorPinSuccess,
  updateBuildingFloorPinError,
  IDeleteBuildingFloorPin,
  deleteBuildingFloorPinSuccess,
  deleteBuildingFloorPinError,
  updateSelectedFloorPinId,
  IUpdateExteriorFloorPin,
  updateExteriorPinSuccess,
  updateExteriorPinError,
  IExteriorPinContentCreateAction,
  createExteriorMapPinSuccess,
  createExteriorMapPinError,
  IExteriorPinContentUpdateAction,
  updateExteriorMapPinContentSuccess,
  updateExteriorMapPinContentError,
  IExteriorPinDeleteAction,
  deleteExteriorMapPinError,
  deleteExteriorMapPinSuccess,
  ICreateBuildingAction,
  IDeleteBuildingAction,
  createBuildingSuccess,
  createBuildingError,
  deleteBuildingSuccess,
  deleteBuildingError,
  fetchExterior,
  fetchPinContent,
  IFetchPinContentAction,
  fetchPinContentSuccess
} from 'client/redux/actions/maps'
import { MapsActionTypes } from 'client/redux/actions/types'
import { createGetMapLocationByIdSelector, getBuildings } from 'client/redux/selectors/maps'
import { navigateTo } from 'client/redux/sagas/utils/navigation'
import { t } from 'client/i18n'
import { toastSuccess } from './toast'

const createFormData = (obj) => {
  const form = new FormData()
  _.each(obj, (value, key) => {
    if (!_.isUndefined(value)) {
      form.append(key, value)
    }
  })
  return form
}

function* deleteMapPin(mapId, pinId) {
  return yield call(api.delete, `/maps/floor/${mapId}/pin/${pinId}`)
}

function* alertError(msg: string) {
  yield put(
    confirm({
      title: t('Unable to Save Changes'),
      message: msg,
      confirmYes: { label: t('OK') },
      isAlert: true
    })
  )
}

function* handleBuildingCreateComplete() {
  yield call(toastSuccess)
}

function* handleBuildingCreate(action: ICreateBuildingAction) {
  const { name, latitude, longitude } = action
  try {
    const result = yield call(api.post, '/maps/building', {
      building: { name, latitude, longitude }
    })
    yield put(fetchExterior())
    yield put(createBuildingSuccess(result.data))
  } catch {
    yield call(alertError, t("We weren't able to create the building. Please try again later."))
    yield put(createBuildingError())
  }
}

function* handleBuildingDelete(action: IDeleteBuildingAction) {
  const { id, isSelected } = action
  try {
    yield call(api.delete, `/maps/building/${id}`)
    yield put(fetchExterior())
    yield put(deleteBuildingSuccess(id))
    if (isSelected) {
      // selected building deleted. url is updated to point to another building
      const buildings = yield select(getBuildings)
      yield call(navigateTo, `/app-editor/maps/building/${_.first(_.keys(buildings))}/floor`)
    }
    yield call(toastSuccess)
  } catch {
    yield call(alertError, t("We weren't able to delete the building. Please try again later."))
    yield put(deleteBuildingError())
  }
}

function* handleBuildingFetch() {
  try {
    const result = yield call(api.get, '/maps/building')
    yield put(fetchBuildingsSuccess(result.data))
  } catch {
    yield call(
      alertError,
      t("We weren't able to fetch the building information. Please try again later.")
    )
    yield put(fetchBuildingsError())
  }
}

function* handleBuildingUpdate(action: IUpdateBuilding) {
  const { building } = action
  try {
    const result = yield call(api.put, `/maps/building/${building.id}`, { building })
    yield put(updateBuildingSuccess(result.data))
    yield call(toastSuccess)
  } catch {
    yield call(alertError, t("We weren't able to update the building. Please try again later."))
    yield put(updateBuildingError())
  }
}

function* handleExteriorFetch() {
  try {
    const result = yield call(api.get, '/maps/exterior')
    yield put(fetchExteriorSuccess(result.data))
  } catch {
    yield call(alertError, t("We weren't able to fetch the exterior map. Please try again later."))
    yield put(fetchExteriorError())
  }
}

function* handleExteriorCreate(action: ICreateExteriorMap) {
  const { image } = action
  const formData = createFormData({ image })

  try {
    const result = yield call(api.post, '/maps/exterior', formData)
    // Refresh building
    yield handleBuildingFetch()
    yield put(createExteriorMapSuccess(result.data))
    yield call(navigateTo, `/app-editor/maps/exterior/${result.data.id}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to upload the exterior map. Please try again later."))
    yield put(createExteriorMapError(error))
  }
}

function* handleExteriorUpdate(action: IUpdateExteriorMap) {
  const formData = createFormData(_.omit(action, 'type'))

  try {
    const result = yield call(api.put, '/maps/exterior', formData)
    yield put(updateExteriorMapSuccess(result.data))
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to update the exterior map. Please try again later."))
    yield put(updateExteriorMapError(error))
  }
}

function* handleExteriorDelete(action: IDeleteExteriorMap) {
  const { isExteriorMapSelected } = action
  try {
    yield call(api.delete, '/maps/exterior')
    yield put(deleteExteriorMapSuccess())
    if (isExteriorMapSelected) {
      yield call(navigateTo, '/app-editor/maps/exterior')
    }
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to delete the exterior map. Please try again later."))
    yield put(deleteExteriorMapError(error))
  }
}

function* handleExteriorPinContentCreate(action: IExteriorPinContentCreateAction) {
  const { mapId, locationData } = action
  try {
    const {
      data: { location }
    } = yield call(api.post, `/maps/floor/${mapId}/pin`, locationData)
    yield put(createExteriorMapPinSuccess({ location }))
    yield put(fetchPinContent(mapId, location.id))
    yield put(updateSelectedFloorPinId(location.id))
    yield call(navigateTo, `/app-editor/maps/exterior/${mapId}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to create the map pin. Please try again later."))
    yield put(createExteriorMapPinError())
  }
}

function* handleExteriorPinContentUpdate(action: IExteriorPinContentUpdateAction) {
  const { mapId, locationData: pin } = action
  try {
    const result = yield call(api.put, `/maps/floor/${mapId}/pin/${pin.id}/content`, pin)
    yield put(updateExteriorMapPinContentSuccess({ location: result.data }))
    yield put(fetchPinContent(mapId, pin.id))
    yield put(updateSelectedFloorPinId(result.data.id))
    yield call(navigateTo, `/app-editor/maps/exterior/${mapId}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to update the map pin. Please try again later."))
    yield put(updateExteriorMapPinContentError())
  }
}

function* handleExteriorPinContentDelete(action: IExteriorPinDeleteAction) {
  const { mapId, pinId } = action
  try {
    yield deleteMapPin(mapId, pinId)
    yield put(deleteExteriorMapPinSuccess({ mapId, pinId }))
    yield call(navigateTo, `/app-editor/maps/exterior/${mapId}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to delete the floor pin. Please try again later."))
    yield put(deleteExteriorMapPinError())
  }
}

function* handleBuildingFloorCreate(action: ICreateBuildingFloor) {
  const { buildingId, image } = action
  const formData = createFormData({ image })

  try {
    const result = yield call(api.post, `/maps/building/${buildingId}/floor`, formData)
    yield put(createBuildingFloorSuccess(result.data))
    yield call(navigateTo, `/app-editor/maps/building/${buildingId}/floor/${result.data.id}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to create the floor. Please try again later."))
    yield put(createBuildingFloorError())
  }
}

function* handleBuildingFloorOrderUpdate(action: IUpdateBuildingFloorOrder) {
  const { buildingId, orderedFloorIds } = action
  const buildings = yield select(getBuildings)
  const building = buildings[buildingId]

  // optimistic update.
  yield put(
    updateBuildingFloorOrderSuccess({
      buildingId,
      floors: _.map(building.floors, (floor) => ({
        ...floor,
        position: _.indexOf(orderedFloorIds, floor.id)
      }))
    })
  )

  try {
    const result = yield call(api.put, `/maps/building/${buildingId}/floor/reorder`, {
      orderedFloorIds
    })
    yield put(updateBuildingFloorOrderSuccess(result.data))
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to create the floor. Please try again later."))
    yield put(updateBuildingFloorOrderError())
    // Revert
    yield put(
      updateBuildingFloorOrderSuccess({
        buildingId,
        floors: building.floors
      })
    )
  }
}

function* handleBuildingFloorUpdate(action: IUpdateBuildingFloor) {
  const { floor, image } = action
  const { buildingId } = floor
  const formData = createFormData({ ...floor, image })
  try {
    const result = yield call(api.put, `/maps/building/${buildingId}/floor/${floor.id}`, formData)
    yield put(updateBuildingFloorSuccess(result.data))
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to update the floor. Please try again later."))
    yield put(updateBuildingFloorError())
  }
}

function* handleBuildingFloorDelete(action: IDeleteBuildingFloor) {
  const { buildingId, floorId, isFloorSelected } = action
  try {
    const result = yield call(api.delete, `/maps/building/${buildingId}/floor/${floorId}`)
    yield put(deleteBuildingFloorSuccess(result.data))
    if (isFloorSelected) {
      yield call(navigateTo, `/app-editor/maps/building/${buildingId}/floor/`)
    }
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to create the floor. Please try again later."))
    yield put(deleteBuildingFloorError())
  }
}

function* handleBuildingFloorPinCreate(action: ICreateBuildingFloorPin) {
  const { buildingId, floorId, locationData } = action
  try {
    const result = yield call(api.post, `/maps/floor/${floorId}/pin`, locationData)
    yield put(createBuildingFloorPinSuccess({ ...result.data, buildingId }))
    yield put(fetchPinContent(floorId, result.data.location.id))
    yield put(updateSelectedFloorPinId(result.data.location.id))
    yield call(navigateTo, `/app-editor/maps/building/${buildingId}/floor/${floorId}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to create the floor pin. Please try again later."))
    yield put(createBuildingFloorPinError())
  }
}

function* handleFetchPinContent(action: IFetchPinContentAction) {
  const { floorId, locationId } = action
  try {
    const { data } = yield call(api.get, `/maps/floor/${floorId}/pin/${locationId}/content`)
    yield put(fetchPinContentSuccess(data, locationId))
  } catch (error) {
    yield call(alertError, t("We weren't able to fetch the pin content. Please try again later."))
  }
}

function* handleFloorPinUpdate(action: IUpdateFloorPin, onSuccessAction, onErrorAction) {
  const { buildingId, floorId, pinData } = action
  const { id: pinId, coordinates, radius } = pinData

  const originalLocation = yield select(
    createGetMapLocationByIdSelector({
      buildingId,
      floorId,
      locationId: pinId
    })
  )

  const locationPropertiesToUpdate = _.omitBy(
    {
      xPosition: _.isFinite(coordinates?.x) ? Math.round(coordinates!.x) : undefined,
      yPosition: _.isFinite(coordinates?.y) ? Math.round(coordinates!.y) : undefined,
      radius: _.isFinite(radius) ? Math.round(radius!) : undefined
    },
    _.isUndefined
  )

  // optimistic update
  yield put(
    onSuccessAction({
      buildingId,
      floorId,
      location: {
        ...originalLocation,
        ...locationPropertiesToUpdate
      }
    })
  )

  try {
    const result = yield call(
      api.put,
      `/maps/floor/${floorId}/pin/${pinId}`,
      locationPropertiesToUpdate
    )
    const location = _.get(result, 'data.location')
    if (location) {
      yield call(toastSuccess)
      yield put(onSuccessAction({ buildingId, floorId, location }))
    } else {
      throw new Error()
    }
  } catch (e) {
    yield put(onErrorAction())
    // Revert to previous location
    const location = _.get(e, 'response.data.location')
    if (!_.isEmpty(location)) {
      yield put(onSuccessAction({ buildingId, floorId, location }))
    }
    // error occured and we were not able to handle resetting back to DB
    yield call(alertError, t("We weren't able to update the pin position. Please try again later."))
  }
}

function* handleBuildingFloorPinUpdate(action: IUpdateBuildingFloorPin) {
  yield call(
    handleFloorPinUpdate,
    action,
    updateBuildingFloorPinSuccess,
    updateBuildingFloorPinError
  )
}

function* handleExteriorFloorPinUpdate(action: IUpdateExteriorFloorPin) {
  yield call(handleFloorPinUpdate, action, updateExteriorPinSuccess, updateExteriorPinError)
}

function* handleBuildingFloorPinLinkedContentUpdate(action: IUpdateBuildingFloorPinContent) {
  const { buildingId, floorId, locationData: pin } = action
  try {
    const result = yield call(api.put, `/maps/floor/${floorId}/pin/${pin.id}/content`, pin)
    const location = { ...result.data }
    yield put(updateBuildingFloorPinContentSuccess({ location, floorId, buildingId }))
    yield put(fetchPinContent(floorId, pin.id))
    yield put(updateSelectedFloorPinId(result.data.id))
    yield call(navigateTo, `/app-editor/maps/building/${buildingId}/floor/${floorId}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to update the floor pin. Please try again later."))
    yield put(updateBuildingFloorPinContentError())
  }
}

function* handleBuildingFloorPinDelete(action: IDeleteBuildingFloorPin) {
  const { buildingId, floorId, pinId } = action
  try {
    const result = yield deleteMapPin(floorId, pinId)
    yield put(
      deleteBuildingFloorPinSuccess({
        ...result.data,
        buildingId
      })
    )
    yield call(navigateTo, `/app-editor/maps/building/${buildingId}/floor/${floorId}`)
    yield call(toastSuccess)
  } catch (error) {
    yield call(alertError, t("We weren't able to delete the floor pin. Please try again later."))
    yield put(deleteBuildingFloorPinError())
  }
}

export default function* root(): IterableIterator<any> {
  yield takeEvery(MapsActionTypes.MAPS_BUILDINGS_FETCH_START, handleBuildingFetch)
  yield takeEvery(MapsActionTypes.MAPS_BUILDING_UPDATE_START, handleBuildingUpdate)

  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_FETCH_START, handleExteriorFetch)
  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_CREATE_START, handleExteriorCreate)
  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_UPDATE_START, handleExteriorUpdate)
  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_DELETE_START, handleExteriorDelete)

  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_PIN_CREATE_START, handleExteriorPinContentCreate)
  yield takeEvery(
    MapsActionTypes.MAPS_EXTERIOR_PIN_LINKED_CONTENT_UPDATE_START,
    handleExteriorPinContentUpdate
  )

  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_PIN_DELETE_START, handleExteriorPinContentDelete)

  yield takeEvery(MapsActionTypes.MAPS_BUILDING_CREATE_START, handleBuildingCreate)

  yield takeEvery(MapsActionTypes.MAPS_BUILDING_CREATE_COMPLETE, handleBuildingCreateComplete)

  yield takeEvery(MapsActionTypes.MAPS_BUILDING_DELETE_START, handleBuildingDelete)

  yield takeEvery(
    MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_START,
    handleBuildingFloorOrderUpdate
  )

  yield takeEvery(MapsActionTypes.MAPS_BUILDING_FLOOR_CREATE_START, handleBuildingFloorCreate)
  yield takeEvery(MapsActionTypes.MAPS_BUILDING_FLOOR_UPDATE_START, handleBuildingFloorUpdate)
  yield takeEvery(MapsActionTypes.MAPS_BUILDING_FLOOR_DELETE_START, handleBuildingFloorDelete)

  yield takeEvery(
    MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_CREATE_START,
    handleBuildingFloorPinCreate
  )

  yield takeEvery(
    MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_UPDATE_START,
    handleBuildingFloorPinUpdate
  )

  yield takeEvery(MapsActionTypes.MAPS_EXTERIOR_PIN_UPDATE_START, handleExteriorFloorPinUpdate)

  yield takeEvery(
    MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_LINKED_CONTENT_UPDATE_START,
    handleBuildingFloorPinLinkedContentUpdate
  )
  yield takeEvery(
    MapsActionTypes.MAPS_BUILDING_FLOOR_PIN_DELETE_START,
    handleBuildingFloorPinDelete
  )
  yield takeEvery(MapsActionTypes.FETCH_PIN_CONTENT_START, handleFetchPinContent)
}
