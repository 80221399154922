import { useCallback, useState } from 'react'
import * as React from 'react'
import _ from 'lodash'

export interface IStepProps {
  onCancel: () => void
  onNext: (stepName: string) => void
}

interface IStep {
  name: string
  component: React.FC<IStepProps>
}

interface IProps {
  workflowSteps: IStep[]
  onCancel?: () => void
}

/* TODO: we will need to revisit the options on scaling the workflow manager before the next feature/task
       that requires a workflow. The main aspects that need to be considered is keeping
       each step unaware of the next step and remove useEffect but still keeping the workflow manager
       workflow agnostic (not hardcoded to a specific workflow)
*/

const WorkflowManager = React.memo((props: IProps) => {
  const [step, setStep] = useState(0)
  const [cancel, setCancel] = useState(false)
  const { workflowSteps, onCancel = _.noop } = props

  const handleCancel = useCallback(() => {
    setCancel(true)
    onCancel()
  }, [onCancel])

  const jumpTo = useCallback(
    (stepName: string) => {
      if (!stepName) {
        handleCancel()
      }

      const newStep = _.findIndex(workflowSteps, ({ name }) => name === stepName)
      const backToStep = newStep === -1 ? 0 : newStep
      setStep(backToStep)
    },
    [handleCancel, workflowSteps]
  )

  if (cancel) {
    return null
  }

  const { component: Component } = workflowSteps[step]

  return <Component onCancel={handleCancel} onNext={jumpTo} />
})

export default WorkflowManager
