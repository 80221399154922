import _ from 'lodash'
import styled from 'styled-components'
import { ITimeRange } from 'shared/json/IMuseumLocationDay'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import RemoveIconSVG from 'assets/svg/icon/x_20.svg'
import { HourOfDay, MinuteOfHour } from 'shared/CalendarTypes'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { t } from 'client/i18n'
import { ICommonDayProps } from '../types'
import HoursInputRow, { IHoursInputRowChangePayload } from './HoursInputRow'

const MAX_RANGES_PER_DAY = 10
const generateDefaultTimeRange = () => ({
  id: -Math.floor(Math.random() * 1000000000),
  start: { hour: 10 as HourOfDay, minute: 0 as MinuteOfHour },
  end: { hour: 17 as HourOfDay, minute: 0 as MinuteOfHour }
})

const HoursRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
  justify-content: flex-end;
`

const MenuPopoutContainer = styled.div`
  display: flex;
  position: relative;
  span {
    display: flex;
    align-items: center;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-width: 368px;
`

const AddHoursButton = styled(PlusIconAddButton)`
  height: 40px;
`

const RemoveIcon = styled(RemoveIconSVG)`
  width: 20px;
  height: 20px;
`

const RemoveIconContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`

const validateAddHoursOption = (ranges: ITimeRange[] = []): boolean =>
  ranges.length < MAX_RANGES_PER_DAY

export default (props: ICommonDayProps) => {
  const { hours = [], onUpdateDay, name, open24Hours } = props
  const hasHours = !_.isEmpty(hours)
  const isOpen = open24Hours || hasHours

  const onAddHours = () =>
    onUpdateDay({
      name,
      open24Hours,
      hours: [...hours, generateDefaultTimeRange()]
    })

  const onRemoveHours = (range: ITimeRange) => () =>
    onUpdateDay({
      name,
      open24Hours: false,
      hours: _.reject(hours, { id: range.id })
    })

  const onChange = ({ range }: IHoursInputRowChangePayload) => {
    const isOpen24Hours = _.isNil(range)
    const index = _.findIndex(hours, { id: range?.id })
    const updatedHours = isOpen24Hours
      ? [hours[0]]
      : [...hours.slice(0, index), range, ...hours.slice(index + 1, hours.length)]

    onUpdateDay({
      name,
      open24Hours: isOpen24Hours,
      hours: updatedHours
    })
  }

  const options = (range: ITimeRange): IMenuOption[] =>
    _.compact([
      {
        label: t('Delete Hours'),
        onClick: onRemoveHours(range)
      },
      validateAddHoursOption(hours) && {
        label: t('Add Hours Below'),
        onClick: onAddHours
      }
    ])

  return (
    <InputContainer>
      {!isOpen && <AddHoursButton label={t('Add Hours')} onClick={onAddHours} />}
      {isOpen &&
        hours.map((hour) => (
          <HoursRowContainer key={`hour-${hour.id}`}>
            <HoursInputRow open24Hours={open24Hours} onChange={onChange} range={hour} />
            {open24Hours ? (
              <RemoveIconContainer onClick={onRemoveHours(hour)}>
                <RemoveIcon />
              </RemoveIconContainer>
            ) : (
              <MenuPopoutContainer>
                <MenuPopout options={options(hour)} />
              </MenuPopoutContainer>
            )}
          </HoursRowContainer>
        ))}
    </InputContainer>
  )
}
