import styled from 'styled-components'
import { t } from 'client/i18n'
import WarningSVG from 'assets/svg/icon/status_warning_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { useQuery } from '@apollo/client'
import gql from 'graphql-tag'
import { GQLExhibit, GQLMuseum } from 'shared/graphql/types/graphql'
import _ from 'lodash'
import { StyledNavLink } from './styledComponents'

const TOURS_QUERY = gql`
  query allTours($museumId: Int!) {
    museum(id: $museumId) {
      tours {
        numItemsWithoutMapLocations
      }
    }
  }
`

const StyledTourNavLink = styled(StyledNavLink)`
  display: flex;
  justify-content: space-between;
`

function ToursWarningIndicator() {
  const { data } = useQuery<{ museum: GQLMuseum }>(TOURS_QUERY)

  const numToursWithMissingMapLocationsCount = _.filter(
    data?.museum?.tours,
    (tour: GQLExhibit) => tour.numItemsWithoutMapLocations > 0
  ).length

  if (numToursWithMissingMapLocationsCount === 0) {
    return null
  }

  return (
    <Tooltip
      content={t('leftNavTourItemsMissingMapLocationsTooltipMessage', {
        count: numToursWithMissingMapLocationsCount
      })}
      placement="right"
    >
      <div>
        <WarningSVG />
      </div>
    </Tooltip>
  )
}

const TourNavLink = () => {
  return (
    <StyledTourNavLink to="/catalog/tours">
      {t('Tours')}
      <ToursWarningIndicator />
    </StyledTourNavLink>
  )
}

export default TourNavLink
