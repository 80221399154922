import IVersionInfo from 'shared/VersionInfo'

const LOCAL_STORAGE_KEY = 'selectedVersion'

export function removeVersionInfoFromLocalStorage() {
  localStorage.removeItem(LOCAL_STORAGE_KEY)
}

export function setVersionInfoInLocalStorage(version: Partial<IVersionInfo>) {
  if (!version) {
    removeVersionInfoFromLocalStorage()
  } else {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(version))
  }
}

export function getVersionInfoFromLocalStorage() {
  const selectedVersionString = localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!selectedVersionString) {
    return null
  }

  return JSON.parse(selectedVersionString) as IVersionInfo
}
