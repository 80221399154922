import { memo } from 'react'
import { Formik } from 'formik'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import StandardForm, { StyledSection } from 'client/components/StandardForm/StandardForm'
import FormField from 'client/components/Form/FormField/FormField'
import { GQLGuide } from 'shared/graphql/types/graphql'
import { usePost, useGet } from 'client/hooks/api'
import IGuidePickerJson from 'shared/json/IGuidePickerJson'
import GuideListWithPicker from 'client/components/GuideListWithPicker'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import { Body1CSS } from 'client/components/TextStyles'

const ItemFormField = styled(FormField)`
  margin-top: var(--spacing-medium);
  margin-bottom: var(--spacing-medium);
`

const GuideSection = styled(StyledSection)`
  margin-top: 0;
  padding-top: 0;
  border-top: 0;
`

const NoteSection = styled.div`
  ${Body1CSS}
`

interface IFormFields {
  featuredGuides: Partial<GQLGuide>[]
}

interface IFormikFieldErrors {
  featuredGuides?: string
}

const MAX_GUIDES = 3

function useFeaturedGuidesPost(
  refetchFeaturedGuides: IFeaturedGuidesFormProps['refetchFeaturedGuides']
) {
  const navigate = useNavigate()
  return usePost('/api/featured-guides', {
    onSuccess: async () => {
      await refetchFeaturedGuides()
      navigate('..')
    }
  })
}

interface IFeaturedGuidesFormProps {
  refetchFeaturedGuides: () => Promise<any>
}

const FeaturedGuidesForm = ({ refetchFeaturedGuides }: IFeaturedGuidesFormProps) => {
  const navigate = useNavigate()

  const [featuredGuides, isFeaturedGuidesLoading] =
    useGet<IGuidePickerJson[]>('/api/featured-guides')

  const [updateFeaturedGuides, isFeaturedGuidesUpdating] =
    useFeaturedGuidesPost(refetchFeaturedGuides)

  const loading = isFeaturedGuidesLoading || isFeaturedGuidesUpdating

  const onCancel = () => navigate('..')

  const initialValues = {
    featuredGuides: featuredGuides || []
  }

  const save = async (values: IFormFields) => {
    await updateFeaturedGuides(_.map(values.featuredGuides, 'id'))
  }

  const handleValidate = (values: IFormFields) => {
    const errors: IFormikFieldErrors = {}
    if (values.featuredGuides.length < 3) {
      errors.featuredGuides = t('You must choose 3 guides before making New and Featured live')
    }

    return errors
  }

  return (
    <Formik<IFormFields>
      onSubmit={save}
      initialValues={initialValues}
      enableReinitialize={true}
      validate={handleValidate}
    >
      {({ handleSubmit, values }) => (
        <StandardForm
          title={t('Edit New and Featured')}
          onCancel={onCancel}
          onSave={handleSubmit}
          enableSave={values.featuredGuides.length <= 3}
          isLoading={loading}
          saveButtonLabel={t('Make Live')}
        >
          <NoteSection>
            <Trans i18nKey="newAndFeaturedMakeLiveNote" />
          </NoteSection>
          <GuideSection>
            <ItemFormField label={`${t('* Guides')} (${values.featuredGuides.length}/3)`}>
              <GuideListWithPicker
                name="featuredGuides"
                maxValues={MAX_GUIDES}
                isDragDisabled={true}
              />
            </ItemFormField>
          </GuideSection>
        </StandardForm>
      )}
    </Formik>
  )
}

export default memo(FeaturedGuidesForm)
