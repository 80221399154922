import { useMemo } from 'react'
import _ from 'lodash'
import { FormikProps } from 'formik'
import { IContent } from 'client/types'
import ReorderableList from 'client/components/ReorderableList/ReorderableList'
import { isItemContent } from 'shared/util/items'
import { GQLExhibit, GQLItem, GQLFeature } from 'shared/graphql/types/graphql'
import GlobeIconTooltip from 'client/components/Languages/GlobeIconTooltip'
import FeaturesMiniPicker from 'client/screens/AppEditor/Home/FeaturesMiniPicker'
import FeaturedItemRow from 'client/screens/AppEditor/Home/FeaturedItemRow'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import styled from 'styled-components'
import { t } from 'client/i18n'
import { Header2CSS } from 'client/components/TextStyles'

const Feature = styled.div`
  padding-bottom: var(--spacing-xlarge);
`

const TitleSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: var(--spacing-small);
`

const Title = styled.div`
  ${Header2CSS};
  flex-grow: 0;
  display: flex;
  > * {
    margin-left: 10px;
  }
  > :first-child {
    margin-left: 0px;
  }
  > :last-child {
    font-weight: var(--font-weight-regular);
  }
`

type IFeaturedItem = {
  exhibit?: Partial<GQLExhibit> | null
  item?: Partial<GQLItem> | null
}

interface IFormViewProps extends FormikProps<GQLFeature> {
  handleDelete: () => void
  handleEdit: () => void
  handleEditFeaturedItems: (featuredItems: IFeaturedItem[]) => void
}

const FormView = (props: IFormViewProps) => {
  const { values, handleChange, handleSubmit, handleDelete, handleEditFeaturedItems, handleEdit } =
    props

  const { featuredItems, title, locales } = values

  const selectedUuids = useMemo(
    () => _.map(featuredItems, (value) => value.item?.uuid || value.exhibit!.uuid),
    [featuredItems]
  )

  const handleEditCurrentFeaturedItems = (newFeaturedItems: IFeaturedItem[]) => {
    handleChange({ target: { name: 'featuredItems', value: newFeaturedItems } })
    // since React 18, we have to delay the handleSubmit call or else it won't reflect the values
    // updated by the handleChange call above. See https://github.com/jaredpalmer/formik/issues/529
    setImmediate(handleSubmit)
  }

  const handleAddFeaturedItems = (ids: string[], pickerData: IContent[]) => {
    const contents: IContent[] = _.map(ids, (id) => _.find(pickerData, { uuid: id })!)
    const items = contents
      .filter((content) => isItemContent(content))
      .map((item: GQLItem) => ({ item: { id: item.id } }))
    const exhibits = contents
      .filter((content) => !isItemContent(content))
      .map((exhibit: GQLExhibit) => ({ exhibit: { id: exhibit.id } }))

    const currentFeaturedItems = _.map(featuredItems, (featuredItem) =>
      _.pick(featuredItem, ['item.id', 'exhibit.id'])
    )

    const updatedFeatureList = [...currentFeaturedItems, ...items, ...exhibits]
    handleEditFeaturedItems(updatedFeatureList)
  }

  const menuOptions: IMenuOption[] = [
    {
      label: t('Edit Section Title'),
      onClick: handleEdit
    },
    {
      label: t('Delete Section'),
      onClick: handleDelete
    }
  ]
  return (
    <Feature>
      <TitleSection>
        <Title>
          {!_.isEmpty(title) && <span>{title}</span>}
          <GlobeIconTooltip locales={locales} />
          <MenuPopout options={menuOptions} />
        </Title>
      </TitleSection>
      <ReorderableList
        values={featuredItems}
        name="feature"
        rowComponent={FeaturedItemRow}
        onUpdate={handleEditCurrentFeaturedItems}
      />
      <FeaturesMiniPicker selectedUuids={selectedUuids} onSubmit={handleAddFeaturedItems} />
    </Feature>
  )
}

export default FormView
