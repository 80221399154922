import { useEffect, useRef, useCallback } from 'react'
import PencilEditSVG from 'assets/svg/icon/edit_20.svg'
import {
  CanvasImage,
  PreviewImageContainer,
  PreviewHoverLabel
} from 'client/screens/Catalog/forms/ImageForm/styledComponents'
import { ICropInformation } from 'shared/CropTypes'

interface IDrawImageArgs {
  sx: number
  sy: number
  sWidth: number
  sHeight: number
  dx: number
  dy: number
  dWidth: number
  dHeight: number
}

const getDrawInformation = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  cropInformation: ICropInformation,
  sourceWidth: number
): IDrawImageArgs => {
  // ratio is used to translate full image to original image dimensions when rendering a preview with cropData (full images are capped by mega-pixel leading to different sizes than the original, which is what is used in the cropper)
  // Only relevant when rendering a preview with cropData, because the cropData is in relation to the original source dimensions (which the fullUrl may not match).
  // Ideally we could just use naturalWidth off the cropState if it exists (image.naturalWidth / cropInformation?.UI?.canvasData?.naturalWidth || 1), but can't due to legacy rows in DB with missing cropState.
  const ratio = image.naturalWidth / sourceWidth || 1
  const imageWidth = image.naturalWidth
  const imageHeight = image.naturalHeight

  const cropData = cropInformation?.cropData
  const croppedOrImageWidth = cropData ? cropData.width * ratio : imageWidth
  const croppedOrImageHeight = cropData ? cropData.height * ratio : imageHeight

  // center and maintain aspect ratio
  const scale = Math.min(canvas.width / croppedOrImageWidth, canvas.height / croppedOrImageHeight)
  const dWidth = croppedOrImageWidth * scale
  const dHeight = croppedOrImageHeight * scale
  const dx = (canvas.width - dWidth) / 2
  const dy = (canvas.height - dHeight) / 2

  return {
    sx: cropData ? cropData.left * ratio : 0,
    sy: cropData ? cropData.top * ratio : 0,
    sWidth: croppedOrImageWidth,
    sHeight: croppedOrImageHeight,
    dx,
    dy,
    dWidth,
    dHeight
  }
}

// For consistency, all previews are rendered the same way; no matter if it is a new image, a pre-existing image, or an image with/without cropState.
const renderImageToCanvas = (
  image: HTMLImageElement,
  canvas: HTMLCanvasElement,
  cropStateByType: ICropInformation,
  sourceWidth: number
) => {
  const context = canvas.getContext('2d')!
  const drawingData = getDrawInformation(image, canvas, cropStateByType, sourceWidth)

  context.clearRect(0, 0, canvas.width, canvas.height)

  context.drawImage(
    image,
    drawingData.sx,
    drawingData.sy,
    drawingData.sWidth,
    drawingData.sHeight,
    drawingData.dx,
    drawingData.dy,
    drawingData.dWidth,
    drawingData.dHeight
  )
}

interface IImagePreviewProps {
  cropInfo: ICropInformation
  sourceWidth: number
  url: string
  dimensions: {
    width: number
    height: number
  }
  label: string
  onClick: () => void
}

const ImagePreview = (props: IImagePreviewProps) => {
  // <img> tags are only used to load the image source & handle event listener cleanup, but remain hidden in the UI.
  // For consistency, the <canvas> is always used to render the image, regardless of the scenario (new, pre-existing, or rendering crop data)
  const canvas = useRef<HTMLCanvasElement>(null)
  const image = useRef<HTMLImageElement>(null)

  const { cropInfo, sourceWidth, url, dimensions, label, onClick } = props
  const { width, height } = dimensions

  const renderImage = useCallback(() => {
    renderImageToCanvas(image.current!, canvas.current!, cropInfo, sourceWidth)
  }, [canvas, cropInfo, sourceWidth])

  useEffect(() => {
    if (cropInfo) {
      renderImage()
    }
  }, [cropInfo, renderImage])

  return (
    <PreviewImageContainer onClick={onClick}>
      <PreviewHoverLabel>
        {label} <PencilEditSVG />
      </PreviewHoverLabel>
      {/*
          <canvas> requires inline width and height as per first paragraph of documentation.
          https://developer.mozilla.org/en-US/docs/Web/API/Canvas_API/Tutorial/Basic_usage#the_canvas_element
        */}
      <CanvasImage ref={canvas} width={width} height={height} />
      <img src={url} ref={image} onLoad={renderImage} hidden={true} />
    </PreviewImageContainer>
  )
}

export default ImagePreview
