import styled from 'styled-components'
import { IMuseumLocationDay } from 'shared/json/IMuseumLocationDay'
import DayHoursRow from './DayHoursRow'

const WeekdayContainer = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-large);
`

interface IOrderedLocationDay extends IMuseumLocationDay {
  position: number
}

interface IProps {
  days: IOrderedLocationDay[]
  onUpdateDay: (day: IOrderedLocationDay) => void
  onDeleteDay?: (day: IOrderedLocationDay) => void
}

export default (props: IProps) => {
  const { days, onUpdateDay } = props

  const handleUpdateDay = (index: number) => (updatedDay: IOrderedLocationDay) => {
    const locationDay = days[index]
    onUpdateDay({ ...locationDay, ...updatedDay })
  }

  return (
    <WeekdayContainer>
      {days.map((day, index) => (
        <div key={`MuseumLocationDay#${day.id || day.position}`}>
          <DayHoursRow
            onUpdateDay={handleUpdateDay(index)}
            onDeleteDay={props.onDeleteDay}
            {...day}
          />
        </div>
      ))}
    </WeekdayContainer>
  )
}
