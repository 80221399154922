import { getExteriorMapCreateConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import { useDispatch } from 'react-redux'
import { createExteriorMap } from 'client/redux/actions/maps'
import { IStepProps } from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowManager'
import { ExteriorMapUpload } from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowComponents/ExteriorMapUpload'

const ExteriorMapStep = (props: IStepProps) => {
  const { onNext, onCancel } = props
  const dispatch = useDispatch()

  const handleImageChange = (file: File) => {
    dispatch(createExteriorMap(file))
    onNext!('uploadingExteriorMap')
  }

  const { message, title } = getExteriorMapCreateConfirmationMessage()

  return (
    <ExteriorMapUpload
      onFileChange={handleImageChange}
      onCancel={onCancel!}
      message={message}
      title={title}
    />
  )
}

export default ExteriorMapStep
