export enum ExhibitItemsHeadingTypes {
  ITEMS_IN_THIS_EXHIBITION = 'ITEMS_IN_THIS_EXHIBITION',
  WORKS_ON_VIEW = 'WORKS_ON_VIEW',
  AUDIO = 'AUDIO',
  EXPLORE = 'EXPLORE',
  HIGHLIGHTS = 'HIGHLIGHTS',
  STOPS = 'STOPS'
}

export const ExhibitItemsHeadings = {
  [ExhibitItemsHeadingTypes.HIGHLIGHTS]: 'HIGHLIGHTS' as const,
  [ExhibitItemsHeadingTypes.ITEMS_IN_THIS_EXHIBITION]: 'ITEMS IN THIS EXHIBITION' as const,
  [ExhibitItemsHeadingTypes.WORKS_ON_VIEW]: 'WORKS ON VIEW' as const,
  [ExhibitItemsHeadingTypes.AUDIO]: 'AUDIO' as const,
  [ExhibitItemsHeadingTypes.EXPLORE]: 'EXPLORE' as const,
  [ExhibitItemsHeadingTypes.STOPS]: 'STOPS' as const
}

export enum DisplayPeriodType {
  NONE = 'none',
  ON_GOING = 'onGoing',
  DATE_RANGE = 'dateRange'
}

export enum ExhibitionType {
  EXHIBITION = 'exhibition',
  TOUR = 'tour',
  EVENT = 'event'
}
