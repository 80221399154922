import { format, formatDistanceToNow } from 'date-fns'
import { UNIVERSAL_DATE_FORMAT } from 'shared/constants/dates'

export const getHowLongAgoText = (dateAdded: string | undefined): string | undefined => {
  if (dateAdded) {
    // TODO this is rendered text, but is INTERNAL. Do we want to translate this eventually?
    return `Added ${formatDistanceToNow(dateAdded, {
      addSuffix: true,
      includeSeconds: true
    })} on ${format(dateAdded, UNIVERSAL_DATE_FORMAT)}`
  }
}
