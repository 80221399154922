import _ from 'lodash'
import { IBuildingById, IReduxState, IMapsState } from 'client/types'
import { MapsActionTypes } from 'client/redux/actions/types'
import { IMapLocationJson } from 'shared/json/IMapLocationJson'
import { IMapJson } from 'shared/json/IMapJson'
import { IMapLocationContentJson } from 'shared/json/IMapLocationContentJson'
import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'

const getMaps = (state: IReduxState): IMapsState => state.maps

export const getBuildings = (state: IReduxState): IBuildingById => getMaps(state)?.buildings

export const getExterior = (state: IReduxState): IMapJson | undefined => getMaps(state).exterior

export const isExteriorMap = (state, mapId: number): boolean => getExterior(state)?.id === mapId

const getHTTPStatus = (state: IReduxState) => getMaps(state).httpStatus

export const isLoading = (state: IReduxState): boolean => getHTTPStatus(state).loading

export const getSelectedFloorPinId = (state: IReduxState): number | null =>
  getMaps(state).selected?.pinId

export const didAttemptToLoad = (state: IReduxState): boolean => {
  const { loading, type } = getHTTPStatus(state)
  return !loading && !_.isEmpty(type)
}

export const createGetMapLocationByIdSelector =
  ({ buildingId, floorId, locationId }) =>
  (state: IReduxState): IMapLocationJson | undefined => {
    const maps = getMaps(state)
    const building = maps.buildings[buildingId]
    const floor = _.find(building?.floors, { id: floorId })
    const floorLocation = _.find(floor?.mapLocations, { id: locationId })
    const exteriorLocation = _.find(maps.exterior?.mapLocations, { id: locationId })
    return floorLocation || exteriorLocation
  }

// Helper until state is normalized by id
interface IIDFields {
  buildingId?: any
  floorId?: any
}

export const getMapById = (state, { buildingId, floorId }: IIDFields): IMapJson => {
  const maps = getMaps(state)

  if (maps.exterior?.id === floorId) {
    return maps.exterior
  }

  if (buildingId) {
    return _.find(getBuildings(state)[buildingId]?.floors, { id: floorId })!
  }

  const buildings = _.values(getBuildings(state))
  const allMaps = _.reduce(buildings, (acc, building) => [...acc, ...building.floors], [])

  return _.find(allMaps, { id: floorId })!
}

export const didPositionUpdateFail = (state: IReduxState): boolean => {
  const { type, loading } = getHTTPStatus(state)
  return type === MapsActionTypes.MAPS_BUILDING_FLOORS_ORDER_UPDATE_ERROR && !loading
}

export const didExteriorMapUploadFail = (state: IReduxState): boolean => {
  const { type, loading } = getHTTPStatus(state)
  return type === MapsActionTypes.MAPS_EXTERIOR_CREATE_ERROR && !loading
}

export const didCreateBuildingFail = (state: IReduxState): boolean => {
  const { type, loading } = getHTTPStatus(state)
  return type === MapsActionTypes.MAPS_BUILDING_CREATE_ERROR && !loading
}

interface IContentWithUpdateExhibits extends IMapLocationContentJson {
  updateAllMapLocations?: boolean
}

type WithContent = {
  content: IContentWithUpdateExhibits
}

type ContentItem = Omit<GQLItem, '__typename' | 'translations'> & WithContent

type ContentExhibition = Omit<GQLExhibit, '__typename' | 'translations'> & WithContent

export type IGetMapLocationContent = ContentItem | ContentExhibition
