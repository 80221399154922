import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAuthData, getAuthError } from 'client/redux/selectors/auth'
import StringInput from 'client/components/Form/StringInput/StringInput'
import Button from 'client/components/Button/Button'
import { mfaVerificationStart } from 'client/redux/actions/auth'
import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import styled from 'styled-components'
import SupportFooter from 'client/components/SupportFooter'
import { StyledBanner, StyledBannerItem } from 'client/screens/styledComponents'
import { t } from 'client/i18n'
import { usePost } from 'client/hooks/api'
import GoogleAuthLogoSVG from 'assets/svg/google_auth_logo.svg'
import EmailOutlineSVG from 'assets/svg/email_outline.svg'
import ArrowSVG from 'assets/svg/arrow.svg'
import { Body1CSS, Header3CSS, Label2CSS } from 'client/components/TextStyles'
import { IAuthState } from 'client/types'

const VERIFICATION_CODE_LENGTH = 8

const StyledInput = styled(StringInput)`
  width: 274px;
  margin-top: 0;
`

const StyledButton = styled(Button)`
  margin-top: var(--spacing-xlarge);
  width: 100%;
`

const HeaderContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding-bottom: var(--spacing-small);
  ${Header3CSS}
`

const StepNText = styled.div`
  ${Label2CSS}
  color: var(--color-grey-07);
  margin-bottom: var(--spacing-xxsmall);
`

const SendNewCodeFooterContainer = styled.div`
  margin-top: var(--spacing-xsmall);
`

const LinkButton = styled(Button).attrs((props) => ({
  ...props,
  type: 'tertiary'
}))`
  display: inline-block;
  font-weight: normal;
  text-decoration: underline;
`

const GoogleAuthToEmailLogoContainer = styled.div`
  display: flex;
  width: 100%;
  column-gap: var(--spacing-xxsmall);
  align-items: center;
`

const Arrow = styled(ArrowSVG)`
  width: 80px;
  height: 16px;
`

const GoogleAuthLogo = styled(GoogleAuthLogoSVG)`
  height: 44px;
  width: 42px;
`
const EmailOutline = styled(EmailOutlineSVG)`
  height: 31px;
  width: 40px;
`
const MigratingFromGoogleAuthContainer = styled.div`
  margin-top: var(--spacing-small);
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-xsmall);
`

const MigratingText = styled.span`
  ${Body1CSS}
`

const getMFAHeader = (authData: IAuthState) => {
  const {
    isUserLoggingInForFirstTime,
    mfaEnrolled: isMigratingFromGoogleAuth,
    isPasswordExpired
  } = authData

  // Description for users first time migrating from totp to email auth
  const migratingDescription = isMigratingFromGoogleAuth ? (
    <MigratingFromGoogleAuthContainer>
      <GoogleAuthToEmailLogoContainer>
        <GoogleAuthLogo />
        <Arrow />
        <EmailOutline />
      </GoogleAuthToEmailLogoContainer>
      <MigratingText>
        {t('You no longer need a code from Google Authenticator to log in.')}
      </MigratingText>
    </MigratingFromGoogleAuthContainer>
  ) : null

  if (isUserLoggingInForFirstTime || isPasswordExpired) {
    // Take isUserLoggingInForFirstTime as priority if both are true
    const stepText = isUserLoggingInForFirstTime ? t('Step 2 of 3') : t('Step 1 of 2')
    return (
      <HeaderContainer>
        <StepNText>{stepText}</StepNText>
        <strong>{t('Verify your identity with two-factor authentication')}</strong>
        {migratingDescription}
      </HeaderContainer>
    )
  }

  return (
    <HeaderContainer>
      <strong>{t('Two-Factor Authentication')}</strong>
      {migratingDescription}
    </HeaderContainer>
  )
}

const EmailMFA = () => {
  const [verificationCode, setVerificationCode] = useState('')
  const [showSendEmailInfo, setShowSendEmailInfo] = useState(false)

  const dispatch = useDispatch()
  const authError = useSelector(getAuthError)
  const authData = useSelector(getAuthData)

  const [sendEmailMFA] = usePost('/auth/send-email-mfa')

  const { email, isPasswordExpired, privacyPolicyAcceptedAt } = authData

  const handleSubmit = () => {
    if (verificationCode) {
      dispatch(mfaVerificationStart(verificationCode))
    }
  }

  const handleSendNewCode = () => {
    setShowSendEmailInfo(true)
    sendEmailMFA()
  }

  const handleEnterPress = () => {
    if (verificationCode) {
      handleSubmit()
    }
  }

  if (showSendEmailInfo) {
    return (
      <ContainerWithConnectsLogo>
        <p>
          {t(
            'Check your email for a new 8-digit code. The code can only be used once and is valid for 10 minutes.'
          )}
        </p>
        <StyledButton
          type="primary"
          onClick={() => setShowSendEmailInfo(false)}
          label={t('Back to Authentication')}
        />
        <SupportFooter />
      </ContainerWithConnectsLogo>
    )
  }

  return (
    <ContainerWithConnectsLogo onEnterPress={handleEnterPress}>
      {authError && (
        <StyledBanner type="error" headingText={t('Invalid two-factor authentication code')}>
          <StyledBannerItem>
            {t(
              'Try re-entering the code. If that doesn’t work, send a new code to your email, then try again.'
            )}
          </StyledBannerItem>
        </StyledBanner>
      )}
      <>
        {getMFAHeader(authData)}
        <p>
          {t(
            'We sent an 8-digit code to __email__. The code is valid for 10 minutes. Enter the code to log in.',
            { email }
          )}
        </p>
        <StyledInput
          name="verificationCode"
          value={verificationCode}
          onChange={(e) => {
            setVerificationCode(e.target.value)
          }}
          disableAutocomplete={true}
          autoFocus={true}
          maxLength={VERIFICATION_CODE_LENGTH}
        />
        <SendNewCodeFooterContainer>
          {t('Having trouble?')}{' '}
          <LinkButton label={t('Send a new code')} onClick={handleSendNewCode} />
        </SendNewCodeFooterContainer>
        <StyledButton
          type="primary"
          disabled={verificationCode?.length !== VERIFICATION_CODE_LENGTH}
          onClick={handleSubmit}
          // If a user's password has expired or if the privacy notice hasn't been accepted, they will see the `PasswordReset` and/or the `PrivacyNotice` screens next.
          // If not, they'll see the exhibitions' grid.
          label={isPasswordExpired || !privacyPolicyAcceptedAt ? t('Next') : t('Complete Login')}
        />
      </>
      <SupportFooter />
    </ContainerWithConnectsLogo>
  )
}

export default EmailMFA
