import { KeyboardEvent, useMemo } from 'react'
import styled from 'styled-components'
import { Formik, FormikProps } from 'formik'
import TextInputField from 'client/components/Formik/TextInput/TextInput'
import { StyledFormSection } from 'client/components/Form/FormField/FormField'
import Button from 'client/components/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import { IPasswordResetValues, resetPassword } from 'client/redux/actions/auth'
import { getAuthData } from 'client/redux/selectors/auth'
import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import SupportFooter from 'client/components/SupportFooter'
import AuthenticationErrorType from 'shared/AuthenticationErrorType'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import { AuthWorkflowHeader } from 'client/screens/styledComponents'
import _ from 'lodash'
import { t } from 'client/i18n'
import { IAuthState } from 'client/types'

const StyledBanner = styled(Banner)`
  margin-left: -48px;
  margin-right: -48px;
  margin-bottom: var(--spacing-large);
`

const StyledBannerItem = styled(BannerItem)`
  max-width: 496px;
`

const StyledTextInputField = styled(TextInputField)`
  margin: var(--spacing-small) 0;
  ${StyledFormSection} {
    margin-bottom: var(--spacing-small);
  }
`
const StyledList = styled.ul`
  padding-left: var(--spacing-xsmall);
  list-style-position: inside;
  margin: 0;

  li:before {
    content: '';
    margin-left: -6px;
  }
`

const Requirements = styled.div`
  display: flex;
  flex-direction: column;
`

const SubmitButton = styled(Button)`
  width: 100%;
  margin-top: var(--spacing-xlarge);
`

const RequirementsHeader = styled.div`
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--spacing-xsmall);
`

const ERROR_MESSAGE_MAP = {
  [AuthenticationErrorType.PASSWORDS_DO_NOT_MATCH]: {
    message: t('Passwords do not match')
  },
  [AuthenticationErrorType.CANNOT_MATCH_CURRENT_PASSWORD]: {
    message: t('New password cannot match current password')
  },
  [AuthenticationErrorType.PASSWORD_DOES_NOT_MEET_REQUIREMENTS]: {
    message: t('Password must meet all listed requirements')
  }
}

const INSTRUCTION_FOR_ERROR = t('Try entering your new password again.')

type InstructionTemplateReturnType = {
  stepText?: string
  instruction: string
  passwordInputLabel: string
  passwordConfirmationInputLabel: string
  buttonLabel: string
}
const getInstructionTemplate = (authData: IAuthState): InstructionTemplateReturnType => {
  const { isUserLoggingInForFirstTime, isPasswordExpired } = authData

  if (isUserLoggingInForFirstTime) {
    return {
      stepText: t('Step 1 of 3'),
      instruction: t('Choose a password'),
      passwordInputLabel: t('Enter Password'),
      passwordConfirmationInputLabel: t('Confirm Password'),
      buttonLabel: t('Next')
    }
  }

  if (isPasswordExpired) {
    return {
      stepText: t('Step 2 of 2'),
      instruction: t('Choose a password'),
      passwordInputLabel: t('Enter Password'),
      passwordConfirmationInputLabel: t('Confirm Password'),
      buttonLabel: t('Complete Login')
    }
  }

  return {
    instruction: t('For security purposes, choose a new password'),
    passwordInputLabel: t('Enter New Password'),
    passwordConfirmationInputLabel: t('Confirm New Password'),
    buttonLabel: t('Reset Password and Log In')
  }
}

const FormView = (props: FormikProps<IPasswordResetValues>) => {
  const { values, handleSubmit } = props
  const authData = useSelector(getAuthData)
  const { error: authError, isLoading, isUserLoggingInForFirstTime } = authData
  const authErrorMessage = authError?.message
  const isSubmitDisabled = !values.password || !values.passwordConfirmation

  const { stepText, instruction, passwordInputLabel, passwordConfirmationInputLabel, buttonLabel } =
    getInstructionTemplate(authData)

  const onKeyPress = (event: KeyboardEvent) => {
    if (!isSubmitDisabled && event.key === 'Enter') {
      handleSubmit()
    }
  }

  const error = useMemo(
    () =>
      _.includes(
        [
          AuthenticationErrorType.PASSWORDS_DO_NOT_MATCH,
          AuthenticationErrorType.CANNOT_MATCH_CURRENT_PASSWORD,
          AuthenticationErrorType.PASSWORD_DOES_NOT_MEET_REQUIREMENTS
        ],
        authErrorMessage
      )
        ? authErrorMessage
        : null,
    [authErrorMessage]
  )

  return (
    <ContainerWithConnectsLogo onKeyPress={onKeyPress} isLoading={isLoading}>
      {error && (
        <StyledBanner type="error" headingText={ERROR_MESSAGE_MAP[error].message}>
          <StyledBannerItem>{INSTRUCTION_FOR_ERROR}</StyledBannerItem>
        </StyledBanner>
      )}
      <AuthWorkflowHeader>
        {stepText && <span>{stepText}</span>}
        <h2>{instruction}</h2>
      </AuthWorkflowHeader>
      <StyledTextInputField name="password" label={passwordInputLabel} type="password" />
      <StyledTextInputField
        name="passwordConfirmation"
        label={passwordConfirmationInputLabel}
        type="password"
      />
      <Requirements>
        <RequirementsHeader>{t('Password Requirements')}</RequirementsHeader>
        {!isUserLoggingInForFirstTime && (
          <div>{t('Must be different from your current password.')}</div>
        )}
        <div>{t('Must be 8 to 48 characters in length.')}</div>
        <div>{t('Must contain at least 3 of the following:')}</div>
        <StyledList>
          <li>{t('Upper case letters (A-Z)')}</li>
          <li>{t('Lower case letters (a-z)')}</li>
          <li>{t('Numbers (0-9)')}</li>
          <li>{t('Special characters (i.e., !@#$%^&*)')}</li>
        </StyledList>
      </Requirements>
      <SubmitButton
        type="primary"
        disabled={isSubmitDisabled}
        onClick={handleSubmit}
        label={buttonLabel}
      />
      <SupportFooter />
    </ContainerWithConnectsLogo>
  )
}

const PasswordResetForm = () => {
  const dispatch = useDispatch()
  const onSubmit = (values: IPasswordResetValues) => {
    dispatch(resetPassword(values))
  }

  return (
    <Formik initialValues={{ password: null, passwordConfirmation: null }} onSubmit={onSubmit}>
      {(formikProps: FormikProps<IPasswordResetValues>) => <FormView {...formikProps} />}
    </Formik>
  )
}

export default PasswordResetForm
