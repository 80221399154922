import { useContext, useEffect } from 'react'
import { TranslationFormContext } from 'client/components/TranslationForm/TranslationForm'
import {
  useLazyQuery,
  OperationVariables,
  DocumentNode,
  TypedDocumentNode,
  QueryResult,
  QueryHookOptions
} from '@apollo/client'

function useTranslationContextAwareQuery<
  TData = any,
  TVariables extends OperationVariables = OperationVariables
>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const translationContext = useContext(TranslationFormContext)
  const selectedLocaleCode = translationContext?.selectedLocale?.code

  // Note fetchQuery updates queryResult
  const [fetchQuery, queryResult] = useLazyQuery(query)

  useEffect(() => {
    fetchQuery({
      ...options,
      // @ts-ignore: TODO need help understanding how to fix this error
      variables: { ...options?.variables, selectedLocaleCode }
    })
  }, [query, options, selectedLocaleCode, fetchQuery])

  return queryResult
}

export default useTranslationContextAwareQuery
