import Table, { ITableCellProps } from 'client/dsm/Table/Table'
import styled from 'styled-components'
import _ from 'lodash'
import { LanguageType, ITrackAndLanguageInfo, IAudioLanguageInfo } from 'shared/constants/video'
import CheckmarkSVG from 'assets/svg/icon/check_20_filled.svg'
import WarningSVG from 'assets/svg/icon/status_warning_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { t } from 'client/i18n'
import { Label2CSS, Body2CSS, Label1CSS } from 'client/components/TextStyles'
import MatrixWarningMessages from './MatrixWarningMessages'

const HEADER_HEIGHT = 40
const ROW_HEIGHT = 58

const MatrixTitle = styled.div`
  ${Label1CSS};
  margin-top: 16px;
`

const TableWrapper = styled.div<{ rowCount: number }>`
  position: relative;
  height: ${({ rowCount }) => HEADER_HEIGHT + ROW_HEIGHT * rowCount}px;
  width: 100%;
  overflow: auto;
  margin-top: 12px;

  .ReactVirtualized__Table__rowColumn,
  .ReactVirtualized__Table__headerColumn {
    &:first-of-type {
      padding-left: 8px;
    }
    &:last-of-type {
      padding-right: 8px;
    }

    &:not(:first-of-type) {
      justify-content: center;
    }
  }
`

const CheckmarkIcon = styled.span`
  svg {
    color: var(--color-green-07);
  }
`

const TooltipContentWrapper = styled.div`
  ${Label1CSS};
  width: 293px;
`

const TooltipHeader = styled.div`
  ${Label2CSS};
  margin: 0;
`
const TooltipBody = styled.div`
  ${Body2CSS};
`

const NoAudioTrackDetectedHeader = styled.div`
  ${Label2CSS};
  color: var(--color-grey-07);
  margin: 10px 0px 5px;
`

const NoAudioTrackDetectedBody = styled.div`
  ${Body2CSS};
  color: var(--color-grey-07);
  padding-bottom: 16px;
  border-bottom: 1px solid var(--color-grey-02);
`
const CheckmarkTooltip = () => {
  return (
    <Tooltip content={<span>{t('Track detected')}</span>}>
      <CheckmarkIcon>
        <CheckmarkSVG />
      </CheckmarkIcon>
    </Tooltip>
  )
}

const audioTooltipContent = (
  <TooltipContentWrapper>
    <TooltipHeader>{t('Audio track not detected')}</TooltipHeader>
    <TooltipBody>
      {t('An audio track for this language was not detected in this video file.')}
    </TooltipBody>
  </TooltipContentWrapper>
)

const audioDescriptionTooltipContent = (
  <TooltipContentWrapper>
    <TooltipHeader>{t('Audio description track not detected')}</TooltipHeader>
    <TooltipBody>
      {t(
        'An audio description track was not detected for this language. Audio descriptions describe visual content essential to understanding the video. Please check accessibility guidelines for compliance. If your video requires audio descriptions, add them as [AD] tracks to your video file, and re-upload.'
      )}
    </TooltipBody>
  </TooltipContentWrapper>
)

interface IWarningTooltipProps {
  isAudioDescription: boolean
}
const WarningTooltip = ({ isAudioDescription }: IWarningTooltipProps) => {
  return (
    <Tooltip content={isAudioDescription ? audioDescriptionTooltipContent : audioTooltipContent}>
      <span>
        <WarningSVG />
      </span>
    </Tooltip>
  )
}

const languageValue = ({
  rowData: trackAndLanguageInfo
}: ITableCellProps<ITrackAndLanguageInfo>) => {
  return trackAndLanguageInfo.name
}
const audioValue = ({ rowData: trackAndLanguageInfo }: ITableCellProps<ITrackAndLanguageInfo>) => {
  return trackAndLanguageInfo.hasAudio ? (
    <CheckmarkTooltip />
  ) : (
    <WarningTooltip isAudioDescription={false} />
  )
}
const audioDescriptionValue = ({
  rowData: trackAndLanguageInfo
}: ITableCellProps<ITrackAndLanguageInfo>) => {
  return trackAndLanguageInfo.hasAudioDescription ? (
    <CheckmarkTooltip />
  ) : (
    <WarningTooltip isAudioDescription={true} />
  )
}

interface IMatrixTableProps {
  supportedLanguages: ITrackAndLanguageInfo[]
}
const MatrixTable = ({ supportedLanguages }: IMatrixTableProps) => {
  if (_.isEmpty(supportedLanguages)) {
    return null
  }

  return (
    <TableWrapper rowCount={supportedLanguages.length}>
      <Table
        data={supportedLanguages}
        headerHeight={HEADER_HEIGHT}
        rowHeight={ROW_HEIGHT}
        columns={[
          { dataKey: 'name', label: t('Language'), cellRenderer: languageValue, flexGrow: 2 },
          { dataKey: 'hasAudio', label: t('Audio'), cellRenderer: audioValue, flexGrow: 2 },
          {
            dataKey: 'hasAudioDescription',
            label: t('Audio Description'),
            cellRenderer: audioDescriptionValue,
            flexGrow: 2
          }
        ]}
      />
    </TableWrapper>
  )
}

interface IAudioTracksMatrixProps {
  audioLanguageInfo: IAudioLanguageInfo
}
export default function AudioTracksMatrix({ audioLanguageInfo }: IAudioTracksMatrixProps) {
  if (!audioLanguageInfo) {
    return null
  }

  const { trackAndLanguageInfoList, numUntaggedTracks } = audioLanguageInfo

  if (_.isEmpty(trackAndLanguageInfoList) && !numUntaggedTracks) {
    return (
      <>
        <MatrixTitle>{t('Video File Information')}</MatrixTitle>
        <NoAudioTrackDetectedHeader>{t('No audio track detected')}</NoAudioTrackDetectedHeader>
        <NoAudioTrackDetectedBody>
          {t(
            'This video file does not contain any audio tracks. Users of your guide will not hear audio when this video is played.'
          )}
        </NoAudioTrackDetectedBody>
      </>
    )
  }
  const audioTracksGroupedByLanguageType = _.groupBy(trackAndLanguageInfoList, 'languageType')
  const supportedLanguages: ITrackAndLanguageInfo[] =
    audioTracksGroupedByLanguageType[LanguageType.SUPPORTED]
  const unsupportedLanguages: ITrackAndLanguageInfo[] =
    audioTracksGroupedByLanguageType[LanguageType.UNSUPPORTED]
  const unrecognizedLanguages: ITrackAndLanguageInfo[] =
    audioTracksGroupedByLanguageType[LanguageType.UNRECOGNIZED]

  const hasSupportedLanguages = !_.isEmpty(supportedLanguages)

  return (
    <>
      <MatrixTitle>{t('Video File Information')}</MatrixTitle>
      <MatrixTable supportedLanguages={supportedLanguages} />
      <MatrixWarningMessages
        marginTop={hasSupportedLanguages ? 17 : 10}
        paddingBottom={hasSupportedLanguages ? 24 : 16}
        unsupportedLanguages={unsupportedLanguages}
        unrecognizedLanguages={unrecognizedLanguages}
        numUntaggedTracks={numUntaggedTracks}
      />
    </>
  )
}
