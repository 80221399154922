import * as yup from 'yup'
import _ from 'lodash'
import { BulkUploadJson } from 'server/types/bulkUpload'
import { LOOKUP_NUMBER_MAX, LOOKUP_NUMBER_MIN } from 'shared/validation/constants'
import { parse as parseHtml } from 'node-html-parser'
import { XMLValidator } from 'fast-xml-parser'
import { TFunction } from 'i18next'
import { TKey } from 'shared/i18n/types/translationResources'

interface ISchemaFunctionParams {
  data: BulkUploadJson
  validLocales: { isDefault: boolean; code: string }[]
  assignedLookupNumbers: number[]
}

export type SchemaFunction = (params: ISchemaFunctionParams) => yup.AnyObjectSchema

// TODO is this safe to TS assert non-null?
export const getFieldNameFromTestPath = (path: string) => _(path).split('.').last()!

const VALID_HTML_TAGS = ['p', 'a', 'strong', 'em', 'b', 'br']

function isValidATag(tagName: string, attributes) {
  if (tagName !== 'a') {
    return false
  }

  return attributes.href && _.isEmpty(_.omit(attributes, ['href', 'target']))
}

export const isValidHtml = (html: string) => {
  const parsed = parseHtml(html)
  const elements = parsed.querySelectorAll('*')

  const isValidTagsAndAttributes = _.every(elements, (element) => {
    const tagName = element.tagName.toLowerCase()
    const { attributes } = element
    const isValidTag = _.includes(VALID_HTML_TAGS, tagName)
    const hasValidAttributes = _.isEmpty(attributes) || isValidATag(tagName, attributes)

    return isValidTag && hasValidAttributes
  })

  const htmlLowercase = html.toLowerCase().trim()
  const isValidParagraph = htmlLowercase.startsWith('<p>') && htmlLowercase.endsWith('</p>')

  const isValidXml =
    XMLValidator.validate(`<root>${html}</root>`, {
      unpairedTags: ['br']
    }) === true

  return isValidTagsAndAttributes && isValidXml && isValidParagraph
}

export function validHtml(t: TFunction) {
  return {
    name: 'valid-html',
    test: (value: string, context) => {
      if (!value) {
        return true
      }
      return (
        isValidHtml(value) ||
        context.createError({
          // eslint-disable-next-line docent/require-translation-keys-to-be-literals
          message: `${t(getFieldNameFromTestPath(context.path) as TKey)}: ${t(
            'HTML text must be properly formatted, must be wrapped in a <p> tag, can only contain <em> <strong> <a> tags otherwise, and does not allow custom classes'
          )}`
        })
      )
    }
  }
}

export function validLookupNumber(t: TFunction) {
  return yup
    .number()
    .nullable()
    .min(
      LOOKUP_NUMBER_MIN,
      t(
        'The lookupNumber is out of range. Supported range is __LOOKUP_NUMBER_MIN__ - __LOOKUP_NUMBER_MAX__.',
        { LOOKUP_NUMBER_MIN, LOOKUP_NUMBER_MAX }
      )
    )
    .max(
      LOOKUP_NUMBER_MAX,
      t(
        'The lookupNumber is out of range. Supported range is __LOOKUP_NUMBER_MIN__ - __LOOKUP_NUMBER_MAX__.',
        { LOOKUP_NUMBER_MIN, LOOKUP_NUMBER_MAX }
      )
    )
}
