import FormField from 'client/components/Form/FormField/FormField'
import { Route, Routes, useNavigate } from 'react-router-dom'
import PencilIconEditButton from 'client/components/Button/PencilIconEditButton'
import SplashForm from 'client/screens/AppEditor/BrandAssets/Splash/SplashForm'
import { t } from 'client/i18n'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import SplashPreview from './SplashPreview'

const PreviewContainer = styled.div`
  padding-top: var(--spacing-small);
  width: 280px;
`

const SPLASH_ASSETS_QUERY = gql`
  query getMuseumImages($museumId: Int!) {
    museum(id: $museumId) {
      id
      displayName
      images {
        splashBackground {
          id
          sourceUrl
          url
          backgroundCropState
        }
        splashLogo {
          id
          url
        }
      }
    }
  }
`

export default function Splash() {
  const navigate = useNavigate()
  const { data, loading } = useQuery(SPLASH_ASSETS_QUERY)

  if (loading) {
    return <LoadingOverlay />
  }

  const displayName = data?.museum?.displayName
  const backgroundImageSourceUrl = data?.museum?.images?.splashBackground?.sourceUrl
  const backgroundImageUrl = data?.museum?.images?.splashBackground?.url
  const backgroundImageCropState = data?.museum?.images?.splashBackground?.backgroundCropState
  const logoUrl = data?.museum?.images?.splashLogo?.url

  return (
    <>
      <FormField
        label={t('Splash Screen')}
        description={t('The splash screen provides a visual transition into your guide.')}
      >
        <PencilIconEditButton onClick={() => navigate('splash')} />
        {backgroundImageUrl && (
          <PreviewContainer>
            <SplashPreview
              logoUrl={logoUrl}
              backgroundImageUrl={backgroundImageUrl}
              displayName={displayName}
            />
          </PreviewContainer>
        )}
      </FormField>
      <Routes>
        <Route
          path="splash/*"
          element={
            <SplashForm
              backgroundImageSourceUrl={backgroundImageSourceUrl}
              backgroundImageUrl={backgroundImageUrl}
              backgroundImageCropState={backgroundImageCropState}
              logoUrl={logoUrl}
              displayName={displayName}
            />
          }
        />
      </Routes>
    </>
  )
}
