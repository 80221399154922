import { useState, ChangeEventHandler, MouseEventHandler, useEffect } from 'react'
import * as React from 'react'
import _ from 'lodash'
import styled from 'styled-components'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import XSVG from 'assets/svg/icon/x_20.svg'
import CheckmarkSVG from 'assets/svg/icon/check_20.svg'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import PencilIconButtonTooltip from 'client/components/Tooltip/PencilIconButtonTooltip'
import { t } from 'client/i18n'

const Container = styled.div({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'row',
  height: '100%',
  cursor: 'pointer'
})

interface IValueContainerProps {
  isEditing?: boolean
}

const ValueContainer = styled.div<IValueContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  border-bottom: ${({ isEditing }) =>
    isEditing ? '1px solid var(--inline-form-selected)' : '1px dotted var(--accnet-02)'};
`

interface IValueInputProps {
  maxWidth?: number
}

const ValueInput = styled.input<IValueInputProps>`
  text-align: left;
  padding-left: 0;
  line-height: 18px;
  display: flex;
  font-size: 18px;
  font-family: var(--font-family);
  font-weight: var(--font-weight-bold);
  color: var(--color-black);
  width: ${({ maxWidth }) => (!_.isNil(maxWidth) ? `${maxWidth}px` : 'calc(100%)')};
  height: 100%;
  border: none;
  cursor: ${({ readOnly }) => (!readOnly ? 'text' : 'pointer')};
  background: ${({ readOnly }) => (!readOnly ? 'var(--color-grey-01)' : 'none')};
  &:focus {
    border: none;
  }
  &:hover {
    border: none;
  }
`

interface IControlsContainerProps {
  isEditing?: boolean
}

const ControlsContainer = styled.div<IControlsContainerProps>`
  align-self: center;
  margin-top: -2px;
  margin-left: 8px;
  display: ${({ isEditing }) => (isEditing ? 'flex' : 'none')};
  ${Container}:hover & {
    display: flex;
  }

  > :not(:first-child) {
    margin-left: var(--spacing-xsmall);
  }
`

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
  secure?: boolean
  maxWidth?: number
  editing?: boolean
  onSave?: (newValue: string | string[] | number) => void
  onCancel?: () => void
  onBeginEditing?: () => void
  onEndEditing?: () => void
}

const InlineEditField = (props: IProps) => {
  const [isEditing, setIsEditing] = useState<boolean>()
  const [updatedValue, setUpdatedValue] = useState(props.value)
  const {
    onSave = _.noop,
    onCancel = _.noop,
    onBeginEditing = _.noop,
    onEndEditing = _.noop,
    onChange = _.noop,
    editing,
    secure,
    type,
    minLength,
    maxLength,
    ...restProps
  } = props

  useEffect(() => {
    if (isEditing && !editing) {
      onBeginEditing()
    } else if (!isEditing && editing) {
      onEndEditing()
    }
    // TODO this seems to work as we have it, but the linter complains that we're missing the deps
    // commented out below. Simply adding those deps causes an infinite loop with this effect
    // whenever a second floor number within the same building is edited without confirming/rejecting
    // the first edit, so we'll leave this for now and come back to it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing /* editing, onBeginEditing, onEndEditing */])

  useEffect(() => {
    setIsEditing(editing)
  }, [editing])

  const handleEditPress = () => {
    setIsEditing(true)
  }

  const handleCancelPress = () => {
    setUpdatedValue(props.value)
    onCancel()
    setIsEditing(false)
  }

  const handleSavePress = () => {
    if (!minLength || `${updatedValue}`.length >= minLength) {
      onSave(updatedValue)
    } else {
      setUpdatedValue(props.value)
    }
    setIsEditing(false)
  }

  const handleInputClick: MouseEventHandler<HTMLInputElement> = (event) => {
    if (!isEditing) {
      event.preventDefault()
      setIsEditing(true)
    }
  }

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { value } = event.target
    if (!maxLength || value.length <= maxLength) {
      setUpdatedValue(event.target.value)
      onChange(event)
    }
  }

  return (
    <Container>
      <ValueContainer isEditing={isEditing}>
        <ValueInput
          {...restProps}
          value={updatedValue}
          onClick={handleInputClick}
          onChange={handleInputChange}
          readOnly={!isEditing}
          type={secure ? 'password' : type || 'text'}
        />
      </ValueContainer>
      <ControlsContainer isEditing={isEditing}>
        {!isEditing && <PencilIconButtonTooltip onClick={handleEditPress} />}
        {isEditing && (
          <Tooltip content={t('Save changes')}>
            <div>
              <IconOnlyButton type="solid" svgComponent={CheckmarkSVG} onClick={handleSavePress} />
            </div>
          </Tooltip>
        )}
        {isEditing && (
          <Tooltip content={t('Revert changes')}>
            <div>
              <IconOnlyButton type="lined" svgComponent={XSVG} onClick={handleCancelPress} />
            </div>
          </Tooltip>
        )}
      </ControlsContainer>
    </Container>
  )
}

export default InlineEditField
