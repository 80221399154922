import { useContext } from 'react'
import * as yup from 'yup'
import gql from 'graphql-tag'
import TranslationForm, {
  TranslationFormContext
} from 'client/components/TranslationForm/TranslationForm'
import RichTextEditorField from 'client/components/Formik/RichTextEditor/RichTextEditor'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import { createApiConfig } from 'client/components/TranslationForm/util'
import { getSelectedVersionInfo } from 'client/redux/selectors/version'
import { useSelector } from 'react-redux'
import MuseumInput from 'shared/graphql/mutations/MuseumInput'
import { t } from 'client/i18n'

const apiConfig = createApiConfig(
  'museum',
  gql`
    query getSingleMuseum($id: Int!) {
      museum(id: $id) {
        id
        translations {
          description

          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

const validationSchema = yup.object().shape({
  description: yup.string().nullable().required()
})

const DescriptionFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <RichTextEditorField
      name={getFieldName('description')}
      label={t('* Description')}
      fieldContainer={FormField}
    />
  )
}

const DescriptionForm = () => {
  const contentId = useSelector(getSelectedVersionInfo).id

  return (
    <TranslationForm
      contentName="Description"
      contentId={contentId}
      apiConfig={apiConfig}
      inputType={MuseumInput}
      allowDelete={false}
      validationSchema={validationSchema}
    >
      <DescriptionFormView />
    </TranslationForm>
  )
}
export default DescriptionForm
