import { useState } from 'react'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import { createBuilding } from 'client/redux/actions/maps'
import FormField from 'client/components/Form/FormField/FormField'
import StringInput from 'client/components/Form/StringInput/StringInput'
import Button from 'client/components/Button/Button'
import MapDialog from 'client/screens/AppEditor/MapEditor/MapDialog'
import { DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import { t } from 'client/i18n'
import { getExterior } from 'client/redux/selectors/maps'
import { LatitudeStringInputField, LongitudeStringInputField } from '../../GoogleMapFields'

interface IProps {
  onCancel: () => void
  onNext: (name: string) => void
}

const BuildingName = (props: IProps) => {
  const { onCancel, onNext } = props
  const [titleValue, setTitleValue] = useState('')
  const [latitude, setLatitude] = useState<string>()
  const [longitude, setLongitude] = useState<string>()
  const isGoogleMap = useSelector(getExterior)?.isGoogleMap ?? false

  const dispatch = useDispatch()
  const [isInputRequired, setInputRequired] = useState(false)

  const handleAddingBuilding = () => {
    const name = titleValue.trim()
    if (!_.isEmpty(name)) {
      dispatch(createBuilding({ name, latitude: latitude || null, longitude: longitude || null }))
      onNext('creatingBuilding')
    } else {
      setInputRequired(true)
    }
  }

  return (
    <MapDialog>
      <DialogHeader>{t('Add Building')}</DialogHeader>
      <DialogBody>
        <p>
          {isGoogleMap
            ? t('You can set the building’s location using latitude/longitude coordinates.')
            : t('You can set the building’s location by dragging the pin on the map.')}
        </p>
        <FormField label={t('* Name')}>
          <StringInput
            value={titleValue}
            onChange={(e) => setTitleValue(e.target.value)}
            // The reason we pass isInputRequired rather than true below is to prevent
            // the error message displayed right after a user opens up the Add Building form.
            isInputRequired={isInputRequired}
          />
        </FormField>
        {isGoogleMap && (
          <>
            <LatitudeStringInputField value={latitude} onChange={setLatitude} />
            <LongitudeStringInputField value={longitude} onChange={setLongitude} />
          </>
        )}
      </DialogBody>
      <DialogActions>
        <Button onClick={onCancel} label={t('Cancel')} />
        <Button onClick={() => handleAddingBuilding()} type="primary" label={t('Save')} />
      </DialogActions>
    </MapDialog>
  )
}

export default BuildingName
