import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'
import { createBuildingComplete } from 'client/redux/actions/maps'
import { getCreateSuccessMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/buildings'
import { ExteriorMapSuccess } from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowComponents/ExteriorMapUpload'
import { getExterior, isLoading } from 'client/redux/selectors/maps'
import Button from 'client/components/Button/Button'
import { DialogActions, DialogBody, DialogHeader } from 'client/dsm/Dialog/Dialog'
import MapDialog from 'client/screens/AppEditor/MapEditor/MapDialog'
import { t } from 'client/i18n'

interface IExteriorMapUploadedProps {
  onNext: (stepName: string) => void
}

export const ExteriorMapUploaded = (props: IExteriorMapUploadedProps) => {
  const exteriorMap = useSelector(getExterior)
  const isUploading = useSelector(isLoading)
  const shouldRender = Boolean(!isUploading && exteriorMap)

  if (!shouldRender) {
    return null
  }

  return <ExteriorMapSuccess onContinue={() => props.onNext('buildingName')} />
}

interface IBuildingCreatedProps {
  onCancel: () => void
}

export const BuildingCreated = (props: IBuildingCreatedProps) => {
  const dispatch = useDispatch()
  const exteriorMap = useSelector(getExterior)

  const { message, title } = getCreateSuccessMessage(!_.isEmpty(exteriorMap))

  return (
    <MapDialog>
      <DialogHeader>{title}</DialogHeader>
      <DialogBody>{message}</DialogBody>
      <DialogActions>
        <Button
          onClick={() => {
            // This terminates/completes the current workflow
            props.onCancel()
            dispatch(createBuildingComplete())
          }}
          type="primary"
          label={t('OK')}
        />
      </DialogActions>
    </MapDialog>
  )
}
