import { useContext, useCallback } from 'react'
import _ from 'lodash'
import { useApolloClient } from '@apollo/client'
import gql from 'graphql-tag'
import TranslationForm, {
  TranslationFormContext
} from 'client/components/TranslationForm/TranslationForm'
import { getContentName } from 'shared/util/items'
import HTMLText from 'client/components/HTMLText/HTMLText'
import RichTextEditorField from 'client/components/Formik/RichTextEditor/RichTextEditor'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import useCoercedParam from 'client/hooks/useCoercedParam'
import { createApiConfig, DialogOptions } from 'client/components/TranslationForm/util'
import AltTextContextualHelp from 'client/screens/Catalog/forms/ImageForm/AltTextContextualHelp'
import CaptionContextualHelp from 'client/screens/Catalog/forms/ImageForm/CaptionContextualHelp'
import ImageFileField from 'client/screens/Catalog/forms/ImageForm/ImageFileField'
import { MessageBody } from 'client/screens/Catalog/forms/ImageForm/styledComponents'
import { ImageInput } from 'shared/graphql/mutations/ImageInput'
import { ImageSchemaClient } from 'client/validation/Image'
import ToggleField from 'client/components/Form/ToggleField/ToggleField'
import { t } from 'client/i18n'

const apiConfig = createApiConfig(
  'image',
  gql`
    query getSingleImage($id: Int!) {
      image(id: $id) {
        id
        externalId
        source
        full
        thumbnail
        hero
        isMarketingUseAllowed
        processingError
        translations {
          altText
          caption
          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

const ImageFormView = () => {
  const { getFieldName, getFieldValue, contentId } = useContext(TranslationFormContext)

  return (
    <>
      <FormField translatable={false} label={t('* Image')}>
        <ImageFileField
          imageId={contentId}
          name={getFieldName('file')}
          source={getFieldValue('source')}
          full={getFieldValue('full')}
          thumbnail={getFieldValue('thumbnail')}
          hero={getFieldValue('hero')}
        />
      </FormField>
      <TextInputFormField
        name={getFieldName('altText')}
        label={t('Alternative Text (Alt Text)')}
        additionalLabelNode={AltTextContextualHelp}
        placeholder={t('e.g., Painting of a yellow vase overflowing with vibrant red tulips')}
      />
      <RichTextEditorField
        name={getFieldName('caption')}
        additionalLabelNode={CaptionContextualHelp}
        label={t('Caption')}
        fieldContainer={FormField}
      />
      <FormField translatable={false}>
        <ToggleField
          name={getFieldName('isMarketingUseAllowed')}
          label={t('Allow Use in Bloomberg Connects Social Media')}
          description={t(
            'Bloomberg Connects can use this image on Bloomberg Connects social media channels. Posts will be shared with you for awareness before going live and will be credited, where possible, based on credit information provided in the CMS.'
          )}
        />
      </FormField>
    </>
  )
}

const ImageForm = () => {
  const apolloClient = useApolloClient()
  const contentId = useCoercedParam<number>('id')

  const validateDelete = useCallback(
    async ({ contentId: imageId }: { contentId?: number }) => {
      const { data } = await apolloClient.query({
        query: gql`
          query onlyImage($id: Int!) {
            image(id: $id) {
              exhibits {
                id
                title
                images {
                  id
                }
              }
              items {
                id
                title
                commonName
                scientificName
                images {
                  id
                }
              }
            }
          }
        `,
        variables: { id: imageId }
      })

      const {
        image: { items, exhibits }
      } = data

      const exhibitsOrItems = [...items, ...exhibits]

      const contentBlockingDeletion = _.filter(
        exhibitsOrItems,
        (exhibitOrItem) => exhibitOrItem.images?.length === 1
      )

      if (contentBlockingDeletion.length === 0) {
        return
      }

      const blockers = _(contentBlockingDeletion)
        .map((content) => (
          // eslint-disable-next-line no-underscore-dangle
          <li key={`blocker-${content.__typename}-${content.id}`}>
            <HTMLText html={getContentName(content)} />
          </li>
        ))
        .value()

      const options: DialogOptions = {
        body: (
          <>
            <p>{t('To delete this image you must first remove it from:')}</p>
            <MessageBody>{blockers}</MessageBody>
          </>
        ),
        isBlocking: true,
        title: t('Unable to Delete Image')
      }

      return options
    },
    [apolloClient]
  )

  return (
    <TranslationForm
      contentName="Image"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={ImageSchemaClient}
      validateDelete={validateDelete}
      inputType={ImageInput}
      initialValidationFields={contentId ? ['processingError'] : undefined}
    >
      <ImageFormView />
    </TranslationForm>
  )
}
export default ImageForm
