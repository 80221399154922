import { useContext } from 'react'
import _ from 'lodash'
import gql from 'graphql-tag'
import TranslationForm, {
  TranslationFormContext
} from 'client/components/TranslationForm/TranslationForm'
import useCoercedParam from 'client/hooks/useCoercedParam'
import FormField from 'client/components/TranslationForm/TranslatableFormField'
import FormFieldSection from 'client/components/TranslationForm/TranslatableFormFieldSection'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import RichTextEditor from 'client/components/Formik/RichTextEditor/RichTextEditor'
import AudiosList from 'client/components/PickerLists/AudiosList'
import VideosList from 'client/components/PickerLists/VideosList'
import RelatedContent from 'client/screens/Catalog/forms/shared/RelatedContent'
import DisplayPeriod from 'client/screens/Catalog/forms/shared/DisplayPeriod'
import { createApiConfig } from 'client/components/TranslationForm/util'
import useValidateFeatured from 'client/components/TranslationForm/useValidateFeatured'
import ExhibitLookupNumberSelect from 'client/screens/Catalog/forms/shared/LookupNumber/ExhibitLookupNumberSelect'
import ExhibitItemList from 'client/screens/Catalog/forms/shared/ExhibitItemList'
import ImagesListField from 'client/screens/Catalog/forms/shared/ImagesListField'
import { ExhibitInput } from 'shared/graphql/mutations/ExhibitInput'
import { ExhibitionType } from 'shared/constants/exhibits'
import { TourSchemaClient } from 'client/validation/Exhibition'
import { EXHIBITION_TITLE_MAX_LENGTH } from 'shared/validation/constants'
import { t } from 'client/i18n'
import { TourWarningBanner } from 'client/screens/Catalog/forms/TourForm/TourWarningBanner'
import extractFromToDatesAndCleanupExpirationData from 'client/screens/Catalog/forms/shared/fromToDates'
import {
  extractItemsForExhibitAndTour,
  parseInitialValuesForExhibitAndTour
} from 'client/util/exhibits'
import LinkedGuideSelectBoxField from 'client/screens/Catalog/forms/shared/LinkedGuideSelectBoxField'
import { useFeatureFlags } from 'client/hooks/useFeatureFlags'

const apiConfig = createApiConfig(
  'exhibit',
  gql`
    query getSingleTour($id: Int!) {
      exhibit(id: $id) {
        id
        type
        estimatedDuration
        externalId
        uuid
        displayPeriod
        from
        to
        timezone
        lookupNumber
        linkedGuideId
        lastExpiredDate
        expirationEnabled
        hideOnExpiration
        images {
          id
        }
        audios {
          id
        }
        videos {
          id
        }
        items {
          id
          lookupNumber
          itemMapLocation {
            mapLocation {
              id
            }
          }
        }
        relatedContent {
          item {
            uuid
            id
          }
          exhibit {
            uuid
            id
          }
          relationshipType
        }
        translations {
          locale {
            englishName
            code
          }
          title
          information
        }
      }
    }
  `
)

const TourFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)

  return (
    <>
      <ImagesListField name={getFieldName('images')} />

      <TextInputFormField
        label={t('* Title')}
        name={getFieldName('title')}
        maxLength={EXHIBITION_TITLE_MAX_LENGTH}
      />

      <DisplayPeriod />

      <TextInputFormField
        type="number"
        min={1}
        onKeyDown={(event) => {
          // The condition added below to ensure user can only input positive whole numbers excluding e.
          if (_.includes(['.', '-', 'e', 'E'], event.key)) {
            event.preventDefault()
          }
        }}
        translatable={false}
        name={getFieldName('estimatedDuration')}
        label={t('Estimated Duration (Minutes)')}
        placeholder={t('e.g., 15, 45, 90, 120')}
      />

      <RichTextEditor
        name={getFieldName('information')}
        label={t('Description')}
        fieldContainer={FormField}
      />

      <LinkedGuideSelectBoxField />

      <FormFieldSection label={t('Audio')} translatable={false}>
        <FormField translatable={false}>
          <AudiosList name={getFieldName('audios')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Video')} translatable={false}>
        <FormField translatable={false}>
          <VideosList name={getFieldName('videos')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection translatable={false}>
        <FormField translatable={false}>
          <ExhibitLookupNumberSelect name={getFieldName('lookupNumber')} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Tour Stops')} translatable={false}>
        <FormField translatable={false}>
          <ExhibitItemList name={getFieldName('items')} type={ExhibitionType.TOUR} />
        </FormField>
      </FormFieldSection>

      <FormFieldSection label={t('Related')} translatable={false}>
        <FormField translatable={false}>
          <RelatedContent name={getFieldName('relatedContent')} />
        </FormField>
      </FormFieldSection>
    </>
  )
}

const TourForm = () => {
  const type = ExhibitionType.TOUR
  const { validateDeleteFeatured } = useValidateFeatured('exhibit', type)
  const contentId = useCoercedParam<number>('id')
  const { EXPIRATION } = useFeatureFlags()

  return (
    <TranslationForm
      contentName="Tour"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={TourSchemaClient}
      defaultValues={{
        type,
        displayPeriod: true,
        // to ensure only newly created tours with visible expiration UI can set expirationEnabled to true
        expirationEnabled: !!EXPIRATION
      }}
      inputType={ExhibitInput}
      initialValuesParser={(values) => parseInitialValuesForExhibitAndTour(values)}
      submitValuesParser={(values) => {
        return {
          ...values,
          estimatedDuration: Number.parseInt(values.estimatedDuration, 10),
          items: extractItemsForExhibitAndTour(values.items),
          ...extractFromToDatesAndCleanupExpirationData(values)
        }
      }}
      validateDelete={validateDeleteFeatured}
      additionalFields={{ nontranslatable: ['displayPeriodType'] }}
      extraBannerComponent={<TourWarningBanner />}
    >
      <TourFormView />
    </TranslationForm>
  )
}
export default TourForm
