import { memo, useCallback, useState } from 'react'
import styled from 'styled-components'
import WorkflowManager from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowManager'
import PlusIconAddButton from 'client/components/Button/PlusIconAddButton'
import { t } from 'client/i18n'
import { ExteriorMapStep, ExteriorMapUploading, ExteriorMapSuccessStep } from './Steps'

const Container = styled.div`
  border-bottom: var(--border-light);
  display: flex;
  justify-content: center;
  align-items: center;
`

const AddExteriorMapButton = styled(PlusIconAddButton)`
  border: 1px dotted var(--color-grey-02);
  border-radius: 2px;
  width: 100%;
  height: 60px;
  margin: var(--spacing-small);
`

const addExteriorMapFlow = [
  { name: 'addExteriorMap', component: ExteriorMapStep },
  { name: 'uploadingExteriorMap', component: ExteriorMapUploading },
  { name: 'exteriorMapUploaded', component: ExteriorMapSuccessStep }
]

interface IAddExteriorMapProps {
  hidden?: boolean
}

const AddExteriorMapWorkflow = memo((props: IAddExteriorMapProps) => {
  const { hidden = false } = props
  const [startCreationFlow, setStartCreationFlow] = useState(false)

  const onCancel = useCallback(() => setStartCreationFlow(false), [])

  return (
    <Container>
      {!hidden && (
        <AddExteriorMapButton
          label={t('Add Exterior Map')}
          onClick={() => setStartCreationFlow(true)}
        />
      )}
      {startCreationFlow && (
        <WorkflowManager workflowSteps={addExteriorMapFlow} onCancel={onCancel} />
      )}
    </Container>
  )
})

export default AddExteriorMapWorkflow
