import * as React from 'react'
import styled from 'styled-components'
import useField from 'client/hooks/useField'

const onIcon = require('assets/images/ToggleOn.png')
const offIcon = require('assets/images/ToggleOff.png')
const disabledOnIcon = require('assets/images/disabledOnIcon.png')
const disabledOffIcon = require('assets/images/disabledOffIcon.png')

interface ToggleImageProps {
  disabled?: boolean
}

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: space-between;
`

const Label = styled.div`
  margin-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-black);
`

const ToggleImage = styled.img<ToggleImageProps>`
  ${({ disabled }) => !disabled && 'cursor: pointer;'}
  height: 20px;
`

interface ToggleInputProps {
  name?: string
  value: boolean | undefined
  label?: React.ReactNode
  onChange: (event) => void
  disabled?: boolean
  className?: string
}

const getToggleSrc = (value: boolean | undefined, disabled: boolean | undefined) => {
  if (disabled) {
    return value ? disabledOnIcon : disabledOffIcon
  }
  return value ? onIcon : offIcon
}

const ToggleInput = (props: ToggleInputProps) => {
  const { name, value, onChange, label, disabled, className } = props
  const onClick = () => !disabled && onChange({ target: { name, value: !value } })
  const src = getToggleSrc(value, disabled)
  return (
    <Container className={className}>
      {label && <Label>{label}</Label>}
      <ToggleImage src={src} onClick={onClick} />
    </Container>
  )
}

interface ToggleFieldProps {
  name: string
  label?: string
  className?: string
}
export const ToggleInputField = (props: ToggleFieldProps) => {
  const { name, label, className } = props
  const { value, setValue } = useField(name)
  return (
    <ToggleInput
      className={className}
      name={name}
      label={label}
      value={value}
      onChange={({ target }) => setValue(target.value)}
    />
  )
}

export default ToggleInput
