import { useCurrentEditor } from '@tiptap/react'
import { useState } from 'react'
import BoldSVG from 'assets/svg/icon/bold.svg'
import IconOnlyButton from 'client/components/IconButton/IconOnlyButton'
import ItalicSVG from 'assets/svg/icon/italic.svg'
import LinkSVG from 'assets/svg/icon/link.svg'
import styled from 'styled-components'
import Tooltip from 'client/dsm/Tooltip/Tooltip'

import { t } from 'client/i18n'
import LinkInputControls from './LinkInputControls'

const ToolbarContainer = styled.div`
  position: relative;
  height: 32px;
  display: flex;
`

const ToolbarButton = styled(IconOnlyButton)<{ isActive?: boolean }>`
  width: 32px;
  height: 32px;
  background-color: ${({ isActive }) => (isActive ? ' var(--color-grey-02)' : 'transparent')};
  color: ${({ isActive }) => (isActive ? 'var(--color-grey-08)' : 'inherit')};
  :hover {
    background: var(--color-grey-01);
    outline: 0px;
  }
  svg {
    fill: ${({ isActive }) => (isActive ? 'var(--color-black)' : 'var(--color-grey-06)')};
    align-self: center;
  }
`
interface IRichTextEditorToolbarProps {
  mode: 'single' | 'multi'
}

const RichTextEditorToolbar = (props: IRichTextEditorToolbarProps) => {
  const { mode } = props

  const { editor } = useCurrentEditor()
  const [showLinkInput, setShowLinkInput] = useState(false)

  const isSingleMode = mode === 'single'

  if (!editor) {
    return null
  }

  if (showLinkInput) {
    return (
      <ToolbarContainer>
        <LinkInputControls
          value={editor.getAttributes('link').href}
          onConfirm={(href) => {
            editor.chain().focus().extendMarkRange('link').setLink({ href }).run()
            setShowLinkInput(false)
          }}
          onCancel={() => {
            editor.chain().focus().extendMarkRange('link').unsetLink().run()
            setShowLinkInput(false)
          }}
          onBlur={() => {
            setShowLinkInput(false)
          }}
        />
      </ToolbarContainer>
    )
  }

  return (
    <ToolbarContainer>
      {!isSingleMode && (
        <Tooltip content={t('Bold')}>
          <div>
            <ToolbarButton
              svgComponent={BoldSVG}
              onClick={() => editor.chain().focus().toggleBold().run()}
              disabled={!editor.can().chain().focus().toggleBold().run()}
              isActive={editor.isActive('bold')}
            />
          </div>
        </Tooltip>
      )}
      <Tooltip content={t('Italic')}>
        <div>
          <ToolbarButton
            svgComponent={ItalicSVG}
            onClick={() => editor.chain().focus().toggleItalic().run()}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            isActive={editor.isActive('italic')}
          />
        </div>
      </Tooltip>
      {!isSingleMode && (
        <Tooltip content={t('Link')}>
          <div>
            <ToolbarButton
              svgComponent={LinkSVG}
              onClick={() => setShowLinkInput(true)}
              isActive={editor.isActive('link')}
            />
          </div>
        </Tooltip>
      )}
    </ToolbarContainer>
  )
}
export default RichTextEditorToolbar
