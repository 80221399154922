import { useFilePicker } from 'use-file-picker'

import Button from 'client/components/Button/Button'
import PageContent from 'client/components/PageContent/PageContent'
import UploadSVG from 'assets/svg/icon/upload_20.svg'
import LoadingOverlay from 'client/components/LoadingOverlay/LoadingOverlay'
import download from 'downloadjs'
import { useState } from 'react'
import axios from 'axios'
import Banner, { BannerItem } from 'client/dsm/Banner/Banner'
import { EmptyView } from 'client/components/PageContent/EmptyView'
import styled from 'styled-components'
import DownloadSVG from 'assets/svg/icon/download_20.svg'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'

const ACCEPTED_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel'
]

interface PageControlsProps {
  uploadDisabled?: boolean
  onUpload: () => void
}
const PageControls = (props: PageControlsProps) => {
  const { onUpload, uploadDisabled } = props

  return (
    <>
      <Button
        onClick={() => window.open('/api/qrcodes/marketing/template', '_self')}
        svgComponent={DownloadSVG}
        label={t('Download Starter Template')}
      />
      <Button
        type="primary"
        onClick={onUpload}
        disabled={uploadDisabled}
        svgComponent={UploadSVG}
        label={t('Upload')}
      />
    </>
  )
}

const Container = styled.div`
  width: 100%;
  padding: var(--spacing-large);
`

function useQrCodeSheetUpload() {
  const [isUploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState<string | null>(null)

  const upload = async (file: File) => {
    setUploading(true)
    setUploadError(null)
    const formData = new FormData()
    formData.append('file', file)
    try {
      const { data } = await axios.post('/api/qrcodes/marketing/generate', formData, {
        responseType: 'blob'
      })
      download(data, 'qrcodes.zip', 'application/zip')
    } catch (e) {
      const errorText = await e.response.data.text()
      const error = JSON.parse(errorText)
      setUploadError(error.message || JSON.stringify(error, null, 4))
    } finally {
      setUploading(false)
    }
  }

  return { upload, isUploading, uploadError }
}

export default function QrCodeGeneration() {
  const { upload, isUploading, uploadError } = useQrCodeSheetUpload()

  const { openFilePicker } = useFilePicker({
    accept: ACCEPTED_FILE_TYPES,
    onFilesSuccessfullySelected: ({ plainFiles }) => upload(plainFiles[0])
  })

  return (
    <PageContent
      title={t('QR Code Generation')}
      controls={<PageControls onUpload={openFilePicker} />}
    >
      {isUploading && <LoadingOverlay loadingText={t('Uploading...')} />}
      {uploadError ? (
        <>
          <Banner type="error" headingText={t('QR Codes Not Generated')}>
            <BannerItem>{t('Review the details below, then upload a new file.')}</BannerItem>
          </Banner>
          <Container>{uploadError}</Container>
        </>
      ) : (
        <EmptyView message={<Trans i18nKey="Click Upload to get started." />} />
      )}
    </PageContent>
  )
}
