import { IGetMapLocationContent } from 'client/redux/selectors/maps'
import _ from 'lodash'
import { useMemo } from 'react'
import { ExhibitionType } from 'shared/constants/exhibits'
import { GQLExhibit, GQLItem } from 'shared/graphql/types/graphql'
import { COMMON_CONTENT_FILTER_CRITERIA } from 'client/util/filters'
import { ReorderableMapLocationContentRow } from 'client/components/ContentRow/ReorderableRow'
import ToggleInput from 'client/components/Form/ToggleInput/ToggleInput'
import FilterableMiniPicker from 'client/components/MiniPicker/FilterableMiniPicker/FilterableMiniPicker'
import ReorderableList from 'client/components/ReorderableList/ReorderableList'
import { PickerItemOrExhibitRow } from 'client/components/ContentRow/PickerRow'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { t } from 'client/i18n'

const StyledMiniPicker = styled(FilterableMiniPicker)`
  margin-top: var(--spacing-medium);
`

const StyledReorderableList = styled(ReorderableList)`
  margin-top: var(--spacing-medium);
`

const ID_FIELD = 'uuid'

interface IReorderableLocationContentListProps {
  items: GQLItem[]
  exhibits: GQLExhibit[]
}

type PickerData = IGetMapLocationContent[]

interface IValues {
  location: string
  content: PickerData
  latitude: number
  longitude: number
}

export default function ReorderableLocationContentList({
  items,
  exhibits
}: IReorderableLocationContentListProps) {
  const { initialValues, values, handleChange } = useFormikContext<IValues>()

  const initialContent = initialValues.content
  const { content } = values

  const pickerData = useMemo(() => {
    const initialSelectedIds = _.map(initialContent, ID_FIELD)
    const selectedIds = _.map(content, ID_FIELD)
    const removedIds = _.difference(initialSelectedIds, selectedIds)

    const itemList = _(items)
      // Items can only have one location
      .filter(
        (item) => _.isNil(item.itemMapLocation?.mapLocation.id) || _.includes(removedIds, item.uuid)
      )
      .reject((item) => _.includes(selectedIds, item.uuid))
      .map(
        (item) =>
          ({
            ...item,
            content: { item, mapLocation: item.itemMapLocation?.mapLocation, featured: false }
          } as IGetMapLocationContent)
      )
      .value()

    const exhibitList = _(exhibits)
      .filter(
        (exhibit) =>
          exhibit.type === ExhibitionType.EXHIBITION ||
          exhibit.type === ExhibitionType.EVENT ||
          // Tours can only have one location
          (exhibit.type === ExhibitionType.TOUR &&
            (_.isEmpty(exhibit.exhibitMapLocations) || _.includes(removedIds, exhibit.uuid)))
      )
      .reject((exhibit) => _.includes(selectedIds, exhibit.uuid))
      .map(
        (exhibit) =>
          ({
            ...exhibit,
            content: { exhibit, featured: true }
          } as IGetMapLocationContent)
      )
      .value()

    return [...itemList, ...exhibitList]
  }, [items, exhibits, content, initialContent])

  const updateContent = (locationContent: IGetMapLocationContent[]) => {
    handleChange({ target: { name: 'content', value: locationContent } })
  }

  const handleAddContent = (ids: string[]) => {
    const locationContent = _.map(ids, (id) => _.find(pickerData, { [ID_FIELD]: id })!)
    updateContent([...content, ...locationContent])
  }

  const updateFeaturedInContent = (
    contentToUpdate: IGetMapLocationContent,
    featured: boolean,
    updateAllMapLocations?: boolean
  ) => {
    const updatedContent: IGetMapLocationContent[] = content.map((item) => {
      if (item.id === contentToUpdate.id) {
        return {
          ...contentToUpdate,
          content: {
            ...contentToUpdate.content,
            featured,
            updateAllMapLocations
          }
        }
      }
      return item
    })
    updateContent(updatedContent)
  }

  const onToggleChange =
    (toggledContent: IGetMapLocationContent) => (event: { target: { value: boolean } }) => {
      updateFeaturedInContent(toggledContent, event.target.value)
    }

  return (
    <>
      <StyledReorderableList
        name="content-list"
        values={content}
        rowComponent={(reorderableRowProps) => (
          <ReorderableMapLocationContentRow
            {...reorderableRowProps}
            flexComponent={(value) => (
              <ToggleInput
                name="map_location"
                label={t('Feature on Map Tab')}
                value={value.content.featured}
                onChange={onToggleChange(value)}
              />
            )}
          />
        )}
        onUpdate={updateContent}
        idFieldName={ID_FIELD}
      />
      <StyledMiniPicker
        items={pickerData}
        contentName="Content"
        rowComponent={PickerItemOrExhibitRow}
        filterCriteria={COMMON_CONTENT_FILTER_CRITERIA}
        onSubmit={handleAddContent}
        idField={ID_FIELD}
      />
    </>
  )
}
