import _ from 'lodash'
import styled from 'styled-components'
import MenuPopout, { IMenuOption } from 'client/components/MenuPopout/MenuPopout'
import { GQLLocale } from 'shared/graphql/types/graphql'
import SelectBox, { ISelectBoxOptions, Option } from 'client/components/Form/SelectBox/SelectBox'
import AddTranslationDialog from 'client/components/TranslationForm/AddTranslationDialog'
import { useDialog, isLocaleCode } from 'client/components/TranslationForm/util'
import GlobeSVG from 'assets/svg/icon/globe_20.svg'
import { FormikErrors, FormikTouched } from 'formik'
import PlusIconAddButton, {
  IPlusIconAddButtonProps
} from 'client/components/Button/PlusIconAddButton'
import mapLocaleToOption from 'client/util/mapLocaleToOption'
import { t } from 'client/i18n'
import { FormikTranslationError } from './TranslationForm'

const Container = styled.div`
  display: flex;
  align-items: center;
`

const SelectContainer = styled.div`
  display: flex;
  margin-right: 10px;
`

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black);
  width: 40px;
  height: 40px;
`

const GlobeIcon = styled(GlobeSVG)`
  width: 16px;
  height: 16px;
  color: var(--color-white);
`

const SelectBoxWrapper = styled.div`
  height: 40px;
  width: 280px;
`

const AddTranslationOptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-top: 1px solid var(--color-grey-03);
  height: 60px;

  div {
    display: flex;
    align-items: top;
  }
`

interface IStyledButtonProps extends IPlusIconAddButtonProps {
  isFocused: boolean
  disabled: boolean
}
const StyledButton = styled(PlusIconAddButton)<IStyledButtonProps>`
  width: 100%;
  height: 100%;

  background-color: ${({ isFocused, disabled }) =>
    /* eslint-disable no-nested-ternary */
    disabled && isFocused
      ? 'var(--color-grey-01)'
      : isFocused
      ? 'var(--color-accent-03)'
      : 'transparent'};
  /* eslint-enable no-nested-ternary */

  &:hover:enabled,
  &:active:hover {
    background-color: ${({ isFocused, disabled }) =>
      /* eslint-disable no-nested-ternary */
      disabled && isFocused
        ? 'var(--color-grey-01)'
        : isFocused
        ? 'var(--color-accent-03)'
        : 'transparent'};
    /* eslint-enable no-nested-ternary */
    border: none;
  }
`

const ADD_TRANSLATION_OPTION_VALUE = 'addTranslation'
const addTranslationOption = { value: ADD_TRANSLATION_OPTION_VALUE, label: '' }

const TranslationOption = (props: any) => {
  return props.data.value === ADD_TRANSLATION_OPTION_VALUE ? (
    <AddTranslationOptionContainer ref={props?.innerRef} {...props.innerProps}>
      <StyledButton
        isFocused={props.isFocused}
        disabled={props.isDisabled}
        onClick={_.noop} // this is still an select option, and so the click behavior is handled by the option click;
        label={t('Add Translation')}
      />
    </AddTranslationOptionContainer>
  ) : (
    Option(props)
  )
}

const parseErrors = (
  errors: FormikErrors<FormikTranslationError>,
  touched: FormikTouched<any>,
  defaultLocale: GQLLocale
): string[] => {
  // the translation dropdown should only visually show errors for things that are deemed touched;
  // otherwise, it will display errors in a locale that was not at all touched, & perhaps just added;
  return _.reduce(
    errors,
    (acc: string[], value, key) => {
      if (!isLocaleCode(key)) {
        if (touched[key]) {
          acc.push(defaultLocale.code)
        }
      } else {
        const isAnythingTouchedInLocale = _.some(
          errors[key],
          (errorValue, field) => touched[key]?.[field]
        )
        if (isAnythingTouchedInLocale) {
          acc.push(key)
        }
      }

      return acc
    },
    []
  )
}

enum DialogType {
  ADD_TRANSLATIONS = 'DialogType.ADD_TRANSLATIONS'
}

interface ITranslationsDropdownProps {
  locales: GQLLocale[]
  defaultLocale: GQLLocale
  selectedLocale: GQLLocale
  errors: FormikErrors<FormikTranslationError>
  touched: FormikTouched<any>
  availableLocales?: GQLLocale[]

  onSelect: (locale: GQLLocale) => void
  onRemove: () => void
  onAdd: (locale: GQLLocale) => void
}

const TranslationsDropdown = (props: ITranslationsDropdownProps) => {
  const {
    onSelect,
    onAdd,
    onRemove,
    defaultLocale,
    locales,
    selectedLocale,
    availableLocales,
    errors,
    touched
  } = props

  const { setCurrentDialog, currentDialog, closeDialog } = useDialog<DialogType>()

  const onAddLocale = (locale: GQLLocale) => {
    onAdd(locale)
    closeDialog()
  }

  const localeCodesWithErrors = parseErrors(errors, touched, defaultLocale)

  // disable the ability to add locales if all available locales have been added
  const disableAddTranslationOption = locales?.length === availableLocales?.length

  const translationLocales = _.reject(locales, { code: defaultLocale?.code })

  const selectOptions: ISelectBoxOptions[] = defaultLocale
    ? [
        mapLocaleToOption(defaultLocale, true, localeCodesWithErrors),
        // TODO: what is this for?
        {
          label: t('Translations'),
          value: 'translationsHeader',
          options: _.map(translationLocales, (locale) =>
            mapLocaleToOption(locale, false, localeCodesWithErrors)
          )
        },
        addTranslationOption
      ]
    : []

  const selectedOption = !_.isEmpty(selectedLocale)
    ? mapLocaleToOption(
        selectedLocale,
        selectedLocale?.code === defaultLocale?.code,
        localeCodesWithErrors
      )
    : { label: '', value: '' } // until the data exists, populate the dropdown explicitly with nothing for better UX;

  const onSelectChange = (e: { target: { value: any } }) => {
    const code = e.target?.value
    if (code === addTranslationOption.value) {
      setCurrentDialog(DialogType.ADD_TRANSLATIONS)
    } else {
      onSelect(_.find(locales, { code })!)
    }
  }

  // don't allow them to remove default language (this check should exist server side too)
  const allowTranslationRemoval = defaultLocale?.code !== selectedLocale?.code

  const menuOptions: IMenuOption[] = [
    ...(allowTranslationRemoval ? [{ label: t('Delete Translation'), onClick: onRemove }] : [])
  ]

  const renderDialog = () => {
    switch (currentDialog.type) {
      case DialogType.ADD_TRANSLATIONS:
        return (
          <AddTranslationDialog
            availableLocales={availableLocales}
            excludedLocales={locales}
            onAddLocale={onAddLocale}
            onCancel={closeDialog}
          />
        )
      default:
        return null
    }
  }

  return (
    <>
      {renderDialog()}
      <Container>
        <SelectContainer>
          <IconContainer>
            <GlobeIcon />
          </IconContainer>
          <SelectBoxWrapper>
            <SelectBox
              name="translations"
              value={selectedOption}
              options={selectOptions}
              isOptionDisabled={(option) =>
                option.value === ADD_TRANSLATION_OPTION_VALUE && disableAddTranslationOption
              }
              onChange={onSelectChange}
              components={{ Option: TranslationOption }}
              isSearchable={true}
              selectedHighlightColor={
                // Note, explicitly defaulting to undefined here until selectedLocale is defined
                selectedLocale && selectedLocale.code !== defaultLocale?.code
                  ? 'var(--color-blue-01)' // non default language
                  : undefined // default language
              }
              hasError={!_.isEmpty(localeCodesWithErrors)}
            />
          </SelectBoxWrapper>
        </SelectContainer>
        <MenuPopout options={menuOptions} testId="translations-menu-popout" />
      </Container>
    </>
  )
}

export default TranslationsDropdown
