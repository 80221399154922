import { useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { getExterior } from 'client/redux/selectors/maps'
import EmptyState from 'client/dsm/EmptyState/EmptyState'
import EmptyStateGridMapSVG from 'assets/svg/emptyStates/empty_state_grid_map.svg'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'
import { t } from 'client/i18n'
import { Header3 } from 'client/components/TextStyles'
import { EmptyStatePlaceholderDescription } from 'client/screens/Catalog/grids/shared/styledComponents'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  svg {
    height: auto;
    width: auto;
  }
  /* Add spacing between children */
  & > * + * {
    margin-top: 20px;
  }
`

const EmptyMapContentView = () => {
  const exterior = useSelector(getExterior)
  const buildings = useBuildingList()
  const hasFloors = !_.isEmpty(_(buildings).map('floors').flatten().value())
  const showSelectFloorEmptyMessage = hasFloors || !_.isEmpty(exterior)

  return showSelectFloorEmptyMessage ? (
    <Container>
      <EmptyState>
        <p>{t('No maps have been selected.')}</p>
        <p>{t('Select map to edit.')}</p>
      </EmptyState>
    </Container>
  ) : (
    <Container>
      <EmptyState icon={<EmptyStateGridMapSVG />}>
        <Header3>{t('Add maps to your guide')}</Header3>
        <EmptyStatePlaceholderDescription centerAlign={true}>
          {t(
            'Maps help visitors navigate your physical space and find objects on site. To get started, add a building and upload floor plans or add an exterior map of your location or grounds.'
          )}
        </EmptyStatePlaceholderDescription>
      </EmptyState>
    </Container>
  )
}

export default EmptyMapContentView
