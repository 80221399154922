import { useEffect } from 'react'
import { ExteriorMapSuccess } from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowComponents/ExteriorMapUpload'
import { IStepProps } from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowManager'
import { getExterior, isLoading } from 'client/redux/selectors/maps'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'

const ExteriorMapSuccessStep = ({ onCancel }: IStepProps) => {
  const exteriorMap = useSelector(getExterior)
  const isUploading = useSelector(isLoading)
  const buildings = useBuildingList()
  const shouldRender = Boolean(exteriorMap && !_.isEmpty(buildings) && !isUploading)

  useEffect(() => {
    if (_.isEmpty(buildings)) {
      onCancel!()
    }
  }, [buildings, onCancel])

  if (!shouldRender) {
    return null
  }

  return <ExteriorMapSuccess onContinue={onCancel} />
}

export default ExteriorMapSuccessStep
