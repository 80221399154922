import { useContext } from 'react'
import gql from 'graphql-tag'
import TranslationForm, {
  TranslationFormContext
} from 'client/components/TranslationForm/TranslationForm'
import TextInputFormField from 'client/components/TranslationForm/TranslatableTextInputField'
import useCoercedParam from 'client/hooks/useCoercedParam'
import { createApiConfig } from 'client/components/TranslationForm/util'
import CreatorInput from 'shared/graphql/mutations/CreatorInput'
import { CreatorSchemaClient } from 'client/validation/Creator'
import { t } from 'client/i18n'

const apiConfig = createApiConfig(
  'creator',
  gql`
    query getSingleCreator($id: Int!) {
      creator(id: $id) {
        id
        externalId
        translations {
          name
          prefix
          lifedates

          locale {
            englishName
            code
          }
        }
      }
    }
  `
)

const CreatorFormView = () => {
  const { getFieldName } = useContext(TranslationFormContext)
  return (
    <>
      <TextInputFormField
        name={getFieldName('prefix')}
        label={t('Prefix')}
        placeholder={t('e.g., Dr., Mrs., Sra., Professor')}
      />
      <TextInputFormField name={getFieldName('name')} label={t('* Name')} />
      <TextInputFormField name={getFieldName('lifedates')} label={t('Life Dates')} />
    </>
  )
}

const CreatorForm = () => {
  const contentId = useCoercedParam<number>('id')

  return (
    <TranslationForm
      contentName="Creator"
      contentId={contentId}
      apiConfig={apiConfig}
      validationSchema={CreatorSchemaClient}
      inputType={CreatorInput}
    >
      <CreatorFormView />
    </TranslationForm>
  )
}
export default CreatorForm
