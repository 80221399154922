import { useDispatch } from 'react-redux'
import _ from 'lodash'
import * as yup from 'yup'
import { useCallback } from 'react'
import { alert } from 'client/redux/actions/confirmation'

const validationSchema = yup.object().shape({
  latitude: yup.number().required().min(-90).max(90),
  longitude: yup.number().required().min(-180).max(180)
})

type LatLongvalue = string | number | undefined | null

function isValidLatLong(latitude: LatLongvalue, longitude: LatLongvalue) {
  if (!_.isEmpty(latitude) || !_.isEmpty(longitude)) {
    return validationSchema.isValidSync({
      latitude,
      longitude
    })
  }

  return true
}

export default function useLatLongValidation() {
  const dispatch = useDispatch()

  return useCallback(
    (latitude: LatLongvalue, longitude: LatLongvalue) => {
      if (!isValidLatLong(latitude, longitude)) {
        dispatch(
          alert(
            'Invalid Latitude/Longitude',
            'Latitude values must be between -90 and 90.  Longitude values must be between -180 and 180'
          )
        )
        return false
      }
      return true
    },
    [dispatch]
  )
}
