import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'
import _ from 'lodash'
import Tooltip from 'client/dsm/Tooltip/Tooltip'
import { IObjectWithLocales } from 'shared/json/IObjectWithLocales'
import GlobeSVG from 'assets/svg/icon/globe_20.svg'
import useGuideDefaultLocale from 'client/components/Languages/useGuideDefaultLocale'
import { ThemeType, IThemeObject } from 'client/types'
import { Body1CSS } from 'client/components/TextStyles'
import { t } from 'client/i18n'
import { TKey } from 'shared/i18n/types/translationResources'

const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const StyledGlobeSVG = styled(GlobeSVG)`
  width: 16px;
  height: 16px;
`

const TooltipLanguage = styled.div`
  margin: 0;
  padding: 0;
  line-height: 24px;
`

const GlobeIconTooltipContainer = styled.div<{ themeType: ThemeType }>`
  ${Body1CSS};
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  cursor: pointer;

  color: ${({ themeType }) =>
    themeType === ThemeType.DARK ? 'var(--color-black)' : 'var(--color-grey-03)'};
  svg {
    color: ${({ themeType }) =>
      themeType === ThemeType.DARK ? 'var(--color-black)' : 'var(--color-grey-03)'};
  }

  :hover {
    color: ${({ themeType }) =>
      themeType === ThemeType.DARK ? 'var(--color-blue-07)' : 'var(--color-white)'};
    svg {
      color: ${({ themeType }) =>
        themeType === ThemeType.DARK ? 'var(--color-blue-07)' : 'var(--color-white)'};
    }
  }
`

const LanguageCountSpan = styled.span`
  line-height: 21px;
  height: 20px;
`

const LanguagesTooltip = ({ locales }: IObjectWithLocales) => {
  const { loading, defaultLocale } = useGuideDefaultLocale()

  if (loading) {
    return null
  }

  return (
    <TooltipContainer>
      <div data-testid="languages-tooltip">
        {_.sortBy(locales, 'englishName').map((locale) => {
          const isDefaultLocale = locale.code === defaultLocale?.code
          const languageName = t(`language.${locale.code!}` as TKey)
          const name = isDefaultLocale ? `${languageName} - ${t('Default')}` : languageName
          return <TooltipLanguage key={locale.code}>{name}</TooltipLanguage>
        })}
      </div>
    </TooltipContainer>
  )
}

interface GlobeIconTooltipProps extends IObjectWithLocales {
  className?: string
  isPopperInteractive?: boolean
}

const GlobeIconTooltip = ({
  locales,
  className,
  isPopperInteractive = false
}: GlobeIconTooltipProps) => {
  const themeContext: IThemeObject = useContext(ThemeContext)
  const themeType = themeContext?.type ?? ThemeType.DARK

  if (!locales || _.isEmpty(locales)) {
    return null
  }

  const stopEventPropagation = (e) => {
    e.stopPropagation()
  }

  return (
    <Tooltip
      content={<LanguagesTooltip locales={locales} />}
      interactive={isPopperInteractive}
      themeType={themeType}
    >
      <GlobeIconTooltipContainer
        themeType={themeType}
        className={className}
        onClick={stopEventPropagation}
        onMouseDown={stopEventPropagation}
        data-testid="num-languages"
      >
        <StyledGlobeSVG />
        <LanguageCountSpan>{locales.length}</LanguageCountSpan>
      </GlobeIconTooltipContainer>
    </Tooltip>
  )
}

export default GlobeIconTooltip
