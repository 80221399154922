import { takeEvery } from 'redux-saga/effects'
import { SET_SELECTED_VERSION_INFO } from 'client/redux/actions/types'
import _ from 'lodash'
import { ISetSelectedVersionAction } from 'client/redux/actions/version'
import {
  getVersionInfoFromLocalStorage,
  setVersionInfoInLocalStorage
} from 'client/util/versionInfoLocalStorage'

function handleSetVersionInfo(action: ISetSelectedVersionAction) {
  const { selectedVersionInfo, reload } = action

  const currentVersionInfo = getVersionInfoFromLocalStorage()
  if (!_.isEqual(selectedVersionInfo, currentVersionInfo)) {
    setVersionInfoInLocalStorage(selectedVersionInfo)
    if (reload) {
      window.location.reload()
    }
  }
}

export default function* root() {
  yield takeEvery(SET_SELECTED_VERSION_INFO, handleSetVersionInfo)
}
