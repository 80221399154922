import gql from 'graphql-tag'

export const GUIDE_EXPORT_GQL_QUERY = gql`
  fragment locationFields on MuseumLocation {
    id
    uuid
    name
    fullAddress
  }
  fragment creatorFields on Creator {
    id
    uuid
    prefix
    name
    lifedates
    isGoogleTranslate
  }

  fragment imageFields on Image {
    id
    uuid
    caption
    altText
    fileName
    isGoogleTranslate
  }

  fragment audioFields on Audio {
    id
    uuid
    title
    description
    transcript
    isGoogleTranslate
  }

  fragment videoFields on Video {
    id
    uuid
    title
    credits
    transcript
    isGoogleTranslate
    posterImage {
      ...imageFields
    }
  }

  fragment itemFields on Item {
    id
    uuid
    type
    lookupNumber
    title
    medium
    date
    information
    dimensions
    credit
    accessionNumber
    commonName
    scientificName
    bloomSeason
    family
    origin
    range
    flowers
    leaves
    attributes
    isGoogleTranslate
    creators {
      ...creatorFields
    }
    images {
      ...imageFields
    }
    audios {
      ...audioFields
    }
    videos {
      ...videoFields
    }
  }
  fragment exhibitFields on Exhibit {
    id
    uuid
    lookupNumber
    title
    from
    to
    timezone
    isAllDay
    displayPeriod
    displayType
    information
    type
    isGoogleTranslate
    images {
      ...imageFields
    }
    audios {
      ...audioFields
    }
    videos {
      ...videoFields
    }
    items {
      ...itemFields
    }
  }
  query guideExport($museumId: Int!) {
    museum(id: $museumId) {
      displayName
      description
      versionType
      guide {
        id
      }
      locations {
        ...locationFields
      }
      features {
        uuid
        title
        isGoogleTranslate
        featuredItems {
          item {
            ...itemFields
          }
          exhibit {
            ...exhibitFields
            items {
              ...itemFields
            }
          }
        }
      }
      items {
        ...itemFields
      }
      exhibits {
        ...exhibitFields
        items {
          ...itemFields
        }
      }
    }
  }
`
