import {
  ReorderableListWithPicker,
  IReorderableListWithPickerProps
} from 'client/components/Formik/ReorderableList/ReorderableListWithPicker'
import { memo, ReactNode } from 'react'
import { DocumentNode } from '@apollo/client/core'
import { extractGQLData } from 'client/util/graphql'
import GQLErrorRenderer from 'client/components/GQLErrorRenderer'
import { IFilterableMiniPickerProps } from 'client/components/MiniPicker/FilterableMiniPicker/FilterableMiniPicker'
import useTranslationContextAwareQuery from 'client/hooks/useTranslationContextAwareQuery'
import useField from 'client/hooks/useField'
import useMediaUpload, { IUploadMediaFunction, UploadMediaType } from 'client/hooks/useMediaUpload'

interface IDataAwareReorderableListProps {
  className?: string
  name: string
  gqlQuery: DocumentNode
  values: IReorderableListWithPickerProps['values']
  maxValues?: IReorderableListWithPickerProps['maxValues']
  onChange: IReorderableListWithPickerProps['onChange']
  rowComponent: IReorderableListWithPickerProps['rowComponent']
  pickerRowComponent: IFilterableMiniPickerProps['rowComponent']
  filterCriteria: IFilterableMiniPickerProps['filterCriteria']
  isDragDisabled?: IReorderableListWithPickerProps['isDragDisabled']
  contentName: IFilterableMiniPickerProps['contentName']
  valueFieldsWithHigherPriority?: string[]
  uploadMedia?: {
    uploadFileFormats: string[]
    type: UploadMediaType
  }
}

interface IDataAwareReorderableListBaseProps
  extends Omit<IDataAwareReorderableListProps, 'uploadMedia'> {
  isUploading?: boolean
  errorDialog?: ReactNode
  uploadProps?: {
    uploadFileFormats: string[]
    onUpload: IUploadMediaFunction
    loadingText: string | undefined
  }
}

function DataAwareReorderableListBase(props: IDataAwareReorderableListBaseProps) {
  const {
    name,
    gqlQuery,
    rowComponent,
    className,
    contentName,
    isDragDisabled,
    pickerRowComponent,
    filterCriteria,
    maxValues,
    values,
    onChange,
    valueFieldsWithHigherPriority,
    isUploading,
    errorDialog,
    uploadProps
  } = props

  const { data, loading: isDataLoading, error, refetch } = useTranslationContextAwareQuery(gqlQuery)

  const source = extractGQLData(data)

  if (error) {
    return <GQLErrorRenderer error={error} />
  }

  return (
    <>
      <ReorderableListWithPicker
        name={name}
        values={values}
        onChange={onChange}
        className={className}
        rowComponent={rowComponent}
        source={source}
        isDragDisabled={isDragDisabled}
        maxValues={maxValues}
        pickerProps={{
          onTooltipShow: () => refetch(),
          isLoading: isDataLoading || isUploading,
          contentName,
          rowComponent: pickerRowComponent,
          filterCriteria,
          ...(uploadProps ?? {})
        }}
        valueFieldsWithHigherPriority={valueFieldsWithHigherPriority}
      />
      {errorDialog}
    </>
  )
}

type IDataAwareReorderableListWithUploadProps = IDataAwareReorderableListProps &
  Required<Pick<IDataAwareReorderableListProps, 'uploadMedia'>>
function DataAwareReorderableListWithUpload(props: IDataAwareReorderableListWithUploadProps) {
  const {
    uploadMedia: { type, uploadFileFormats },
    ...otherProps
  } = props

  const { uploadMedia, isUploading, loadingText, errorDialog } = useMediaUpload(type)

  return (
    <DataAwareReorderableListBase
      isUploading={isUploading}
      errorDialog={errorDialog}
      uploadProps={{ uploadFileFormats, onUpload: uploadMedia, loadingText }}
      {...otherProps}
    />
  )
}

function DataAwareReorderableList(props: IDataAwareReorderableListProps) {
  const { uploadMedia, ...otherProps } = props

  return uploadMedia ? (
    <DataAwareReorderableListWithUpload uploadMedia={uploadMedia} {...otherProps} />
  ) : (
    <DataAwareReorderableListBase {...otherProps} />
  )
}

type IFormikDataAwareReorderableListProps = Omit<
  IDataAwareReorderableListProps,
  'onChange' | 'values'
>
function FormikDataAwareReorderableList(props: IFormikDataAwareReorderableListProps) {
  const { name } = props
  const { value, setValue } = useField(name)

  return <DataAwareReorderableList {...props} values={value} onChange={setValue} />
}

export default memo(FormikDataAwareReorderableList)
