import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAuthData, getAuthError } from 'client/redux/selectors/auth'
import StringInput from 'client/components/Form/StringInput/StringInput'
import Button from 'client/components/Button/Button'
import { mfaVerificationStart } from 'client/redux/actions/auth'
import ContainerWithConnectsLogo from 'client/components/ContainerWithConnectsLogo'
import styled from 'styled-components'
import SupportFooter from 'client/components/SupportFooter'
import { AuthWorkflowHeader, StyledBanner, StyledBannerItem } from 'client/screens/styledComponents'
import { t } from 'client/i18n'
import { Trans } from 'react-i18next'
import { IMFAProps } from './types'

const googleAuthenticatorLogo = require('assets/images/google_authenticator_logo.png')

const VALID_CODE_REGEX = /\d{6}/

const StyledHeader = styled(AuthWorkflowHeader)`
  margin-bottom: var(--spacing-small);
`

const FlexContainer = styled.div`
  display: flex;
  align-items: start;
`

const StyledImage = styled.img<{ enrolling?: boolean }>`
  ${({ enrolling }) =>
    enrolling
      ? // QR code
        `
        margin: -15px 1px -15px -15px;
      `
      : // Google Authenticator logo
        `
        width: 112px;
        height: 100px;
        margin-right: var(--spacing-small);
      `}
`

const Instructions = styled.div`
  p {
    margin: 0;
  }
`

const StyledInput = styled(StringInput)`
  width: 200px;
  margin-top: var(--spacing-xsmall);
`

const StyledButton = styled(Button)`
  margin-top: var(--spacing-xlarge);
  width: 100%;
`

const GoogleAuthMFA = ({ enrolling = false }: IMFAProps) => {
  const [verificationCode, setVerificationCode] = useState('')
  const [verificationCodeIsValid, setVerificationCodeIsValid] = useState(false)
  const { qrDataURL, isPasswordExpired, isUserLoggingInForFirstTime, privacyPolicyAcceptedAt } =
    useSelector(getAuthData) || {}
  const authError = useSelector(getAuthError)
  const dispatch = useDispatch()

  const onChange = (event) => {
    const { value } = event.target
    setVerificationCode(value)
    setVerificationCodeIsValid(value && value.match(VALID_CODE_REGEX) !== null)
  }

  const onSubmit = (event?: any) => {
    if (event) {
      event.preventDefault()
    }
    if (verificationCodeIsValid) {
      dispatch(mfaVerificationStart(verificationCode))
    }
  }
  const onKeyPress = (event) => {
    if (event.key === 'Enter') {
      onSubmit()
    }
  }

  return (
    <ContainerWithConnectsLogo onKeyPress={onKeyPress}>
      {authError && (
        // TODO should we disambiguate other types of errors here (e.g., network errors)?
        <StyledBanner type="error" headingText={t('Unrecognized verification code')}>
          <StyledBannerItem>{t('Enter a valid code.')}</StyledBannerItem>
        </StyledBanner>
      )}
      <form onSubmit={onSubmit}>
        <StyledHeader>
          {isUserLoggingInForFirstTime && <span>{t('Step 2 of 3')}</span>}
          {!isUserLoggingInForFirstTime && isPasswordExpired && <span>{t('Step 1 of 2')}</span>}
          <h2>
            {enrolling ? t('Set up two-factor authentication') : t('Two-Factor Authentication')}
          </h2>
        </StyledHeader>
        <FlexContainer>
          <StyledImage
            enrolling={enrolling}
            src={enrolling ? qrDataURL : googleAuthenticatorLogo}
          />
          <div>
            <Instructions>
              {enrolling ? (
                <>
                  <p>
                    <Trans i18nKey="Install the Google Authenticator app." />
                  </p>
                  <p>{t('Open the app and scan this QR code.')}</p>
                  <p>{t('Enter the verification code:')}</p>
                </>
              ) : (
                <>
                  <p>
                    <Trans i18nKey="Open the Google Authenticator app." />
                  </p>
                  <p>{t('Enter the verification code:')}</p>
                </>
              )}
            </Instructions>
            <StyledInput
              name="verificationCode"
              value={verificationCode}
              onChange={onChange}
              disableAutocomplete={true}
              maxLength={6}
              autoFocus={true}
            />
          </div>
        </FlexContainer>
        <StyledButton
          type="primary"
          disabled={!verificationCodeIsValid}
          onClick={onSubmit}
          // If a user's password has expired or if the privacy notice hasn't been accepted, they will see the `PasswordReset` and/or the `PrivacyNotice` screens next.
          // If not, they'll see the exhibitions' grid.
          label={isPasswordExpired || !privacyPolicyAcceptedAt ? t('Next') : t('Complete Login')}
        />
      </form>
      <SupportFooter />
    </ContainerWithConnectsLogo>
  )
}

export default GoogleAuthMFA
