import { useSelector, useDispatch } from 'react-redux'
import { getExterior } from 'client/redux/selectors/maps'
import _ from 'lodash'
import { useEffect } from 'react'
import { createExteriorMap } from 'client/redux/actions/maps'
import { getBuildingCreateConfirmationMessage } from 'client/screens/AppEditor/MapEditor/DialogMessages/exteriorMap'
import { ExteriorMapUpload } from 'client/screens/AppEditor/MapEditor/WorkflowManager/WorkflowComponents/ExteriorMapUpload'
import useBuildingList from 'client/screens/AppEditor/MapEditor/useBuildingList'

interface IProps {
  onCancel: () => void
  onNext: (stepName: string) => void
}

const ExteriorMap = (props: IProps) => {
  const { onCancel, onNext } = props
  const exteriorMap = useSelector(getExterior)
  const buildings = useBuildingList()
  const dispatch = useDispatch()
  const needsExteriorMap = !_.isEmpty(buildings) && _.isEmpty(exteriorMap)

  useEffect(() => {
    if (!needsExteriorMap) {
      onNext('buildingName')
    }
  })

  if (!needsExteriorMap) {
    return null
  }

  const handleImageChange = (file: File) => {
    dispatch(createExteriorMap(file))
    onNext('uploadingExteriorMap')
  }

  const { message, title } = getBuildingCreateConfirmationMessage()

  return (
    <ExteriorMapUpload
      onFileChange={handleImageChange}
      onCancel={onCancel}
      message={message}
      title={title}
    />
  )
}

export default ExteriorMap
